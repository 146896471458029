var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "33",
        height: "33",
        viewBox: "0 0 33 33",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("rect", {
        attrs: { width: "33", height: "33", rx: "6", fill: _vm.color },
      }),
      _c("path", {
        attrs: {
          d: "M17.3125 8H11.625C11.194 8 10.7807 8.16857 10.476 8.46863C10.1712 8.76869 10 9.17565 10 9.6V22.4C10 22.8243 10.1712 23.2313 10.476 23.5314C10.7807 23.8314 11.194 24 11.625 24H21.375C21.806 24 22.2193 23.8314 22.524 23.5314C22.8288 23.2313 23 22.8243 23 22.4V13.6L17.3125 8Z",
          stroke: _vm.stroke,
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M17 9V14H23",
          stroke: _vm.stroke,
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }