<template>
  <div class="pag-activate" :class="{ 'pag-activate--border': isMobile }">
    <template v-if="!isMobile">
      <div class="pag-activate--header">{{ $t('gpt.automateYourProcess') }}</div>
      <div class="pag-activate--description" v-html="$t('gpt.useChatbots')"></div>

      <div class="pag-activate--benefits">
        <div class="pag-activate--benefits__box">
          <div class="pag-activate--benefits__box__header">24 / 7</div>
          <div class="pag-activate--benefits__box__description">
            {{ $t('register.gpt.benefits1') }}
          </div>
        </div>
        <div class="pag-activate--benefits__box">
          <div class="pag-activate--benefits__box__header">+300%</div>
          <div class="pag-activate--benefits__box__description">
            {{ $t('register.gpt.benefits2') }}
          </div>
        </div>
        <div class="pag-activate--benefits__box">
          <div class="pag-activate--benefits__box__header">
            <RatingSuccessIcon :width="30.13" :height="30.13" stroke="#3034F7" />
          </div>
          <div class="pag-activate--benefits__box__description pag-activate--benefits__box__description--icon">
            {{ $t('register.gpt.benefits3') }}
          </div>
        </div>
      </div>

      <div class="pag-activate--get-started" :class="{ 'pag-activate--get-started--disabled': !IS_ADMIN }" @click="onGetStarted">
        {{ $t('gpt.getStarted') }}
      </div>
      <div class="pag-activate--more-info">{{ $t('gpt.needMoreInfo') }}</div>
      <div class="pag-activate--see-more" @click="onGoToSeeMore()">{{ $t('gpt.seeMore') }}</div>

      <div class="pag-activate--analyze">
        <img src="@/assets/images/register-funnel/gpt-analyze-2.png" alt="GPT Connect bot" />
      </div>
    </template>
    <template v-else>
      <div class="pag-activate-mobile">
        <div class="pag-activate-mobile--mobile-message">
          <div>
            <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M17.4998 32.0846C25.554 32.0846 32.0832 25.5555 32.0832 17.5013C32.0832 9.44715 25.554 2.91797 17.4998 2.91797C9.44568 2.91797 2.9165 9.44715 2.9165 17.5013C2.9165 25.5555 9.44568 32.0846 17.4998 32.0846Z"
                stroke="#42B5FF"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path d="M17.5 23.3333V17.5" stroke="#42B5FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M17.5 11.668H17.515" stroke="#42B5FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
          <div>{{ $t('gpt.mobileMessage') }}</div>
        </div>
        <div class="pag-activate-mobile--header">{{ $t('gpt.automateYourProcess') }}</div>
        <div class="pag-activate-mobile--description" v-html="$t('gpt.useChatbots')"></div>

        <div class="pag-activate-mobile--benefits">
          <div class="pag-activate-mobile--benefits__box">
            <div class="pag-activate-mobile--benefits__box__header">24 / 7</div>
            <div class="pag-activate-mobile--benefits__box__description">
              {{ $t('register.gpt.benefits1') }}
            </div>
          </div>
          <div class="pag-activate-mobile--benefits__box">
            <div class="pag-activate-mobile--benefits__box__header">+300%</div>
            <div class="pag-activate-mobile--benefits__box__description">
              {{ $t('register.gpt.benefits2') }}
            </div>
          </div>
          <div class="pag-activate-mobile--benefits__box">
            <div class="pag-activate-mobile--benefits__box__header">
              <RatingSuccessIcon :width="30.13" :height="30.13" stroke="#3034F7" />
            </div>
            <div class="pag-activate-mobile--benefits__box__description pag-activate-mobile--benefits__box__description--icon">
              {{ $t('register.gpt.benefits3') }}
            </div>
          </div>
        </div>

        <div class="pag-activate-mobile--more-info">{{ $t('gpt.needMoreInfo') }}</div>
        <div class="pag-activate-mobile--see-more" @click="onGoToSeeMore()">{{ $t('gpt.seeMore') }}</div>

        <div class="pag-activate-mobile--analyze">
          <img src="@/assets/images/register-funnel/gpt-analyze-2.png" alt="GPT analyze" />
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { mobile_check } from '@/mixins/mobile_check'
import RatingSuccessIcon from '@/components/icons/ratings/RatingSuccessIcon.vue'
export default {
  name: 'GptGetStarted',
  components: {
    RatingSuccessIcon
  },
  mixins: [mobile_check],
  computed: {
    ...mapGetters({
      company: 'company',
      activeUserInfo: 'activeUser'
    }),
    isMobile() {
      return this.mobileCheck()
    },
    IS_ADMIN() {
      if (!this.activeUserInfo) return false

      return this.activeUserInfo.role === 'admin'
    },

    gptIsActivated() {
      if (!this.company || !this.company.gptIsActivated) return false

      if (this.company.gptIsActivated) {
        return true
      }

      return false
    }
  },
  methods: {
    onGetStarted() {
      if (this.IS_ADMIN) {
        this.$emit('get-started')
      }

      // if (this.gptIsActivated && this.IS_ADMIN) {
      //   this.$emit('get-started')
      // } else if (this.company.isAppSumoAccount) {
      //   this.$router.push({ name: 'appsumo-billing', params: { action: 'activate-gpt-get-started' } })
      // } else {
      //   this.$router.push({ name: 'payment', params: { action: 'activate-gpt-get-started' } })
      // }
    },
    onGoToSeeMore() {
      window.open('https://www.letsconnect.at/gpt-connect', '_blank')
    }
  }
}
</script>
<style lang="scss" scoped>
.pag-activate {
  position: relative;
  margin-top: 26px;
  background-color: #fff;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  &--border {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  &-mobile {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
    overflow-y: auto;
    overflow-x: hidden;

    &--mobile-message {
      background: #eef8ff;
      display: flex;
      justify-content: center;
      padding: 21px 15px 28px 21px;

      font-family: 'Larsseit-Regular';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.05px;
      color: #42b5ff;

      :last-child {
        margin-left: 10px;
      }
    }

    &--header {
      margin-top: 40px;
      text-align: center;
      font-family: 'Larsseit-Bold';
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      letter-spacing: -0.178px;
      color: #262629;
    }

    &--description {
      font-family: 'Larsseit-Regular';
      margin-top: 30px;
      margin-left: 20px;
      margin-right: 20px;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 21px;
      text-align: center;
      letter-spacing: -0.0833333px;
      color: #53535b;
    }

    &--more-info {
      margin-top: 30px;
      margin-left: 20px;
      margin-right: 20px;
      font-family: 'Larsseit-Regular';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 0.05px;
      color: #53535b;
    }

    &--see-more {
      font-family: 'Larsseit-Medium';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.05px;
      color: #3034f7;
      cursor: pointer;
    }

    &--analyze {
      align-self: flex-end;
      margin-top: 40px;
      margin-right: -10px;
    }

    &--benefits {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: 40px;

      &__box {
        position: relative;
        background: #42b5ff;
        box-shadow: 1.43462px 3.58654px 7.17308px rgba(16, 36, 61, 0.25);
        border-radius: 8.6077px;
        width: 224.09px;
        height: 224.09px;
        margin-top: 20px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &__icon {
          position: absolute;
          top: -25px;
          left: -43px;
        }

        &__header {
          font-family: 'Larsseit-Bold';
          font-style: normal;
          font-weight: 700;
          font-size: 46.2186px;
          line-height: 63px;
          letter-spacing: -0.25677px;
          color: #3034f7;
        }

        &__description {
          font-style: normal;
          font-weight: 400;
          font-size: 19.6079px;
          line-height: 27px;
          text-align: center;
          letter-spacing: -0.140057px;
          color: #ffffff;
          font-family: 'Larsseit-Regular';
          &--icon {
            margin-top: 2.45px !important;
          }
        }
      }
    }
  }

  &--header {
    font-family: 'Larsseit-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    letter-spacing: -0.177778px;
    color: #262629;
    max-width: 601px;
  }
  &--description {
    font-family: 'Larsseit-Regular';
    max-width: 750px;
    margin-top: 30px;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.0833333px;
    color: #53535b;
  }
  &--benefits {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 40px;

    &__box {
      position: relative;
      background: #42b5ff;
      box-shadow: 1.43462px 3.58654px 7.17308px rgba(16, 36, 61, 0.25);
      border-radius: 8.6077px;
      width: 114.77px;
      height: 114.77px;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      &__icon {
        position: absolute;
        top: -15px;
        left: -27px;
      }

      &__header {
        font-style: normal;
        font-weight: 700;
        font-size: 23.6712px;
        line-height: 32px;
        letter-spacing: -0.131506px;
        font-family: 'Larsseit-Bold';

        color: #3034f7;
        margin-top: 24.39px;
      }

      &__description {
        font-style: normal;
        font-weight: 400;
        font-size: 10.0423px;
        line-height: 14px;
        text-align: center;
        letter-spacing: -0.0717308px;
        font-family: 'Larsseit-Regular';

        color: #ffffff;
        margin-top: 7.45px;

        &--icon {
          margin-top: 2.45px !important;
        }
      }
    }

    &__box:not(:last-child) {
      margin-right: 14.35px;
    }
  }

  &--get-started {
    margin-top: 82px;
    background: #42b5ff;
    border-radius: 8px;
    padding: 21px 30px;

    font-family: 'Larsseit-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 18px;
    letter-spacing: -0.111111px;
    color: #ffffff;
    cursor: pointer;

    &--disabled {
      background: #979797;
    }

    &:hover {
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.4);
    }
  }

  &--more-info {
    margin-top: 30px;
    font-family: 'Larsseit-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: right;
    letter-spacing: 0.05px;
    color: #53535b;
  }

  &--see-more {
    font-family: 'Larsseit-Medium';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.05px;
    color: #3034f7;
    cursor: pointer;
  }

  &--analyze {
    position: absolute;
    overflow: hidden;
    bottom: 100px;
    right: 0px;
  }
}
</style>
<style>
.vega-message-bold {
  font-family: 'Larsseit-Bold';
  font-weight: 700;
}
</style>
