var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pag-files" }, [
    _c("div", { staticClass: "pag-files--header1" }, [
      _vm._v("\n    " + _vm._s(_vm.$t("gpt.sources.uploadFiles")) + "\n  "),
    ]),
    _c(
      "div",
      [
        _c(
          "vue-dropzone",
          {
            ref: "vueDropzone",
            attrs: {
              id: "dataSourceFiles",
              options: _vm.dropzoneOptions,
              useCustomSlot: true,
            },
            on: { "vdropzone-files-added": _vm.afterFilesAdded },
          },
          [
            _c(
              "div",
              { staticClass: "pag-files-filescontainer" },
              [
                _c("upload-icon", { attrs: { stroke: "#000000" } }),
                _c(
                  "div",
                  { staticClass: "pag-files--header2 pag-files-mt-15" },
                  [_vm._v(_vm._s(_vm.$t("gpt.sources.dragDropMessage")))]
                ),
                _c("div", { staticClass: "pag-files--text" }, [
                  _vm._v(_vm._s(_vm.$t("gpt.sources.supportFileTypes"))),
                ]),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "pag-files-attached" },
      [
        _c("div", { staticClass: "pag-files--header1" }, [
          _vm._v(
            "\n      " + _vm._s(_vm.$t("gpt.sources.attachedFiles")) + "\n    "
          ),
        ]),
        _vm.isProcessing
          ? _c("LoadingIndicator", { staticClass: "pag-files-loader" })
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "pag-files-attached-files" },
      [
        _c(
          "scroll-view",
          { key: _vm.fileKey, staticStyle: { "max-height": "250px" } },
          _vm._l(_vm.uploads, function (file, index) {
            return _c("div", { key: index, staticClass: "pag-files-list" }, [
              _c("div", { staticClass: "pag-files-list--file-details" }, [
                _c("span", { staticClass: "pag-files-list--filename" }, [
                  _vm._v(_vm._s(file.name)),
                ]),
                _c("span", { staticClass: "pag-files-list--filesize" }, [
                  _vm._v(_vm._s(_vm.formatSize(file.size))),
                ]),
                _c("span", { staticClass: "pag-files-list--upload-percent" }, [
                  _vm._v(_vm._s(file.uploadPercent || 0) + "%"),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass: "pag-files--delete-btn",
                  on: {
                    click: function ($event) {
                      return _vm.deleteFile(index)
                    },
                  },
                },
                [_c("DeleteIcon")],
                1
              ),
            ])
          }),
          0
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }