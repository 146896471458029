var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pag-bubble-chat" }, [
    _c(
      "div",
      { staticClass: "pag-bubble-chat--block" },
      [
        _c("TextBubbleIcon"),
        _c(
          "div",
          { staticClass: "pag-bubble-chat--robot" },
          [_c("RoboChatIcon")],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }