var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "19",
        height: "19",
        viewBox: "0 0 19 19",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { "clip-path": "url(#clip0_10530_446)" } }, [
        _c("path", {
          attrs: {
            d: "M9.50004 17.4154C13.8723 17.4154 17.4167 13.871 17.4167 9.4987C17.4167 5.12644 13.8723 1.58203 9.50004 1.58203C5.12779 1.58203 1.58337 5.12644 1.58337 9.4987C1.58337 13.871 5.12779 17.4154 9.50004 17.4154Z",
            fill: "#F1A342",
            stroke: "#F1A342",
            "stroke-width": "2",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
          },
        }),
        _c("path", {
          attrs: {
            d: "M9.5 12.6667V9.5",
            stroke: "white",
            "stroke-width": "2",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
          },
        }),
        _c("path", {
          attrs: {
            d: "M9.5 6.33203H9.50792",
            stroke: "white",
            "stroke-width": "2",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
          },
        }),
      ]),
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip0_10530_446" } }, [
          _c("rect", { attrs: { width: "19", height: "19", fill: "white" } }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }