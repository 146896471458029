<template>
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17 11.9961V15.5164C17 15.9833 16.8127 16.431 16.4793 16.7611C16.1459 17.0912 15.6937 17.2766 15.2222 17.2766H2.77778C2.30628 17.2766 1.8541 17.0912 1.5207 16.7611C1.1873 16.431 1 15.9833 1 15.5164V11.9961"
      :stroke="stroke"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M13.4446 5.83403L9.00011 1.43359L4.55566 5.83403" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M9 1.43359V11.9947" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>
<script>
export default {
  name: 'UploadIcon',
  props: {
    stroke: {
      type: String,
      default: '#3B86F7'
    }
  }
}
</script>
