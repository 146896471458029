var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pag-customization" }, [
    _c("div", { staticClass: "pag-customization--top" }, [
      _c("div", { staticClass: "pag-customization--top-left" }, [
        _c("div", { staticClass: "pag-customization--translation-container" }, [
          _c(
            "div",
            { staticClass: "mr-4" },
            [
              _c("info-icon", {
                attrs: {
                  fill: "#01AAF4",
                  color: "#fff",
                  width: 20,
                  height: 20,
                },
              }),
            ],
            1
          ),
          _c("div", {
            domProps: { innerHTML: _vm._s(_vm.$t("info.translation")) },
          }),
        ]),
        _c("div", { staticClass: "language-selection" }, [
          _c("div", { staticClass: "language-selection-text" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.$t("vue.selectLanguageText")) +
                "\n        "
            ),
          ]),
          _c(
            "div",
            [
              _c("v-select", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "language-selection-select mb-4 md:mb-0 w-full",
                staticStyle: { display: "inline-block", "max-width": "520px" },
                attrs: {
                  name: "language-select",
                  options: _vm.gptconnectSupportedLanguages,
                  placeholder: _vm.$t("vue.selectLanguage"),
                  label: "lnFullText",
                  clearable: false,
                  dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  "validate-on": "blur",
                },
                scopedSlots: _vm._u([
                  {
                    key: "option",
                    fn: function (option) {
                      return [
                        _c("div", [
                          _vm._v(
                            "\n                " +
                              _vm._s(option.lnFullText) +
                              "\n              "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.selectedLanguage,
                  callback: function ($$v) {
                    _vm.selectedLanguage = $$v
                  },
                  expression: "selectedLanguage",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "pag-customization-face" }, [
          _c("div", { staticClass: "pag-customization--label" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.$t("gpt.customization.chatbotFaceLabel")) +
                "\n        "
            ),
          ]),
          _c("div", { staticClass: "pag-customization--img-container" }, [
            _vm._m(0),
            _c(
              "div",
              { staticClass: "pag-customization--img-container--uploading" },
              [
                !_vm.croppaHasFile &&
                _vm.selectedGptWidget &&
                !_vm.selectedGptWidget.gptBotface
                  ? _c(
                      "div",
                      {
                        staticClass: "pag-customization--uploadcontainer",
                        on: { click: _vm.onChooseFile },
                      },
                      [
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm.$t("gpt.customization.uploadYourImage"))
                          ),
                        ]),
                        _c("div", [_c("UploadIcon")], 1),
                      ]
                    )
                  : _vm._e(),
                _c("croppa", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.croppaHasFile ||
                        (_vm.selectedGptWidget &&
                          _vm.selectedGptWidget.gptBotface),
                      expression:
                        "croppaHasFile || (selectedGptWidget && selectedGptWidget.gptBotface)",
                    },
                  ],
                  staticClass: "resizable-croppa",
                  attrs: {
                    width: _vm.croppaWidth,
                    height: _vm.croppaHeight,
                    "show-remove-button": true,
                    "canvas-color": "transparent",
                    "prevent-white-space": true,
                    "image-border-radius": 10,
                    accept: ".jpeg,.jpg,.png",
                    "disable-drag-to-move": false,
                    "file-size-limit": 2048000,
                    "placeholder-font-size": 14,
                    quality: 2,
                    disabled: false,
                    "initial-size": "cover",
                    "initial-image":
                      _vm.selectedGptWidget && _vm.selectedGptWidget.gptBotface,
                  },
                  on: {
                    "image-remove": _vm.onImageRemoved,
                    "file-choose": _vm.onFileChosen,
                  },
                  model: {
                    value: _vm.gptBotface,
                    callback: function ($$v) {
                      _vm.gptBotface = $$v
                    },
                    expression: "gptBotface",
                  },
                }),
              ],
              1
            ),
            _vm.croppaHasFile
              ? _c(
                  "div",
                  {
                    staticClass: "pag-customization--upload",
                    on: { click: _vm.onCroppaSave },
                  },
                  [_c("UploadIcon")],
                  1
                )
              : _vm._e(),
          ]),
        ]),
        _c("div", { staticClass: "pag-customization-bubble" }, [
          _vm.canUpdateTextFields
            ? _c("div", { staticClass: "pag-customization--label" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("gpt.customization.speechBubbleLabel")) +
                    "\n        "
                ),
              ])
            : _vm._e(),
          _c("div", { staticClass: "pag-customization--text-area" }, [
            !_vm.canUpdateTextFields
              ? _c("div", { staticClass: "w-full bold" }, [
                  _vm._v(
                    _vm._s(_vm.$t("gpt.customization.speechBubbleLabel")) +
                      " (English)"
                  ),
                ])
              : _vm._e(),
            _c("textarea", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'",
                },
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.gptSpeechBubbleText,
                  expression: "gptSpeechBubbleText",
                },
              ],
              staticClass: "pag-customization-inputs--text-area",
              attrs: {
                type: "text",
                name: "chatbot-speechbubble",
                placeholder: _vm.$t("gpt.choose.chatbotNameInput"),
              },
              domProps: { value: _vm.gptSpeechBubbleText },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.gptSpeechBubbleText = $event.target.value
                },
              },
            }),
            !_vm.canUpdateTextFields
              ? _c("div", { staticClass: "w-full bold" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("gpt.customization.speechBubbleLabel")) +
                      " (" +
                      _vm._s(this.selectedLanguageForGptConnect.lnFullText) +
                      ")\n          "
                  ),
                ])
              : _vm._e(),
            !_vm.canUpdateTextFields
              ? _c("textarea", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.tGptConnectGptSpeechBubbleText,
                      expression: "tGptConnectGptSpeechBubbleText",
                    },
                  ],
                  staticClass: "pag-customization-inputs--text-area",
                  attrs: {
                    type: "text",
                    name: "chatbot-speechbubble",
                    placeholder: _vm.$t("gpt.choose.chatbotNameInput"),
                  },
                  domProps: { value: _vm.tGptConnectGptSpeechBubbleText },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.tGptConnectGptSpeechBubbleText = $event.target.value
                    },
                  },
                })
              : _vm._e(),
          ]),
        ]),
        _c("div", { staticClass: "pag-customization-botcolor" }, [
          _c("div", { staticClass: "pag-customization-botcolor-colors" }, [
            _c("div", { staticClass: "pag-customization--label" }, [
              _vm._v(_vm._s(_vm.$t("gpt.customization.botWidgetMainColor"))),
            ]),
            _c("div", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.botWidgetMainColor,
                    expression: "botWidgetMainColor",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "pag-customization-botcolor-picker",
                attrs: { type: "color", name: "chatbot-maincolor" },
                domProps: { value: _vm.botWidgetMainColor },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.botWidgetMainColor = $event.target.value
                  },
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "pag-customization-botcolor-colors" }, [
            _c("div", { staticClass: "pag-customization--label" }, [
              _vm._v(_vm._s(_vm.$t("gpt.customization.botWidgetSecondColor"))),
            ]),
            _c("div", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.botWidgetSecondColor,
                    expression: "botWidgetSecondColor",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "pag-customization-botcolor-picker",
                attrs: { type: "color", name: "chatbot-secondcolor" },
                domProps: { value: _vm.botWidgetSecondColor },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.botWidgetSecondColor = $event.target.value
                  },
                },
              }),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "pag-customization-welcome" }, [
          _vm.canUpdateTextFields
            ? _c("div", { staticClass: "pag-customization--label" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("gpt.customization.welcomeMessageLabel")) +
                    "\n        "
                ),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "pag-customization-inputs--editor" },
            [
              !_vm.canUpdateTextFields
                ? _c("div", { staticClass: "w-full bold" }, [
                    _vm._v(
                      _vm._s(_vm.$t("gpt.customization.welcomeMessageLabel")) +
                        " (English)"
                    ),
                  ])
                : _vm._e(),
              _c("quill-editor", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                ref: "quillEditorA",
                attrs: { options: _vm.editorOption, name: "chatbot-welcome" },
                model: {
                  value: _vm.gptBotWelcomeMessage,
                  callback: function ($$v) {
                    _vm.gptBotWelcomeMessage = $$v
                  },
                  expression: "gptBotWelcomeMessage",
                },
              }),
              !_vm.canUpdateTextFields
                ? _c("div", { staticClass: "w-full bold" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t("gpt.customization.welcomeMessageLabel")
                        ) +
                        " (" +
                        _vm._s(this.selectedLanguageForGptConnect.lnFullText) +
                        ")\n          "
                    ),
                  ])
                : _vm._e(),
              !_vm.canUpdateTextFields
                ? _c("quill-editor", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    ref: "quillEditorATranslation",
                    attrs: {
                      options: _vm.editorOption,
                      name: "chatbot-welcome-translation",
                    },
                    model: {
                      value: _vm.tGptConnectGptBotWelcomeMessage,
                      callback: function ($$v) {
                        _vm.tGptConnectGptBotWelcomeMessage = $$v
                      },
                      expression: "tGptConnectGptBotWelcomeMessage",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "pag-customization-botcolor" }, [
          _c("div", { staticClass: "pag-customization-botcolor-colors" }, [
            _c("div", { staticClass: "pag-customization--label" }, [
              _vm._v(
                _vm._s(_vm.$t("gpt.customization.requestOptionsButtonColor"))
              ),
            ]),
            _c("div", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.botWidgetRequestOptionsButtonColor,
                    expression: "botWidgetRequestOptionsButtonColor",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "pag-customization-botcolor-picker",
                attrs: {
                  type: "color",
                  name: "chatbot-requestoptionsbuttoncolor",
                },
                domProps: { value: _vm.botWidgetRequestOptionsButtonColor },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.botWidgetRequestOptionsButtonColor = $event.target.value
                  },
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "pag-customization-botcolor-colors" }, [
            _c("div", { staticClass: "pag-customization--label" }, [
              _vm._v(
                _vm._s(_vm.$t("gpt.customization.requestOptionsIconColor"))
              ),
            ]),
            _c("div", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.botWidgetRequestOptionsIconColor,
                    expression: "botWidgetRequestOptionsIconColor",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "pag-customization-botcolor-picker",
                attrs: {
                  type: "color",
                  name: "chatbot-requestoptionsiconcolor",
                },
                domProps: { value: _vm.botWidgetRequestOptionsIconColor },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.botWidgetRequestOptionsIconColor = $event.target.value
                  },
                },
              }),
            ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "pag-customization--top-right" },
        [_c("BubbleChat"), _c("LetsConnectChat", { key: _vm.botFaceKey })],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pag-customization--img-container--img" }, [
      _c("img", { attrs: { src: "/img/lcbot.png", alt: "" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }