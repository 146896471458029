<template>
  <div class="pags-howto">
    <div v-show="showBackground" class="video-background" :style="`background-image: url(${defaultImageURL});`"></div>
    <video ref="videoPlayer" :src="videoUrl" preload="none" controls @play="onPlay" @pause="onPause" @ended="onEnded"></video>
    <img
      :v-show="showIcon"
      class="play-icon"
      :class="{ 'play-icon--hidden': !showIcon }"
      src="@/assets/images/register-funnel/vega-video-icon.png"
      alt="Play Video"
      @click="playHowTo"
    />
  </div>
</template>
<script>
export default {
  name: 'GptStepHowTo',
  data() {
    return {
      showIcon: true,
      showBackground: true
    }
  },
  computed: {
    videoUrl() {
      return this.$i18n.locale === 'en'
        ? 'https://storage.googleapis.com/pathadvice-app.appspot.com/videos/gpt-connect/GPT-Connect.mp4'
        : 'https://storage.googleapis.com/pathadvice-app.appspot.com/videos/gpt-connect/GPT-Connect.mp4'
    },
    defaultImageURL() {
      return this.$i18n.locale === 'de' ? require('@/assets/images/gpt/gpt-connect.png') : require('@/assets/images/gpt/gpt-connect.png')
    }
  },
  methods: {
    playHowTo() {
      this.$refs.videoPlayer.play()
    },
    onPlay() {
      this.showIcon = false
      this.showBackground = false
    },
    onPause() {
      this.showIcon = true
    },
    onEnded() {
      this.showIcon = true
      this.showBackground = true
    }
  }
}
</script>
<style lang="scss" scoped>
.pags-howto {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 10px;
  width: 88vw;
  height: 90vh;
  max-width: 840px;

  @media (min-width: 1200px) {
    width: calc(100vw - 410px) !important;
  }
  max-height: 475px;

  video {
    width: 100%; /* to ensure the video covers the entire width of the div */
    height: 100%; /* to ensure the video covers the entire height of the div */
    object-fit: contain; /* to maintain aspect ratio of the video and avoid stretching */
  }

  background: #d9d9d9;
  border-radius: 8px;
  overflow: hidden;

  .video-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #d9d9d9;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
  }

  .play-icon {
    position: absolute;
    width: 10%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    z-index: 2;

    &--hidden {
      display: none;
    }
  }
}
</style>
