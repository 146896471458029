var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 10 11",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M7.88499 0.895935H1.13386C0.514123 0.895935 0.00866699 1.4122 0.00866699 2.02903V7.18454C0.00866699 7.81753 0.514123 8.31763 1.13386 8.31763H2.82164V9.80987C2.82164 9.9865 3.01538 10.0891 3.15779 9.98406L5.35367 8.30147H7.88534C8.50507 8.30147 9.01053 7.7852 9.01053 7.16837V2.02903C9.01018 1.4122 8.51967 0.895935 7.88499 0.895935ZM2.58245 4.05787C2.27038 4.05787 2.03568 4.31376 2.03568 4.6325C2.03568 4.95124 2.28621 5.20713 2.58245 5.20713C2.87869 5.20713 3.12922 4.95124 3.12922 4.6325C3.12922 4.31376 2.89539 4.05787 2.58245 4.05787ZM4.55123 4.05787C4.23917 4.05787 3.98881 4.31376 3.98881 4.6325C3.98881 4.95124 4.23934 5.20713 4.55123 5.20713C4.8633 5.20713 5.098 4.95124 5.098 4.6325C5.098 4.31376 4.86417 4.05787 4.55123 4.05787ZM5.97364 4.6325C5.97364 4.31376 6.20835 4.05787 6.52042 4.05787C6.83336 4.05787 7.06719 4.31376 7.06719 4.6325C7.06719 4.95124 6.81666 5.20713 6.52042 5.20713C6.22417 5.20713 5.97364 4.95124 5.97364 4.6325ZM7.90079 7.50356C8.05463 7.50356 8.18209 7.37337 8.18209 7.21624V2.04458C8.18209 1.88745 8.05463 1.75726 7.90079 1.75726H1.14966C0.995826 1.75726 0.868364 1.88745 0.868364 2.04458V7.21624C0.868364 7.37337 0.995826 7.50356 1.14966 7.50356H3.68134V8.58099L5.08782 7.50356H7.90079Z",
          fill: _vm.color,
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }