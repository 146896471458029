var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gpt-test" }, [
    _vm.isLoading
      ? _c("div", { staticClass: "gpt-test__loading" }, [
          _c("div", { staticClass: "gpt-test__loading__loader" }, [
            _c("span", { staticClass: "gpt-test__loading__loader__spinner" }),
            _c("div", { staticClass: "gpt-test__loading__loader__text" }, [
              _vm._v(_vm._s(_vm.$t("buttonAndHints.loading"))),
            ]),
          ]),
        ])
      : _vm._e(),
    _c("div", { staticClass: "gpt-test--header" }, [
      _vm._v(_vm._s(_vm.$t("gpt.test.header"))),
    ]),
    _c("div", { staticClass: "gpt-test--description" }, [
      _vm._v(_vm._s(_vm.$t("gpt.test.description"))),
    ]),
    _c(
      "div",
      { staticClass: "gpt-test--lc-chat" },
      [
        _c("LetsConnectChat", { attrs: { mode: "test" } }),
        _c(
          "div",
          {
            staticClass: "gpt-test--lc-chat--testcount",
            class: {
              "gpt-test--lc-chat--testcount--limit": _vm.hasTestLimitReached,
            },
          },
          [_vm._v(_vm._s(_vm.totalTestCount) + "/10")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }