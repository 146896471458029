<template>
  <div class="pag-widget">
    <form-wizard
      class="pag-widget--formwizard"
      color="rgba(var(--vs-primary), 1)"
      title=""
      subtitle=""
      :startIndex="currentStep"
      :nextButtonText="$t('vue.next')"
      :backButtonText="$t('vue.back')"
      :finishButtonText="$t('vue.finish')"
      ref="gptFormWizard"
      shape="circle"
      @on-change="onStepChanged"
    >
      <template slot="footer">
        <div class="pag-widget-footer">
          <div class="pag-widget-footer--btn" @click="prevTab">{{ $t('vue.back') }}</div>
          <div class="pag-widget-footer--btn" v-if="currentStep !== 3" @click="nextTab">{{ $t('vue.next') }}</div>
        </div>
      </template>
      <!-- tab 1 content -->
      <tab-content :title="$t('gpt.tabs.howTo')" class="mb-5" icon="material-icons icon-question-mark" :lazy="true">
        <HowTo v-if="currentStep == 0" />
      </tab-content>

      <!-- tab 2 content -->
      <tab-content :title="$t('gpt.tabs.settings')" class="tab2 mb-5" icon="material-icons icon-settings" :before-change="validateSettings" :lazy="true">
        <Settings v-if="currentStep == 1" ref="gptSettings" />
      </tab-content>

      <!-- tab 3 content -->
      <tab-content :title="$t('gpt.tabs.customization')" class="mb-5" icon="material-icons icon-edit-3" :before-change="validateCustomization">
        <Customization v-if="currentStep == 2" ref="gptCustomization" />
      </tab-content>

      <!-- tab 4 content -->
      <tab-content :title="$t('gpt.tabs.dataSources')" class="mb-5" icon="material-icons icon-file-text-2">
        <DataSources v-if="currentStep == 3" @on-complete-process="onCompleteProcess" />
      </tab-content>
    </form-wizard>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

import HowTo from './steps/HowTo.vue'
import Settings from './steps/Settings.vue'
import Customization from './steps/Customization.vue'
import DataSources from './steps/DataSources.vue'

export default {
  name: 'AddGptToWidget',
  components: {
    FormWizard,
    TabContent,
    HowTo,
    Settings,
    Customization,
    DataSources
  },
  data() {
    return {
      currentStep: 0,
      howToVideo: 'https://storage.googleapis.com/pathadvice-stage.appspot.com/vega/vega.mp4'
    }
  },
  props: {
    step: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      selectedGptChatbotName: 'gpt/selectedGptChatbotName',
      selectedGptWidgetId: 'gpt/selectedGptWidgetId',
      gptConsultation: 'gpt/consultation',
      gptQualifiedPercent: 'gpt/qualifiedPercent',
      selectedGptWidget: 'gpt/selectedGptWidget',
      gptSpeechBubbleText: 'gpt/gptSpeechBubbleText',
      gptBotWelcomeMessage: 'gpt/gptBotWelcomeMessage',
      botWidgetMainColor: 'gpt/botWidgetMainColor',
      botWidgetSecondColor: 'gpt/botWidgetSecondColor',
      botWidgetRequestOptionsButtonColor: 'gpt/botWidgetRequestOptionsButtonColor',
      botWidgetRequestOptionsIconColor: 'gpt/botWidgetRequestOptionsIconColor',
      selectedGptConnectTranslation: 'widgetTranslation/selectedGptConnectTranslation',
      selectedLanguageForGptConnect: 'widgetTranslation/selectedLanguageForGptConnect',
      hasGptConnectWidgetTranslationUpdated: 'widgetTranslation/hasGptConnectWidgetTranslationUpdated'
    })
  },
  created() {
    if (this.step) {
      this.currentStep = this.step
    }
  },
  mounted() {
    const wizard = this.$refs.gptFormWizard
    wizard.activateAll()
  },
  methods: {
    ...mapActions({
      setHasGptConnectWidgetTranslationUpdated: 'widgetTranslation/setHasGptConnectWidgetTranslationUpdated'
    }),
    nextTab() {
      this.$refs.gptFormWizard.nextTab()
    },
    prevTab() {
      this.$refs.gptFormWizard.prevTab()
    },
    onCompleteProcess() {
      this.$emit('on-complete')
    },
    async onStepChanged() {
      setTimeout(() => {
        if (this.$refs && this.$refs.gptFormWizard) {
          this.currentStep = this.$refs.gptFormWizard.activeTabIndex
        }
      }, 300)
    },

    async validateSettings() {
      this.$refs.gptSettings.onValidateSettings()
      if (this.selectedGptWidget && this.selectedGptWidgetId && this.selectedGptChatbotName && this.gptConsultation) {
        await this.$vs.loading()
        const _gpt = {
          botName: this.selectedGptChatbotName,
          consultation: this.gptConsultation,
          qualifiedPercent: this.gptQualifiedPercent
        }
        await this.$db.collection('dialogs').doc(this.selectedGptWidgetId).set({ gpt: _gpt }, { merge: true })
        await this.$vs.loading.close()
        return true
      }
      return false
    },
    async saveTranslation() {
      if (
        this.selectedGptConnectTranslation &&
        this.selectedLanguageForGptConnect.code &&
        this.selectedGptWidgetId &&
        this.activeUserInfo &&
        this.activeUserInfo.company
      ) {
        const gptConnect = this.selectedGptConnectTranslation
        const _update = {
          canBeDeleted: true,
          ln: this.selectedLanguageForGptConnect.code,
          lnFullText: this.selectedLanguageForGptConnect.lnFullText,
          lnText: this.selectedLanguageForGptConnect.lnText,
          modified: new Date(),
          sort: 1
        }
        _update.gptConnect = gptConnect

        await this.$db
          .collection('translations')
          .doc(this.activeUserInfo.company)
          .collection('gpt-connect')
          .doc(this.selectedGptWidgetId)
          .set({ companyId: this.activeUserInfo.company, updated: new Date() }, { merge: true })

        await this.$db
          .collection('translations')
          .doc(this.activeUserInfo.company)
          .collection('gpt-connect')
          .doc(this.selectedGptWidgetId)
          .collection('languages')
          .doc(this.selectedLanguageForGptConnect.code)
          .set(_update, { merge: true })
        /* As the translation is saved to firestore, set the below store prop to false */
        this.setHasGptConnectWidgetTranslationUpdated(false)
      }
    },
    async validateCustomization() {
      this.$refs.gptCustomization.onValidateCustomization()
      if (
        this.gptSpeechBubbleText &&
        this.gptBotWelcomeMessage &&
        this.botWidgetMainColor &&
        this.botWidgetSecondColor &&
        this.botWidgetRequestOptionsButtonColor &&
        this.botWidgetRequestOptionsIconColor
      ) {
        await this.$vs.loading()
        const _gpt = {
          botWelcomeMessage: this.gptBotWelcomeMessage,
          speechBubbleText: this.gptSpeechBubbleText,
          botWidgetMainColor: this.botWidgetMainColor,
          botWidgetSecondColor: this.botWidgetSecondColor,
          botWidgetRequestOptionsButtonColor: this.botWidgetRequestOptionsButtonColor,
          botWidgetRequestOptionsIconColor: this.botWidgetRequestOptionsIconColor
        }
        await this.$db.collection('dialogs').doc(this.selectedGptWidgetId).set({ gpt: _gpt }, { merge: true })
        if (this.hasGptConnectWidgetTranslationUpdated) {
          await this.saveTranslation()
        }
        await this.$vs.loading.close()
        return true
      }
      return false
    },
    validateVegaPopup() {
      if (this.vegaPopupText && this.vegaPopupMessage && this.selectedGptWidgetId) {
        return true
      }
      return false
    }
  }
}
</script>
<style lang="scss">
.icon-question-mark::before {
  content: 'question_mark';
}
.icon-title::before {
  content: 'title';
}
.icon-widgets::before {
  content: 'widgets';
}

.pag-widget {
  width: 100vw;
  height: calc(100vh - 65px);
  position: relative;

  &-footer {
    display: flex;
    justify-content: space-between;

    &--btn {
      border-radius: 8px;
      background: #275D73;
      height: 37px;
      padding: 0px 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      color: #fff;
      font-family: 'Larsseit-Regular';
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 17.668px; /* 110.427% */
      letter-spacing: -0.111px;
      cursor: pointer;
    }
  }

  @media (min-width: 1200px) {
    width: calc(100vw - 325px) !important;
  }

  height: 100%;
  overflow-y: auto;
  margin-top: 24px;

  background: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 0px 21px;

  // &--formwizard {
  //   max-width: 2118px;
  // }

  // .wizard-nav-pills {
  //   max-width: 1118px;
  // }

  &--formwizard .wizard-tab-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 20px 10px;
  }
}
</style>
