<template>
  <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_10088_2388)">
      <mask id="mask0_10088_2388" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="6" width="5" height="6">
        <path
          d="M4.77295 6.34375V10.7646C4.77295 10.9981 4.58367 11.1874 4.35018 11.1874C4.25647 11.1874 4.16541 11.1562 4.09132 11.0989L0.932949 8.6528C1.45202 8.26328 2.07815 7.91067 2.81135 7.59499C3.54455 7.27931 4.19842 6.86223 4.77295 6.34375Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_10088_2388)">
        <path
          d="M4.77271 6.34375V10.7646C4.77271 10.9981 4.58343 11.1874 4.34994 11.1874C4.25622 11.1874 4.16516 11.1562 4.09107 11.0989L0.932705 8.6528C1.45177 8.26328 2.07791 7.91067 2.81111 7.59499C3.54431 7.27931 4.19817 6.86223 4.77271 6.34375Z"
          fill="#3034F7"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.04129 5.17188L6.10544 8.15672C4.90194 9.47848 3.49785 10.0733 1.89318 9.9411C0.288501 9.80892 0.00453964 8.21918 1.04129 5.17188Z"
          fill="#090DC2"
        />
      </g>
      <path
        d="M6.67058 0.425781H2.44713C1.13917 0.425781 0.0788574 1.48609 0.0788574 2.79406V6.93298C0.0788574 8.24094 1.13917 9.30125 2.44713 9.30125H6.67058C7.97854 9.30125 9.03886 8.24094 9.03886 6.93298V2.79406C9.03886 1.48609 7.97854 0.425781 6.67058 0.425781Z"
        fill="#3034F7"
      />
      <path
        d="M3.49236 6.76471C2.62235 6.48787 1.99902 5.74088 1.99902 4.86282C1.99902 3.98477 2.62234 3.23778 3.49234 2.96094"
        stroke="white"
        stroke-width="0.97924"
        stroke-linecap="round"
      />
      <path
        d="M5.62532 6.76471C6.49533 6.48787 7.11865 5.74088 7.11865 4.86282C7.11865 3.98477 6.49534 3.23778 5.62534 2.96094"
        stroke="#275D73"
        stroke-width="0.97924"
        stroke-linecap="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.46031 5.08346L4.48316 5.86991C4.3622 5.96726 4.18523 5.94812 4.08788 5.82716C4.04768 5.77721 4.02576 5.71502 4.02576 5.6509V4.07801C4.02576 3.92274 4.15163 3.79687 4.30689 3.79687C4.37101 3.79688 4.43321 3.81879 4.48316 3.859L5.46031 4.64544C5.58127 4.74279 5.6004 4.91976 5.50305 5.04072C5.49038 5.05646 5.47605 5.07079 5.46031 5.08346Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_10088_2388">
        <rect width="8.96" height="11.2" fill="white" transform="translate(0.0803223 0.429688)" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'PoweredByIcon'
}
</script>
