<template>
  <div class="vx-row pgw-gwidgets">
    <vs-prompt
      :title="$t('vue.deleteDialog')"
      @cancel="onCancelDeleteWidget"
      @accept="onDeleteGptWidget(selectedGptWidgetId)"
      @close="onCancelDeleteWidget"
      color="danger"
      :cancel-text="$t('vue.cancel')"
      :accept-text="$t('vue.delete')"
      :active.sync="deletePrompt"
    >
      <div class="con-exemple-prompt" v-if="selectedGptWidget">
        <p>
          <strong>{{ selectedGptWidget.name }}</strong>
        </p>
        {{ $t('vue.deleteDialogMessage') }}
      </div>
    </vs-prompt>

    <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base" v-for="(widget, index) in widgets" :key="index">
      <div class="pgw-gwidgets-box">
        <div class="pgw-gwidgets-box--controls">
          <div class="pgw-gwidgets-box--controls--header" v-if="widget && widget.gpt && widget.gpt.botName">{{ widget.gpt.botName }}</div>
          <div class="pgw-gwidgets-box--controls--switch" @click="toggleEnableGptWidget(widget)">
            <toggle-switch-icon :enabled="widget.isGptWidgetEnabled || false" :width="50" :height="50" :color="`${IS_ADMIN ? '#275D73' : '#979797'}`" />
          </div>
        </div>
        <div class="pgw-gwidgets-box--actions">
          <feather-icon
            icon="EditIcon"
            class="inline-flex rounded-full pgw-gwidgets-box--actions--btn"
            :style="{ background: `${IS_ADMIN ? 'rgba(var(--vs-primary),.15)' : 'rgba(151, 151, 151,.15)'}`, color: `${IS_ADMIN ? '#275D73' : '#979797'}` }"
            @click="onEditGptWidget(widget.id)"
          />
          <feather-icon
            icon="TrashIcon"
            class="inline-flex rounded-full pgw-gwidgets-box--actions--btn"
            :style="{
              background: `${IS_ADMIN ? 'rgba(var(--vs-danger),.15)' : 'rgba(151, 151, 151,.15)'}`,
              color: `${IS_ADMIN ? 'rgba(234,84,85)' : '#979797'}`
            }"
            @click="onDeleteGptWidgetPrompt(widget)"
          />

          <feather-icon
            icon="SettingsIcon"
            class="inline-flex rounded-full pgw-gwidgets-box--actions--btn"
            :style="{
              background: `${IS_ADMIN ? 'rgba(28, 132, 57, .15)' : 'rgba(151, 151, 151,.15)'}`,
              color: `${IS_ADMIN ? 'rgb(28, 132, 57)' : '#979797'}`
            }"
            @click="onEditGptWidgetSettings(widget.id)"
          />
          <feather-icon
            v-if="IS_CUSTOM_GPT_PROMPT_ENABLED"
            icon="ToolIcon"
            class="inline-flex rounded-full pgw-gwidgets-box--actions--btn"
            :style="{
              background: `${IS_ADMIN ? 'rgba(28, 132, 57, .15)' : 'rgba(151, 151, 151,.15)'}`,
              color: `${IS_ADMIN ? 'rgb(28, 132, 57)' : '#979797'}`
            }"
            @click="onEditGptPrompt(widget.id)"
          />
        </div>

        <div class="pgw-gwidgets-box--footer">
          <div v-if="widget && widget.isVegaWidget && widget.isVegaWidgetEnabled && widget.vega" class="pgw-gwidgets-box--footer--vega">
            <VegaActivatedIcon />
          </div>
          <div class="pgw-gwidgets-box--footer--wname">
            {{ widget.name }}
            <CheckIcon class="pgw-gwidgets-box--footer--wname--icon" />
          </div>
          <div v-if="widget && widget.isGptWidget && widget.gpt" class="pgw-gwidgets-box--footer--testing" @click="onTestGptBot(widget.id)">
            <div class="pgw-gwidgets-box--footer--testing--text">{{ $t('gpt.testBot') }}</div>
            <RightArrowIcon />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import ToggleSwitchIcon from '@/components/icons/ToggleSwitchIcon.vue'
import CheckIcon from './icons/CheckIcon.vue'
import VegaActivatedIcon from '@/components/icons/vega/VegaActivatedIcon.vue'
import RightArrowIcon from './icons/RightArrowIcon.vue'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
dayjs.extend(utc)

export default {
  name: 'GptWidgets',
  components: {
    ToggleSwitchIcon,
    CheckIcon,
    VegaActivatedIcon,
    RightArrowIcon
  },

  data() {
    return {
      deletePrompt: false,
      deleteGptWidgetId: null
    }
  },

  computed: {
    ...mapGetters({
      company: 'company',
      activeUserInfo: 'activeUser',
      dialogs: 'dialogs',
      selectedGptWidgetId: 'gpt/selectedGptWidgetId',
      selectedGptWidget: 'gpt/selectedGptWidget'
    }),

    IS_GPT_ACTIVATED() {
      if (this.company && this.company.gptIsActivated) return true
      return false
    },

    IS_ADMIN() {
      if (!this.activeUserInfo) return false

      return this.activeUserInfo.role === 'admin'
    },

    IS_CUSTOM_GPT_PROMPT_ENABLED() {
      if (this.company && this.company.isCustomGptPromptEnabled) return true
      return false
    },

    IS_GPT_ON_TRIAL() {
      if (
        this.company &&
        this.company.gptPaymentStatus === 'on-trial' &&
        this.company.gptExpiryDate &&
        dayjs().isBefore(dayjs.unix(this.company.gptExpiryDate.seconds))
      ) {
        return true
      }

      return false
    },

    widgets() {
      if (!this.dialogs) return []
      return this.dialogs.filter((x) => x.isGptWidget)
    }
  },

  methods: {
    ...mapMutations({
      setSelectedGptChatbotName: 'gpt/SET_SELECTED_GPT_CHATBOT_NAME',
      setConsultation: 'gpt/SET_CONSULTATION',
      setQualifiedPercent: 'gpt/SET_QUALIFIED_PERCENT',
      setGptSpeechBubbleText: 'gpt/SET_GPT_SPEECH_BUBBLE_TEXT',
      setGptBotWelcomeMessage: 'gpt/SET_GPT_BOT_WELCOME_MESSAGE',
      setBotWidgetMainColor: 'gpt/SET_BOT_WIDGET_MAIN_COLOR',
      setBotWidgetSecondColor: 'gpt/SET_BOT_WIDGET_SECOND_COLOR',
      setBotWidgetRequestOptionsButtonColor: 'gpt/SET_BOT_WIDGET_REQUEST_OPTIONS_BUTTON_COLOR',
      setBotWidgetRequestOptionsIconColor: 'gpt/SET_BOT_WIDGET_REQUEST_OPTIONS_ICON_COLOR',
      setSelectedGptWidget: 'gpt/SET_SELECTED_GPT_WIDGET',
      setSelectedGptWidgetId: 'gpt/SET_SELECTED_GPT_WIDGET_ID',
      setGptLinks: 'gpt/SET_GPT_LINKS',
      setHasFilesUpdated: 'gpt/SET_HAS_FILES_UPDATED',
      setGptPrompt: 'gpt/SET_GPT_PROMPT'
    }),
    onTestGptBot(widgetId) {
      this.setSelectedGptWidgetId(widgetId)
      this.$router.push({ name: 'gpt-connect-test', params: { id: widgetId } })
    },
    async toggleEnableGptWidget(widget) {
      if (!this.IS_ADMIN) return
      if (!this.IS_GPT_ACTIVATED && !this.IS_GPT_ON_TRIAL) {
        this.$router.push({ name: 'payment', params: { action: 'activate-gpt-get-started' } })
        return
      }

      const isGptWidgetEnabled = Boolean(!widget.isGptWidgetEnabled)
      await this.$vs.loading()
      await this.$db.collection('dialogs').doc(widget.id).set({ isGptWidgetEnabled }, { merge: true })
      await this.$vs.loading.close()
    },
    async onEditGptWidget(id) {
      if (!this.IS_ADMIN) return
      await this.$vs.loading()
      const widgetRef = await this.$db.collection('dialogs').doc(id).get()
      const widget = widgetRef.data()
      if (widget && widget.gpt) {
        if (typeof widget.gptIsAlwaysOn === 'undefined') {
          const data = {
            gptIsAlwaysOn: true
          }
          widget.gptIsAlwaysOn = true
          await this.$db.collection('dialogs').doc(id).set(data, { merge: true })
        }
        this.setSelectedGptChatbotName(widget.gpt.botName)
        this.setConsultation(widget.gpt.consultation)
        this.setQualifiedPercent(widget.gpt.qualifiedPercent)
        this.setGptSpeechBubbleText(widget.gpt.speechBubbleText)
        this.setGptBotWelcomeMessage(widget.gpt.botWelcomeMessage)
        this.setBotWidgetMainColor(widget.gpt.botWidgetMainColor)
        this.setBotWidgetSecondColor(widget.gpt.botWidgetSecondColor)
        this.setBotWidgetRequestOptionsButtonColor(widget.gpt.botWidgetRequestOptionsButtonColor)
        this.setBotWidgetRequestOptionsIconColor(widget.gpt.botWidgetRequestOptionsIconColor)
        this.setSelectedGptWidgetId(id)
        this.setSelectedGptWidget(widget)
        this.setGptLinks([])
        this.setHasFilesUpdated(false)
        this.$emit('edit-gpt-widget')
      }

      await this.$vs.loading.close()
    },

    async onEditGptWidgetSettings(id) {
      if (!this.IS_ADMIN) return

      await this.$vs.loading()
      const widgetRef = await this.$db.collection('dialogs').doc(id).get()
      const widget = widgetRef.data()
      if (widget && widget.gpt) {
        if (typeof widget.gptIsAlwaysOn === 'undefined') {
          const data = {
            gptIsAlwaysOn: true
          }
          await this.$db.collection('dialogs').doc(id).set(data, { merge: true })
          widget.gptIsAlwaysOn = true
        }

        this.setSelectedGptChatbotName(widget.gpt.botName)
        this.setConsultation(widget.gpt.consultation)
        this.setQualifiedPercent(widget.gpt.qualifiedPercent)
        this.setGptSpeechBubbleText(widget.gpt.speechBubbleText)
        this.setGptBotWelcomeMessage(widget.gpt.botWelcomeMessage)
        this.setBotWidgetMainColor(widget.gpt.botWidgetMainColor)
        this.setBotWidgetSecondColor(widget.gpt.botWidgetSecondColor)
        this.setBotWidgetRequestOptionsButtonColor(widget.gpt.botWidgetRequestOptionsButtonColor)
        this.setBotWidgetRequestOptionsIconColor(widget.gpt.botWidgetRequestOptionsIconColor)
        this.setSelectedGptWidgetId(id)
        this.setSelectedGptWidget(widget)
        this.$emit('edit-gpt-widget-settings')
      }

      await this.$vs.loading.close()
    },

    async onEditGptPrompt(id) {
      if (!this.IS_ADMIN) return

      await this.$vs.loading()
      const gptPromptRef = await this.$db.collection('gpt-prompts').doc(id).get()
      const gptPromptDoc = gptPromptRef.data()
      this.setSelectedGptWidgetId(id)

      if (gptPromptDoc) {
        this.setGptPrompt(gptPromptDoc)
      } else {
        this.setGptPrompt(null)
      }

      this.$emit('edit-gpt-prompt')

      await this.$vs.loading.close()
    },

    async onDeleteGptWidgetPrompt(widget) {
      if (!this.IS_ADMIN) return
      this.setSelectedGptWidgetId(widget.id)
      this.setSelectedGptWidget(widget)
      this.deletePrompt = true
    },
    onCancelDeleteWidget() {
      this.setSelectedGptWidgetId(-1)
      this.setSelectedGptWidget(null)
    },

    async onDeleteGptWidget(id) {
      if (!this.IS_ADMIN) return
      await this.$vs.loading()
      await this.$db.collection('dialogs').doc(id).set(
        {
          isGptWidgetEnabled: this.$firebase.firestore.FieldValue.delete(),
          isGptWidget: this.$firebase.firestore.FieldValue.delete(),
          gpt: this.$firebase.firestore.FieldValue.delete()
        },
        { merge: true }
      )
      this.setSelectedGptWidgetId(-1)
      this.setSelectedGptWidget(null)
      this.$emit('delete-widget')

      await this.$vs.loading.close()
    }
  }
}
</script>
<style lang="scss" scoped>
.pgw-gwidgets {
  position: relative;
  padding: 5px 15px;
  margin-top: 26px;
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    padding: 0;
  }

  &-box {
    min-width: 360px;

    width: 100%;
    height: 100%;

    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;

    @media (max-width: 768px) {
      margin-top: 15px;
      max-width: 100%;
      min-width: auto;
      max-height: 74px;
    }

    @media (min-width: 769px) {
      max-height: 300px;
    }

    &--controls {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 15px 15px 0px 15px;

      &--header {
        font-family: 'Larsseit-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 41px;
        text-align: center;
        color: #575757;

        max-width: 15ch;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &--actions {
      align-self: center;
      display: flex;
      flex-direction: row;
      cursor: pointer;
      gap: 20px;

      &--btn {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      @media (max-width: 768px) {
        display: none;
      }
    }

    &--footer {
      @media (max-width: 768px) {
        display: none;
      }

      height: 41px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      background: rgba(43, 255, 111, 0.1);
      margin-top: 20px;

      &--vega {
        position: absolute;
        top: -30px;
        left: 30px;
      }

      &--wname {
        border-radius: 30px;
        background: #efefef;
        padding: 8px 25px;
        color: #575757;
        text-align: center;
        font-family: 'Larsseit-Regular';
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 40px;
        position: relative;

        &--icon {
          position: absolute;
          bottom: 0px;
          right: 0px;
        }
      }

      &--testing {
        display: flex;
        gap: 10px;
        position: absolute;
        right: 10px;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &--text {
          color: #575757;
          text-align: center;
          font-family: 'Larsseit-Regular';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
  &-box:last-child {
    margin-right: 0px;
  }
}

.dialog {
  &__icon {
    margin: 36px 10px 20px;
    padding: 10px;

    &:hover {
      opacity: 0.8;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.rounded-full {
  border-radius: 9999px !important;
}
</style>
