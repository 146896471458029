var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "18",
        height: "19",
        viewBox: "0 0 18 19",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M17 11.9961V15.5164C17 15.9833 16.8127 16.431 16.4793 16.7611C16.1459 17.0912 15.6937 17.2766 15.2222 17.2766H2.77778C2.30628 17.2766 1.8541 17.0912 1.5207 16.7611C1.1873 16.431 1 15.9833 1 15.5164V11.9961",
          stroke: _vm.stroke,
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M13.4446 5.83403L9.00011 1.43359L4.55566 5.83403",
          stroke: _vm.stroke,
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M9 1.43359V11.9947",
          stroke: _vm.stroke,
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }