<template>
  <div class="gpt-settings">
    <div class="flex items-center">
      <span class="gpt-settings--heading mr-3">{{ $t('gpt.widgetSettingsTitle') }}</span>
      <vx-tooltip class="flex items-center" position="top" :title="''" :text="$t('info.dialogSettingsTitleInfo')">
        <img :src="infoImg" style="display: inline-block; width: 15px" />
      </vx-tooltip>
    </div>

    <div class="gpt-settings-container">
      <div class="gpt-settings-container--close-icon" @click="$emit('close-gpt-widget-settings')">
        <close-icon :width="32" :height="32" color="#53535B" />
      </div>
      <div class="gpt-settings-container__item">
        <div @click="toggleAlwaysOn">
          <toggle-switch-icon :width="42" :height="42" :enabled="isAlwaysOn" />
        </div>
        <div>{{ $t('info.alwaysOn') }}</div>
      </div>
      <div class="gpt-settings-container__item">
        <div @click="toggleIsHiddenWhenNoAgentOnline">
          <toggle-switch-icon :width="42" :height="42" :enabled="isHiddenWhenNoAgentOnline" />
        </div>
        <div>{{ $t('gpt.hideDialogWhileNoAgentIsCurrentlyOnline') }}</div>
      </div>
      <div class="gpt-settings-container__item">
        <div @click="toggleSetCustomTime">
          <toggle-switch-icon :width="42" :height="42" :enabled="isEnabledCustomTimeSettings" />
        </div>
        <div>{{ $t('info.setCustomTime') }}</div>
      </div>
      <div class="gpt-settings-container__item">
        <DialogSettingsAvailabileTimeslots v-if="isEnabledCustomTimeSettings" :dialog="selectedGptWidget" settingsKey="gptTimeSettings" :isCustomDays="true" />
      </div>
    </div>
  </div>
</template>
<script>
import CloseIcon from '@/components/icons/CloseIcon'
import ToggleSwitchIcon from '@/components/icons/ToggleSwitchIcon.vue'
import DialogSettingsAvailabileTimeslots from '../pages/dialog/DialogSettingsAvailabileTimeslots.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'GptWidgetSettings',
  components: {
    CloseIcon,
    ToggleSwitchIcon,
    DialogSettingsAvailabileTimeslots
  },
  data() {
    return {
      isAlwaysOn: false,
      isEnabledCustomTimeSettings: false,
      isHiddenWhenNoAgentOnline: false,
      infoImg: require('@/assets/images/elements/info.svg')
    }
  },
  computed: {
    ...mapGetters({
      selectedGptWidgetId: 'gpt/selectedGptWidgetId',
      selectedGptWidget: 'gpt/selectedGptWidget'
    })
  },
  mounted() {
    if (this.selectedGptWidget && this.selectedGptWidgetId) {
      this.selectedGptWidget.id = this.selectedGptWidgetId
      this.isAlwaysOn = Boolean(this.selectedGptWidget.gptIsAlwaysOn)
      if (this.selectedGptWidget.gptTimeSettings) {
        this.isEnabledCustomTimeSettings = Boolean(this.selectedGptWidget.gptTimeSettings.enabled)
      }
      this.isHiddenWhenNoAgentOnline = Boolean(this.selectedGptWidget.gptIsHiddenWhenNoAgentOnline)
    }
  },
  methods: {
    async toggleAlwaysOn() {
      if (this.isAlwaysOn && !this.isEnabledCustomTimeSettings && !this.isHiddenWhenNoAgentOnline) {
        return
      }

      this.isAlwaysOn = !this.isAlwaysOn
      const data = {
        gptIsAlwaysOn: this.isAlwaysOn
      }
      if (this.isEnabledCustomTimeSettings) {
        this.isEnabledCustomTimeSettings = false
        data.gptTimeSettings = {
          enabled: false
        }
      }
      await this.$db.collection('dialogs').doc(this.selectedGptWidgetId).set(data, { merge: true })
    },
    async toggleSetCustomTime() {
      if (this.isEnabledCustomTimeSettings && !this.isHiddenWhenNoAgentOnline && !this.isAlwaysOn) {
        return
      }

      this.isEnabledCustomTimeSettings = !this.isEnabledCustomTimeSettings
      const data = {
        gptTimeSettings: {
          enabled: this.isEnabledCustomTimeSettings
        }
      }
      if (this.isEnabledCustomTimeSettings) {
        this.isAlwaysOn = false
        data.gptIsAlwaysOn = false
      }

      await this.$db.collection('dialogs').doc(this.selectedGptWidgetId).set(data, { merge: true })
    },
    async toggleIsHiddenWhenNoAgentOnline() {
      if (this.isHiddenWhenNoAgentOnline && !this.isEnabledCustomTimeSettings && !this.isAlwaysOn) {
        return
      }
      this.isHiddenWhenNoAgentOnline = !this.isHiddenWhenNoAgentOnline
      const data = {
        gptIsHiddenWhenNoAgentOnline: this.isHiddenWhenNoAgentOnline
      }
      if (!this.isEnabledCustomTimeSettings && !this.isAlwaysOn) {
        this.isAlwaysOn = true
        data.gptIsAlwaysOn = true
      }
      await this.$db.collection('dialogs').doc(this.selectedGptWidgetId).set(data, { merge: true })
    }
  }
}
</script>
<style lang="scss" scoped>
.gpt-settings {
  position: relative;
  margin-top: 26px;

  &--heading {
    color: #262629;
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
    font-family: 'Lato', Helvetica, Arial, sans-ser;
  }

  &-container {
    margin-top: 10px;
    background-color: #fff;
    border-radius: 6px;
    width: 100%;
    height: 100%;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;

    &--close-icon {
      position: absolute;
      right: 30px;
      top: 60px;
      cursor: pointer;
    }

    &__item {
      display: flex;
      gap: 10px;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
