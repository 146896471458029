var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-row pgw-gwidgets" },
    [
      _c(
        "vs-prompt",
        {
          attrs: {
            title: _vm.$t("vue.deleteDialog"),
            color: "danger",
            "cancel-text": _vm.$t("vue.cancel"),
            "accept-text": _vm.$t("vue.delete"),
            active: _vm.deletePrompt,
          },
          on: {
            cancel: _vm.onCancelDeleteWidget,
            accept: function ($event) {
              return _vm.onDeleteGptWidget(_vm.selectedGptWidgetId)
            },
            close: _vm.onCancelDeleteWidget,
            "update:active": function ($event) {
              _vm.deletePrompt = $event
            },
          },
        },
        [
          _vm.selectedGptWidget
            ? _c("div", { staticClass: "con-exemple-prompt" }, [
                _c("p", [
                  _c("strong", [_vm._v(_vm._s(_vm.selectedGptWidget.name))]),
                ]),
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("vue.deleteDialogMessage")) +
                    "\n    "
                ),
              ])
            : _vm._e(),
        ]
      ),
      _vm._l(_vm.widgets, function (widget, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass:
              "vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base",
          },
          [
            _c("div", { staticClass: "pgw-gwidgets-box" }, [
              _c("div", { staticClass: "pgw-gwidgets-box--controls" }, [
                widget && widget.gpt && widget.gpt.botName
                  ? _c(
                      "div",
                      { staticClass: "pgw-gwidgets-box--controls--header" },
                      [_vm._v(_vm._s(widget.gpt.botName))]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "pgw-gwidgets-box--controls--switch",
                    on: {
                      click: function ($event) {
                        return _vm.toggleEnableGptWidget(widget)
                      },
                    },
                  },
                  [
                    _c("toggle-switch-icon", {
                      attrs: {
                        enabled: widget.isGptWidgetEnabled || false,
                        width: 50,
                        height: 50,
                        color: "" + (_vm.IS_ADMIN ? "#275D73" : "#979797"),
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "pgw-gwidgets-box--actions" },
                [
                  _c("feather-icon", {
                    staticClass:
                      "inline-flex rounded-full pgw-gwidgets-box--actions--btn",
                    style: {
                      background:
                        "" +
                        (_vm.IS_ADMIN
                          ? "rgba(var(--vs-primary),.15)"
                          : "rgba(151, 151, 151,.15)"),
                      color: "" + (_vm.IS_ADMIN ? "#275D73" : "#979797"),
                    },
                    attrs: { icon: "EditIcon" },
                    on: {
                      click: function ($event) {
                        return _vm.onEditGptWidget(widget.id)
                      },
                    },
                  }),
                  _c("feather-icon", {
                    staticClass:
                      "inline-flex rounded-full pgw-gwidgets-box--actions--btn",
                    style: {
                      background:
                        "" +
                        (_vm.IS_ADMIN
                          ? "rgba(var(--vs-danger),.15)"
                          : "rgba(151, 151, 151,.15)"),
                      color:
                        "" + (_vm.IS_ADMIN ? "rgba(234,84,85)" : "#979797"),
                    },
                    attrs: { icon: "TrashIcon" },
                    on: {
                      click: function ($event) {
                        return _vm.onDeleteGptWidgetPrompt(widget)
                      },
                    },
                  }),
                  _c("feather-icon", {
                    staticClass:
                      "inline-flex rounded-full pgw-gwidgets-box--actions--btn",
                    style: {
                      background:
                        "" +
                        (_vm.IS_ADMIN
                          ? "rgba(28, 132, 57, .15)"
                          : "rgba(151, 151, 151,.15)"),
                      color:
                        "" + (_vm.IS_ADMIN ? "rgb(28, 132, 57)" : "#979797"),
                    },
                    attrs: { icon: "SettingsIcon" },
                    on: {
                      click: function ($event) {
                        return _vm.onEditGptWidgetSettings(widget.id)
                      },
                    },
                  }),
                  _vm.IS_CUSTOM_GPT_PROMPT_ENABLED
                    ? _c("feather-icon", {
                        staticClass:
                          "inline-flex rounded-full pgw-gwidgets-box--actions--btn",
                        style: {
                          background:
                            "" +
                            (_vm.IS_ADMIN
                              ? "rgba(28, 132, 57, .15)"
                              : "rgba(151, 151, 151,.15)"),
                          color:
                            "" +
                            (_vm.IS_ADMIN ? "rgb(28, 132, 57)" : "#979797"),
                        },
                        attrs: { icon: "ToolIcon" },
                        on: {
                          click: function ($event) {
                            return _vm.onEditGptPrompt(widget.id)
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c("div", { staticClass: "pgw-gwidgets-box--footer" }, [
                widget &&
                widget.isVegaWidget &&
                widget.isVegaWidgetEnabled &&
                widget.vega
                  ? _c(
                      "div",
                      { staticClass: "pgw-gwidgets-box--footer--vega" },
                      [_c("VegaActivatedIcon")],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "pgw-gwidgets-box--footer--wname" },
                  [
                    _vm._v(
                      "\n          " + _vm._s(widget.name) + "\n          "
                    ),
                    _c("CheckIcon", {
                      staticClass: "pgw-gwidgets-box--footer--wname--icon",
                    }),
                  ],
                  1
                ),
                widget && widget.isGptWidget && widget.gpt
                  ? _c(
                      "div",
                      {
                        staticClass: "pgw-gwidgets-box--footer--testing",
                        on: {
                          click: function ($event) {
                            return _vm.onTestGptBot(widget.id)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "pgw-gwidgets-box--footer--testing--text",
                          },
                          [_vm._v(_vm._s(_vm.$t("gpt.testBot")))]
                        ),
                        _c("RightArrowIcon"),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }