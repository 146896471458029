var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pag-lc-chat" }, [
    _c(
      "div",
      {
        staticClass: "pag-lc-chat-infocontainer",
        style:
          "background: " +
          (_vm.selectedGptWidget.step2ButtonColor || "#3034f7") +
          ";",
      },
      [
        _c("div", { staticClass: "pag-lc-chat-infocontainer-heading" }, [
          _c("img", {
            staticClass: "pag-lc-chat-infocontainer--icon",
            attrs: { src: _vm.botFace, alt: "Bot Face" },
          }),
          _c("div", { staticClass: "pag-lc-chat-infocontainer-details" }, [
            _c(
              "div",
              { staticClass: "pag-lc-chat-infocontainer-details--company" },
              [_vm._v(_vm._s(_vm.companyName))]
            ),
            _c(
              "div",
              { staticClass: "pag-lc-chat-infocontainer-details--botname" },
              [_vm._v(_vm._s(_vm.selectedGptChatbotName))]
            ),
          ]),
        ]),
        _c("div", { staticClass: "pag-caction" }, [
          _c("div", { staticClass: "pag-caction-img-container" }, [
            _c("img", {
              staticClass: "pag-caction-img",
              attrs: { src: _vm.defaultImageURL },
            }),
            _c("div", { staticClass: "pag-caction-img-online" }),
          ]),
          _c(
            "div",
            {
              staticClass: "pag-caction--child pag-caction-live-support",
              class: { "pag-caction--disabled": _vm.isTestMode },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("gpt.customization.contactLiveSupport")) +
                  "\n      "
              ),
            ]
          ),
          _c("div", { staticClass: "pag-caction-buttons-wrapper" }, [
            _vm.showChat
              ? _c(
                  "div",
                  {
                    staticClass:
                      "pag-caction--child pag-caction-button pag-caction-chat",
                    class: { "pag-caction--disabled": _vm.isTestMode },
                    style:
                      "color: " +
                      (_vm.botWidgetRequestOptionsIconColor || "#fff") +
                      "; background: " +
                      (_vm.botWidgetRequestOptionsButtonColor || "#275D73") +
                      ";",
                  },
                  [
                    _c("ChatIcon", {
                      attrs: {
                        width: 12,
                        height: 12,
                        color: _vm.isTestMode
                          ? "#B2B2B2"
                          : "" +
                            (_vm.botWidgetRequestOptionsIconColor || "#fff"),
                      },
                    }),
                    _vm._v(
                      "\n          " + _vm._s(_vm.$t("vue.chat")) + "\n        "
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.showAudio
              ? _c(
                  "div",
                  {
                    staticClass:
                      "pag-caction--child pag-caction-button pag-caction-audio",
                    class: { "pag-caction--disabled": _vm.isTestMode },
                    style:
                      "color: " +
                      (_vm.botWidgetRequestOptionsIconColor || "#fff") +
                      "; background: " +
                      (_vm.botWidgetRequestOptionsButtonColor || "#275D73") +
                      ";",
                  },
                  [
                    _c("PhoneCallIcon", {
                      attrs: {
                        width: 12,
                        height: 12,
                        color: _vm.isTestMode
                          ? "#B2B2B2"
                          : "" +
                            (_vm.botWidgetRequestOptionsIconColor || "#fff"),
                      },
                    }),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("vue.audio")) +
                        "\n        "
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.showVideo
              ? _c(
                  "div",
                  {
                    staticClass:
                      "pag-caction--child pag-caction-button pag-caction-video",
                    class: { "pag-caction--disabled": _vm.isTestMode },
                    style:
                      "color: " +
                      (_vm.botWidgetRequestOptionsIconColor || "#fff") +
                      "; background: " +
                      (_vm.botWidgetRequestOptionsButtonColor || "#275D73") +
                      ";",
                  },
                  [
                    _c("VideoCallIcon", {
                      attrs: {
                        width: 12,
                        height: 12,
                        color: _vm.isTestMode
                          ? "#B2B2B2"
                          : "" +
                            (_vm.botWidgetRequestOptionsIconColor || "#fff"),
                      },
                    }),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("vue.video")) +
                        "\n        "
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "pag-lc-close" },
          [_c("CloseIcon", { attrs: { width: 14, height: 14 } })],
          1
        ),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "pag-lc-chat-chatcontainer",
        staticStyle: { position: "relative" },
      },
      [
        _c(
          "div",
          {
            ref: "chatScrollArea",
            staticClass: "pag-lc-chat-scroll-area",
            staticStyle: {
              "--toolbar-background": "#3034f7",
              position: "relative",
              "padding-left": "20px",
            },
          },
          [
            _c(
              "div",
              { staticStyle: { padding: "10px 0px" } },
              _vm._l(_vm.gptTestChats, function (chat, index) {
                return _c("div", { key: index }, [
                  _c(
                    "div",
                    {
                      class: {
                        "pag-lc-chat-chatcontainer--visitor pag-lc-chat--message-visitor":
                          chat.sender === "visitor",
                        "pag-lc-chat-chatcontainer--bot pag-lc-chat--message":
                          chat.sender === "bot",
                      },
                    },
                    [
                      chat.sender === "bot"
                        ? _c("img", {
                            staticClass: "pag-lc-chat--botface",
                            attrs: { src: _vm.botFace, alt: "Bot Face" },
                          })
                        : _vm._e(),
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(_vm.sanitizeHtml(chat.message)),
                        },
                      }),
                    ]
                  ),
                ])
              }),
              0
            ),
          ]
        ),
        _vm.isFetchingAnswer
          ? _c("loading-indicator", {
              staticClass: "pag-lc-chat--loader",
              attrs: { color: "#3034F7" },
            })
          : _vm._e(),
      ],
      1
    ),
    _c("div", {
      staticClass: "pag-lc-chat-tool-container",
      style:
        "background: " +
        (_vm.selectedGptWidget.step2ButtonColor || "#3034f7") +
        ";",
    }),
    _c("div", { staticClass: "pag-lc-chat-message-container" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.question,
            expression: "question",
          },
        ],
        staticClass: "pag-lc-chat-message-container--message-input",
        attrs: {
          type: "text",
          placeholder: "Write a message ...",
          disabled: _vm.mode === "preview" || _vm.totalTestCount >= 10,
        },
        domProps: { value: _vm.question },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.question = $event.target.value
          },
        },
      }),
      _c(
        "div",
        { on: { click: _vm.onSendQuestion } },
        [
          _c("SendIcon", {
            staticClass: "pag-lc-chat-message-container--send-button",
            attrs: {
              width: 20,
              height: 20,
              color:
                _vm.isFetchingAnswer || _vm.totalTestCount >= 10
                  ? "#b2b2b2"
                  : "#3034F7",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "pag-lc-chat-poweredby-container" }, [
      _c(
        "div",
        { staticClass: "pag-lc-chat-poweredby-container-content" },
        [_c("powered-by"), _c("powered-by-text")],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }