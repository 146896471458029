<template>
  <div class="pag-choose-wrapper">
    <div v-if="!widgets || widgets.length === 0" class="mt-5 pag-choose__hints">
      <vs-icon class="pag-choose__hints__icon" icon-pack="material-icons" icon="error" size="22px" color="rgb(240, 85, 65)" />

      {{ $t('gpt.hints.title') }}

      <ul class="pag-choose__hints__options-list">
        <li class="pag-choose__hints__options-list__item">{{ $t('gpt.hints.option_1') }}</li>
        <li class="pag-choose__hints__options-list__item">{{ $t('gpt.hints.option_2') }}</li>
        <li class="pag-choose__hints__options-list__item">{{ $t('gpt.hints.option_3') }}</li>
      </ul>

      <div class="mt-5 vx-row">
        <div class="vx-col pl-5">
          <vs-button size="medium" color="primary" class="btn-main" @click.prevent="$router.push({ name: 'dialog', params: { action: 'add-web-connect' } })">
            {{ $t('vue.addWebConnect') }}
          </vs-button>
        </div>
      </div>
    </div>

    <div v-else class="pag-choose">
      <div class="pag-choose-select">
        <div class="pag-choose-select--label">{{ $t('gpt.choose.chatbotNameLabel') }}</div>
        <div>
          <input
            type="text"
            v-validate="'required'"
            name="chatbot-name"
            v-model="gptChatbotName"
            class="pag-choose-inputs--text-input"
            :placeholder="$t('gpt.choose.chatbotNameInput')"
          />
        </div>
        <div class="mt-4 pag-choose-select--label">{{ $t('gpt.choose.selectLabel') }}</div>
        <div>
          <select class="pag-choose-select--input" v-validate="'requiredSelect'" name="widgets" v-model="selectedGptWidgetId">
            <option disabled value="-1" selected>{{ $t('gpt.choose.selectInput') }}</option>
            <option v-for="option in widgets" :value="option.value" :key="option.value">{{ option.text }}</option>
          </select>
        </div>
        <div class="pag-choose--labelheading mt-4">{{ $t('gpt.choose.offerLiveConsultation') }}</div>

        <div class="pag-choose-radio">
          <input v-validate="'required'" type="radio" name="consultation" value="all" v-model="gptConsultation" />
          <label class="pag-choose-select--label">{{ $t('gpt.choose.allVisitors') }}</label>
        </div>
        <div class="pag-choose-radio" v-if="showOnlyQualifiedVisitorsOption">
          <input v-validate="'required'" type="radio" name="consultation" value="qualified" v-model="gptConsultation" />
          <label class="pag-choose-select--label">{{ $t('gpt.choose.qualifiedVisitors') }}</label>
          <br />
          <div v-if="gptConsultation === 'qualified'">
            <input
              type="text"
              style="width: 60px"
              name="qualified-percent"
              v-model="gptQualifiedPercent"
              class="pag-choose-inputs--text-input"
              :placeholder="$t('gpt.choose.chatbotNameInput')"
            />
            %
          </div>
        </div>
        <div v-else @click="toggleConsultation">
          <toggle-switch-icon :width="32" :height="32" :enabled="gptConsultation === 'all'" />
        </div>
      </div>
      <div class="pag-choose-preview">
        <div class="pag-choose-select--label">{{ $t('gpt.choose.previewLabel') }}</div>
        <div v-if="selectedGptWidgetId && selectedGptWidgetId !== -1 && dialog" class="pag-choose-preview--preview">
          <button-plus-text-new-preview
            v-if="dialog && dialog.type === 'button' && dialog.useNewButtonPlusTextStyle"
            :uploadType="dialog.useOneMediaForAllSteps ? 'allMedia' : 'buttonPlusTextNewMedia'"
            :uploadSubtype="dialog.useOneMediaForAllSteps && typeof dialog.allMedia === 'object' && dialog.allMedia !== null ? 'buttonPlusTextNewMedia' : ''"
            :dialog="dialog"
            :imageURL="defaultImageURL"
            :embedVideoURL="embedVideoURL"
          />
          <button-preview
            v-else
            :uploadType="dialog.useOneMediaForAllSteps ? 'allMedia' : 'buttonMedia'"
            :uploadSubtype="dialog.useOneMediaForAllSteps && typeof dialog.allMedia === 'object' && dialog.allMedia !== null ? 'buttonMedia' : ''"
            :dialog="dialog"
            :isForPreviewScreen="false"
            :canShowMinimizeIcon="canShowMinimizeIcon"
            :imageURL="defaultImageURL"
            :embedVideoURL="embedVideoURL"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { Validator } from 'vee-validate'
import validationDictionary from '@/validations/validationDictionary'
import ButtonPreview from '@/components/dialog/media/ButtonPreview'
import ButtonPlusTextNewPreview from '@/components/dialog/media/ButtonPlusTextNewPreview'
import ToggleSwitchIcon from '@/components/icons/ToggleSwitchIcon.vue'

export default {
  name: 'GptStepSettings',
  data() {
    return {
      gptChatbotName: '',
      gptConsultation: 'all',
      gptQualifiedPercent: 70,
      selectedGptWidgetId: -1,
      dialog: null,
      defaultImageURL: require('@/assets/images/dialog/contact-form-background.jpeg')
    }
  },
  components: {
    ButtonPreview,
    ButtonPlusTextNewPreview,
    ToggleSwitchIcon
  },

  computed: {
    ...mapGetters({
      company: 'company',
      activeUserInfo: 'activeUser',
      dialogs: 'dialogs',
      selectedGptChatbotName: 'gpt/selectedGptChatbotName',
      gptSelectedGptWidgetId: 'gpt/selectedGptWidgetId',
      gptSelectedGptWidget: 'gpt/selectedGptWidget'
    }),

    canShowMinimizeIcon() {
      if (((this.dialog && !this.dialog.isAlwaysShowButtonEnabled) || this.dialog.isMinimizeSmallButtonEnabled) && !this.dialog.isSmallButtonPreviewVisible) {
        return true
      }
      return false
    },

    embedVideoURL() {
      return this.defaultVideoURL
    },
    defaultVideoURL() {
      return '/Mann-Winken-SD.mp4'
    },

    widgets() {
      if (!this.dialogs || this.dialogs.length === 0) return []
      return this.dialogs.map((x) => {
        return {
          value: x.id,
          text: x.name
        }
      })
    },

    showOnlyQualifiedVisitorsOption() {
      if (this.gptSelectedGptWidget && this.gptSelectedGptWidget.isVegaWidgetEnabled) {
        return true
      }
      return false
    }
  },

  created() {
    /* Localize validator */
    Validator.localize(this.$i18n.locale, validationDictionary[this.$i18n.locale])

    if (this.gptSelectedGptWidgetId) {
      this.selectedGptWidgetId = this.gptSelectedGptWidgetId
    }

    if (this.selectedGptChatbotName) {
      this.gptChatbotName = this.selectedGptChatbotName
    }
  },
  methods: {
    ...mapMutations({
      setSelectedGptChatbotName: 'gpt/SET_SELECTED_GPT_CHATBOT_NAME',
      setConsultation: 'gpt/SET_CONSULTATION',
      setQualifiedPercent: 'gpt/SET_QUALIFIED_PERCENT',
      setGptSpeechBubbleText: 'gpt/SET_GPT_SPEECH_BUBBLE_TEXT',
      setGptBotWelcomeMessage: 'gpt/SET_GPT_BOT_WELCOME_MESSAGE',
      setBotWidgetMainColor: 'gpt/SET_BOT_WIDGET_MAIN_COLOR',
      setBotWidgetSecondColor: 'gpt/SET_BOT_WIDGET_SECOND_COLOR',
      setSelectedGptWidget: 'gpt/SET_SELECTED_GPT_WIDGET',
      setSelectedGptWidgetId: 'gpt/SET_SELECTED_GPT_WIDGET_ID',
      setBotWidgetRequestOptionsButtonColor: 'gpt/SET_BOT_WIDGET_REQUEST_OPTIONS_BUTTON_COLOR',
      setBotWidgetRequestOptionsIconColor: 'gpt/SET_BOT_WIDGET_REQUEST_OPTIONS_ICON_COLOR'
    }),
    toggleConsultation() {
      const consultation = this.gptConsultation === 'all' ? 'none' : 'all'
      this.gptConsultation = consultation
    },
    async onValidateSettings() {
      await this.$validator.validateAll(['widgets', 'chatbot-name', 'consultation'])
    },
    async updateGptWidget() {
      const widgetRef = await this.$db.collection('dialogs').doc(this.selectedGptWidgetId).get()
      if (widgetRef && widgetRef.data()) {
        const widget = widgetRef.data()
        this.dialog = widget
        this.setSelectedGptWidgetId(this.selectedGptWidgetId)
        this.setSelectedGptWidget(widget)
        if (widget && widget.gpt) {
          this.gptConsultation = widget.gpt.consultation
          this.gptChatbotName = widget.gpt.botName
          this.gptQualifiedPercent = widget.gpt.qualifiedPercent
          this.setSelectedGptChatbotName(widget.gpt.botName)
          if (widget.gpt.consultation) {
            this.setConsultation(widget.gpt.consultation)
          }
          this.setQualifiedPercent(widget.gpt.qualifiedPercent || 0)
          if (widget.gpt.speechBubbleText) {
            this.setGptSpeechBubbleText(widget.gpt.speechBubbleText)
          }
          if (widget.gpt.botWelcomeMessage) {
            this.setGptBotWelcomeMessage(widget.gpt.botWelcomeMessage)
          }
          if (widget.gpt.botWidgetMainColor) {
            this.setBotWidgetMainColor(widget.gpt.botWidgetMainColor)
          }
          if (widget.gpt.botWidgetSecondColor) {
            this.setBotWidgetSecondColor(widget.gpt.botWidgetSecondColor)
          }
          if (widget.gpt.botWidgetRequestOptionsButtonColor) {
            this.setBotWidgetRequestOptionsButtonColor(widget.gpt.botWidgetRequestOptionsButtonColor)
          }
          if (widget.gpt.botWidgetRequestOptionsIconColor) {
            this.setBotWidgetRequestOptionsIconColor(widget.gpt.botWidgetRequestOptionsIconColor)
          }
        }
      }
    }
  },
  watch: {
    async selectedGptWidgetId() {
      if (this.selectedGptWidgetId) {
        await this.updateGptWidget()
      }
    },
    gptConsultation() {
      this.setConsultation(this.gptConsultation)
      if (this.gptConsultation === 'all') {
        this.gptQualifiedPercent = 0
      }
    },
    gptChatbotName() {
      this.setSelectedGptChatbotName(this.gptChatbotName)
    },
    gptQualifiedPercent() {
      this.setQualifiedPercent(this.gptQualifiedPercent)
    }
  }
}
</script>
<style lang="scss" scoped>
.pag-choose {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 30px;

  input[type='radio'] {
    cursor: pointer;
  }

  --label {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #575757;
  }

  &--labelheading {
    color: #262629;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  input[type='radio'],
  label {
    vertical-align: middle; /* align the inline elements to the middle */
  }

  /* Optional: Add some space between the radio button and its label */
  input[type='radio'] + label {
    margin-left: 8px;
  }

  input[aria-invalid='true'] {
    /* Your styles here */
    border: 2px solid red; /* for example, to indicate an error */
    background-color: #ffe5e5; /* a light red background */
  }
  select[aria-invalid='true'] {
    /* Your styles here */
    border: 2px solid red; /* for example, to indicate an error */
    background-color: #ffe5e5; /* a light red background */
  }

  /* Style the label following an invalid radio button */
  input[type='radio'][aria-invalid='true'] + label {
    /* Your styles here */
    color: red; /* Change text color for example */
    background-color: #ffe5e5; /* A light red background */
    padding: 2px 5px; /* Some padding to make the background more noticeable */
    border-radius: 4px; /* Optional: add rounded corners to the label */
  }

  &-inputs {
    flex: 4;
    display: flex;
    flex-direction: column;
    margin-right: 100px;

    &--text {
      margin-top: 15px;

      &-input {
        padding: 10px;
        width: 90%;
        background: #ffffff;
        border: 1px solid #d9dbdd;
        border-radius: 8px;

        margin-top: 10px;

        font-family: 'Larsseit-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: -0.111111px;
        color: #878894;
      }
    }
  }

  &-wrapper {
    margin-top: 30px;
    width: 88vw;
    height: 90vh;

    @media (min-width: 1200px) {
      width: calc(100vw - 410px) !important;
    }

    max-height: 475px;
  }

  &__hints {
    position: relative;
    padding-left: 30px;

    &__icon {
      position: absolute;
      top: -1px;
      left: 0;
    }

    &__options-list {
      padding-left: 13px;

      &__item {
        position: relative;
        padding-left: 9px;

        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 8px;
          left: -3px;
          width: 2px;
          height: 2px;
          border-radius: 50%;
          background: rgb(87, 87, 87);
        }
      }
    }
  }

  &-select {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 5;
    align-self: flex-start;

    &--label {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #575757;
    }

    &--input {
      width: 90%;
      margin-top: 10px;
      padding: 10px;
      font-family: 'Larsseit-Regular';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 20px;
      letter-spacing: -0.111111px;
      color: #878894;
      border-radius: 8px;

      background: #ffffff;
      border: 1px solid #d9dbdd;
    }
  }

  &-preview {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 3;
    justify-self: flex-end;

    &--preview {
      margin-top: 20px;
      align-self: flex-start;
    }
  }
}
</style>
