var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pag-web" }, [
    _c("div", { staticClass: "pag-web--header" }, [
      _vm._v(_vm._s(_vm.$t("gpt.sources.crawl"))),
    ]),
    _c("div", { staticClass: "pag-web-crawl" }, [
      _c("div", { staticClass: "pag-web-crawl-actions" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.crawlLink,
              expression: "crawlLink",
            },
          ],
          staticClass: "pag-web-crawl-actions--input",
          attrs: {
            type: "text",
            name: "chatbot-secondcolor",
            placeholder: "https://www.example.com",
          },
          domProps: { value: _vm.crawlLink },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.crawlLink = $event.target.value
            },
          },
        }),
        _c(
          "div",
          {
            staticClass: "pag-web--btn",
            class: {
              "pag-web--invalid-url":
                !_vm.isCrawlLinkValid || _vm.isFetchingUrls,
            },
            on: {
              click: function ($event) {
                return _vm.onFetchLinks("url")
              },
            },
          },
          [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("gpt.sources.fetchLinks")) +
                "\n      "
            ),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "pag-web-crawl-info" },
        [
          _c("info-icon", { attrs: { color: "#000", width: 11, height: 11 } }),
          _c("div", { staticClass: "pag-web--infotext" }, [
            _vm._v(_vm._s(_vm.$t("gpt.sources.crawlInfo"))),
          ]),
        ],
        1
      ),
      _vm.progress && _vm.progress < 100
        ? _c("div", { staticClass: "pag-web-progress-bar-container" }, [
            _c("div", {
              staticClass: "pag-web-progress-bar",
              style: { width: _vm.progress + "%" },
            }),
            _c("div", { staticClass: "pag-web-progress-label" }, [
              _c("span", [_vm._v(_vm._s(_vm.progress) + "% ")]),
              _c("span", { staticClass: "pag-web-progress-label--small" }, [
                _vm._v(
                  "[Total Links: " +
                    _vm._s(_vm.totalLinksCount) +
                    ", Remaining Links: " +
                    _vm._s(_vm.remainingLinksCount) +
                    " ]"
                ),
              ]),
            ]),
          ])
        : _vm._e(),
    ]),
    _vm._m(0),
    _c("div", { staticClass: "pag-web--header" }, [
      _vm._v(_vm._s(_vm.$t("gpt.sources.submitSitemap"))),
    ]),
    _c("div", { staticClass: "pag-web-crawl" }, [
      _c("div", { staticClass: "pag-web-crawl-actions" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.sitemapLink,
              expression: "sitemapLink",
            },
          ],
          staticClass: "pag-web-crawl-actions--input",
          attrs: {
            type: "text",
            name: "chatbot-secondcolor",
            placeholder:
              "https://www.example.com/sitemap.xml,https://www.example.com/sitemap2.xml",
          },
          domProps: { value: _vm.sitemapLink },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.sitemapLink = $event.target.value
            },
          },
        }),
        _c(
          "div",
          {
            staticClass: "pag-web--btn",
            class: {
              "pag-web--invalid-url":
                !_vm.isSitemapLinkValid || _vm.isFetchingUrls,
            },
            on: {
              click: function ($event) {
                return _vm.onFetchLinks("sitemap")
              },
            },
          },
          [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("gpt.sources.loadSitemap")) +
                "\n      "
            ),
          ]
        ),
      ]),
    ]),
    _vm.gptLinks.length > 0
      ? _c(
          "div",
          { staticClass: "pag-web-includedlinks" },
          [
            _c("div", { staticClass: "pag-web--header" }, [
              _vm._v(_vm._s(_vm.$t("gpt.sources.includedLinks"))),
            ]),
            _vm.isFetchingUrls
              ? _c("LoadingIndicator", { staticClass: "pag-files-loader" })
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "pag-web--delete",
                on: {
                  click: function ($event) {
                    return _vm.onDeleteAllLinks()
                  },
                },
              },
              [_c("DeleteIcon", { attrs: { width: 25, height: 25 } })],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      {
        ref: "chatScrollArea",
        staticClass: "pag-web-scroll-area",
        staticStyle: {
          "max-height": "300px",
          "--toolbar-background": "#275D73",
          position: "relative",
          "padding-left": "20px",
        },
      },
      [
        _c(
          "div",
          { key: _vm.linksKey, staticClass: "pag-web-linkscontainer" },
          _vm._l(_vm.gptLinks, function (link, index) {
            return _c("div", { key: index, staticClass: "pag-web-linkslist" }, [
              _c("input", {
                staticClass: "pag-web-crawl-actions--input",
                attrs: { type: "text", placeholder: "https://www.example.com" },
                domProps: { value: link },
                on: {
                  input: function ($event) {
                    return _vm.onUpdateLink($event, index)
                  },
                },
              }),
              _c(
                "div",
                {
                  staticClass: "pag-web-crawl-actions--delete",
                  on: {
                    click: function ($event) {
                      return _vm.onDeleteLink(index)
                    },
                  },
                },
                [_c("DeleteIcon")],
                1
              ),
            ])
          }),
          0
        ),
      ]
    ),
    _c(
      "div",
      { staticClass: "pag-web--addlink", on: { click: _vm.onAddLink } },
      [_vm._v("Add")]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pag-web-or" }, [
      _c("div", { staticClass: "pag-web-or--line" }),
      _c("div", { staticClass: "pag-web-or--text" }, [_vm._v("OR")]),
      _c("div", { staticClass: "pag-web-or--line" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }