<template>
  <div class="gpt-settings">
    <div class="flex items-center">
      <span class="gpt-settings--heading mr-3">{{ $t('gpt.widgetCustomPromptTitle') }}</span>
      <vx-tooltip class="flex items-center" position="top" :title="''" :text="$t('gpt.widgetCustomPromptInfo')">
        <img :src="infoImg" style="display: inline-block; width: 15px" />
      </vx-tooltip>
    </div>

    <div class="gpt-settings-container">
      <div class="gpt-settings-container--close-icon" @click="$emit('close-gpt-prompt')">
        <close-icon :width="32" :height="32" color="#53535B" />
      </div>
      <div class="gpt-settings-container__item">
        <div @click="toggleEnableGptPrompt">
          <toggle-switch-icon :width="42" :height="42" :enabled="isGptPromptEnabled" />
        </div>
        <div>{{ $t('vue.enabled') }}</div>
      </div>
      <div class="gpt-settings-container__item">
        <div>{{ $t('gpt.prompt') }}</div>
      </div>
      <div class="gpt-settings-container__item w-full">
        <vs-textarea
          name="prompt"
          counter="2000"
          height="300px"
          :placeholder="$tp('gpt.widgetCustomPromptInfo')"
          v-model="gptPromptText"
          v-validate="{
            max: 2000
          }"
          class="w-full"
        />
      </div>
      <p v-if="errors.first('prompt')" class="gpt-settings-error">* {{ errors.first('prompt') }}</p>
      <div class="gpt-settings__action mt60" @click="onSaveGptPrompt">
        {{ $t('vue.save') }}
      </div>
    </div>
  </div>
</template>
<script>
import CloseIcon from '@/components/icons/CloseIcon'
import ToggleSwitchIcon from '@/components/icons/ToggleSwitchIcon.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'GptWidgetPrompt',
  components: {
    CloseIcon,
    ToggleSwitchIcon
  },
  data() {
    return {
      isGptPromptEnabled: false,
      gptPromptText: '',
      infoImg: require('@/assets/images/elements/info.svg')
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      selectedGptWidgetId: 'gpt/selectedGptWidgetId',
      gptPrompt: 'gpt/gptPrompt'
    })
  },
  mounted() {
    if (this.gptPrompt && this.selectedGptWidgetId) {
      this.isGptPromptEnabled = Boolean(this.gptPrompt.enabled)
      this.gptPromptText = this.gptPrompt && this.gptPrompt.prompt ? this.gptPrompt.prompt : ''
    } else {
      this.isGptPromptEnabled = false
      this.gptPromptText = this.gptPrompt && this.gptPrompt.prompt ? this.gptPrompt.prompt : ''
    }
  },
  methods: {
    async toggleEnableGptPrompt() {
      this.isGptPromptEnabled = !this.isGptPromptEnabled
      const data = {
        enabled: this.isGptPromptEnabled,
        companyId: this.activeUserInfo.company
      }
      await this.$db.collection('gpt-prompts').doc(this.selectedGptWidgetId).set(data, { merge: true })
    },
    async onSaveGptPrompt() {
      const result = await this.$validator.validateAll()
      if (result) {
        await this.$vs.loading()
        const data = {
          enabled: this.isGptPromptEnabled,
          prompt: this.gptPromptText || '',
          companyId: this.activeUserInfo.company
        }
        await this.$db.collection('gpt-prompts').doc(this.selectedGptWidgetId).set(data, { merge: true })
        this.$emit('close-gpt-prompt')
        await this.$vs.loading.close()
        this.$nextTick(() => {
          this.errors.clear()
          this.$validator.reset()
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.gpt-settings {
  position: relative;
  margin-top: 26px;

  .mt60 {
    margin-top: 60px;
  }

  &-error {
    color: rgb(240, 85, 65);
    font-size: 11px;
  }

  &__action {
    background: #3034f7;
    border-radius: 8px;
    width: 148px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: 'Larsseit';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 19px;
    letter-spacing: -0.111111px;
    color: #ffffff;

    &--disabled {
      background: #d3d3d3;
    }

    cursor: pointer;
  }

  &--heading {
    color: #262629;
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
    font-family: 'Lato', Helvetica, Arial, sans-ser;
  }

  &-container {
    margin-top: 10px;
    background-color: #fff;
    border-radius: 6px;
    width: 100%;
    height: 100%;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;

    &--close-icon {
      position: absolute;
      right: 30px;
      top: 60px;
      cursor: pointer;
    }

    &__item {
      display: flex;
      gap: 10px;
      justify-content: center;
      align-items: center;

      &__inputarea {
        width: 900px;
        height: 320px;
        padding: 20px;
      }
    }
  }
}
</style>
