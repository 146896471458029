<template>
  <svg width="45" height="69" viewBox="0 0 45 69" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_10088_2321" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="4" y="43" width="20" height="26">
      <path
        d="M23.5527 43.3906V65.9067C23.5527 67.066 22.613 68.0058 21.4537 68.0058C20.9801 68.0058 20.5205 67.8457 20.1494 67.5514L4.48714 55.1303C7.06432 53.1498 10.1731 51.3571 13.8134 49.7522C17.4538 48.1472 20.7002 46.0267 23.5527 43.3906Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_10088_2321)">
      <path
        d="M23.5525 43.3906V65.9067C23.5525 67.066 22.6127 68.0058 21.4534 68.0058C20.9799 68.0058 20.5202 67.8457 20.1492 67.5514L4.4869 55.1303C7.06408 53.1498 10.1728 51.3571 13.8132 49.7522C17.4535 48.1472 20.6999 46.0267 23.5525 43.3906Z"
        :fill="botWidgetMainColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.02761 37.4336L30.1711 52.6091C24.1957 59.3292 17.2244 62.3532 9.25722 61.6812C1.29002 61.0092 -0.119849 52.9266 5.02761 37.4336Z"
        :fill="botWidgetSecondColor"
      />
    </g>
    <path
      d="M20.0342 14.3222C20.0342 15.0453 20.6204 15.6315 21.3435 15.6315C22.0666 15.6315 22.6528 15.0453 22.6528 14.3222L20.0342 14.3222ZM20.0342 9.8125L20.0342 14.3222L22.6528 14.3222L22.6528 9.8125L20.0342 9.8125Z"
      :fill="botWidgetMainColor"
    />
    <path
      d="M32.9779 13.3047H12.0085C5.51445 13.3047 0.25 18.5692 0.25 25.0632V46.6706C0.25 53.1647 5.51445 58.4292 12.0085 58.4292H32.9779C39.4719 58.4292 44.7364 53.1647 44.7364 46.6706V25.0632C44.7364 18.5692 39.4719 13.3047 32.9779 13.3047Z"
      :fill="botWidgetMainColor"
    />
    <ellipse cx="12.2512" cy="29.5207" rx="5.30977" ry="5.30979" fill="white" />
    <path
      d="M31.5177 40.2145C30.1102 44.5341 26.3124 47.6289 21.8482 47.6289C17.384 47.6289 13.5862 44.5341 12.1787 40.2146"
      :stroke="botWidgetSecondColor"
      stroke-width="4.86193"
      stroke-linecap="round"
    />
    <path
      d="M27.4531 30.759C28.0566 28.8945 29.685 27.5586 31.5991 27.5586C33.5132 27.5586 35.1416 28.8944 35.7451 30.759"
      :stroke="botWidgetSecondColor"
      stroke-width="4.86193"
      stroke-linecap="round"
    />
    <path
      d="M25.1259 5.51885C25.1259 7.5676 23.465 9.22843 21.4163 9.22843C19.3676 9.22843 17.7067 7.5676 17.7067 5.51885C17.7067 3.4701 19.3676 1.80926 21.4163 1.80926C23.465 1.80926 25.1259 3.4701 25.1259 5.51885Z"
      :stroke="botWidgetMainColor"
      stroke-width="2.61852"
    />
  </svg>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'RoboChatIcon',
  computed: {
    ...mapGetters({
      botWidgetMainColor: 'gpt/botWidgetMainColor',
      botWidgetSecondColor: 'gpt/botWidgetSecondColor'
    })
  }
}
</script>
