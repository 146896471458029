<template>
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_10530_446)">
      <path
        d="M9.50004 17.4154C13.8723 17.4154 17.4167 13.871 17.4167 9.4987C17.4167 5.12644 13.8723 1.58203 9.50004 1.58203C5.12779 1.58203 1.58337 5.12644 1.58337 9.4987C1.58337 13.871 5.12779 17.4154 9.50004 17.4154Z"
        fill="#F1A342"
        stroke="#F1A342"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M9.5 12.6667V9.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M9.5 6.33203H9.50792" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_10530_446">
        <rect width="19" height="19" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'GptInfoIcon'
}
</script>
