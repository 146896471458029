var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pag-choose-wrapper" }, [
    !_vm.widgets || _vm.widgets.length === 0
      ? _c(
          "div",
          { staticClass: "mt-5 pag-choose__hints" },
          [
            _c("vs-icon", {
              staticClass: "pag-choose__hints__icon",
              attrs: {
                "icon-pack": "material-icons",
                icon: "error",
                size: "22px",
                color: "rgb(240, 85, 65)",
              },
            }),
            _vm._v("\n\n    " + _vm._s(_vm.$t("gpt.hints.title")) + "\n\n    "),
            _c("ul", { staticClass: "pag-choose__hints__options-list" }, [
              _c(
                "li",
                { staticClass: "pag-choose__hints__options-list__item" },
                [_vm._v(_vm._s(_vm.$t("gpt.hints.option_1")))]
              ),
              _c(
                "li",
                { staticClass: "pag-choose__hints__options-list__item" },
                [_vm._v(_vm._s(_vm.$t("gpt.hints.option_2")))]
              ),
              _c(
                "li",
                { staticClass: "pag-choose__hints__options-list__item" },
                [_vm._v(_vm._s(_vm.$t("gpt.hints.option_3")))]
              ),
            ]),
            _c("div", { staticClass: "mt-5 vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col pl-5" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "btn-main",
                      attrs: { size: "medium", color: "primary" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.$router.push({
                            name: "dialog",
                            params: { action: "add-web-connect" },
                          })
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("vue.addWebConnect")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        )
      : _c("div", { staticClass: "pag-choose" }, [
          _c("div", { staticClass: "pag-choose-select" }, [
            _c("div", { staticClass: "pag-choose-select--label" }, [
              _vm._v(_vm._s(_vm.$t("gpt.choose.chatbotNameLabel"))),
            ]),
            _c("div", [
              _c("input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.gptChatbotName,
                    expression: "gptChatbotName",
                  },
                ],
                staticClass: "pag-choose-inputs--text-input",
                attrs: {
                  type: "text",
                  name: "chatbot-name",
                  placeholder: _vm.$t("gpt.choose.chatbotNameInput"),
                },
                domProps: { value: _vm.gptChatbotName },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.gptChatbotName = $event.target.value
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "mt-4 pag-choose-select--label" }, [
              _vm._v(_vm._s(_vm.$t("gpt.choose.selectLabel"))),
            ]),
            _c("div", [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "requiredSelect",
                      expression: "'requiredSelect'",
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectedGptWidgetId,
                      expression: "selectedGptWidgetId",
                    },
                  ],
                  staticClass: "pag-choose-select--input",
                  attrs: { name: "widgets" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.selectedGptWidgetId = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                [
                  _c(
                    "option",
                    { attrs: { disabled: "", value: "-1", selected: "" } },
                    [_vm._v(_vm._s(_vm.$t("gpt.choose.selectInput")))]
                  ),
                  _vm._l(_vm.widgets, function (option) {
                    return _c(
                      "option",
                      { key: option.value, domProps: { value: option.value } },
                      [_vm._v(_vm._s(option.text))]
                    )
                  }),
                ],
                2
              ),
            ]),
            _c("div", { staticClass: "pag-choose--labelheading mt-4" }, [
              _vm._v(_vm._s(_vm.$t("gpt.choose.offerLiveConsultation"))),
            ]),
            _c("div", { staticClass: "pag-choose-radio" }, [
              _c("input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.gptConsultation,
                    expression: "gptConsultation",
                  },
                ],
                attrs: { type: "radio", name: "consultation", value: "all" },
                domProps: { checked: _vm._q(_vm.gptConsultation, "all") },
                on: {
                  change: function ($event) {
                    _vm.gptConsultation = "all"
                  },
                },
              }),
              _c("label", { staticClass: "pag-choose-select--label" }, [
                _vm._v(_vm._s(_vm.$t("gpt.choose.allVisitors"))),
              ]),
            ]),
            _vm.showOnlyQualifiedVisitorsOption
              ? _c("div", { staticClass: "pag-choose-radio" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.gptConsultation,
                        expression: "gptConsultation",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      name: "consultation",
                      value: "qualified",
                    },
                    domProps: {
                      checked: _vm._q(_vm.gptConsultation, "qualified"),
                    },
                    on: {
                      change: function ($event) {
                        _vm.gptConsultation = "qualified"
                      },
                    },
                  }),
                  _c("label", { staticClass: "pag-choose-select--label" }, [
                    _vm._v(_vm._s(_vm.$t("gpt.choose.qualifiedVisitors"))),
                  ]),
                  _c("br"),
                  _vm.gptConsultation === "qualified"
                    ? _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.gptQualifiedPercent,
                              expression: "gptQualifiedPercent",
                            },
                          ],
                          staticClass: "pag-choose-inputs--text-input",
                          staticStyle: { width: "60px" },
                          attrs: {
                            type: "text",
                            name: "qualified-percent",
                            placeholder: _vm.$t("gpt.choose.chatbotNameInput"),
                          },
                          domProps: { value: _vm.gptQualifiedPercent },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.gptQualifiedPercent = $event.target.value
                            },
                          },
                        }),
                        _vm._v("\n          %\n        "),
                      ])
                    : _vm._e(),
                ])
              : _c(
                  "div",
                  { on: { click: _vm.toggleConsultation } },
                  [
                    _c("toggle-switch-icon", {
                      attrs: {
                        width: 32,
                        height: 32,
                        enabled: _vm.gptConsultation === "all",
                      },
                    }),
                  ],
                  1
                ),
          ]),
          _c("div", { staticClass: "pag-choose-preview" }, [
            _c("div", { staticClass: "pag-choose-select--label" }, [
              _vm._v(_vm._s(_vm.$t("gpt.choose.previewLabel"))),
            ]),
            _vm.selectedGptWidgetId &&
            _vm.selectedGptWidgetId !== -1 &&
            _vm.dialog
              ? _c(
                  "div",
                  { staticClass: "pag-choose-preview--preview" },
                  [
                    _vm.dialog &&
                    _vm.dialog.type === "button" &&
                    _vm.dialog.useNewButtonPlusTextStyle
                      ? _c("button-plus-text-new-preview", {
                          attrs: {
                            uploadType: _vm.dialog.useOneMediaForAllSteps
                              ? "allMedia"
                              : "buttonPlusTextNewMedia",
                            uploadSubtype:
                              _vm.dialog.useOneMediaForAllSteps &&
                              typeof _vm.dialog.allMedia === "object" &&
                              _vm.dialog.allMedia !== null
                                ? "buttonPlusTextNewMedia"
                                : "",
                            dialog: _vm.dialog,
                            imageURL: _vm.defaultImageURL,
                            embedVideoURL: _vm.embedVideoURL,
                          },
                        })
                      : _c("button-preview", {
                          attrs: {
                            uploadType: _vm.dialog.useOneMediaForAllSteps
                              ? "allMedia"
                              : "buttonMedia",
                            uploadSubtype:
                              _vm.dialog.useOneMediaForAllSteps &&
                              typeof _vm.dialog.allMedia === "object" &&
                              _vm.dialog.allMedia !== null
                                ? "buttonMedia"
                                : "",
                            dialog: _vm.dialog,
                            isForPreviewScreen: false,
                            canShowMinimizeIcon: _vm.canShowMinimizeIcon,
                            imageURL: _vm.defaultImageURL,
                            embedVideoURL: _vm.embedVideoURL,
                          },
                        }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }