<template>
  <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_10101_3220)">
      <mask id="mask0_10101_3220" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="1" y="8" width="6" height="7">
        <path
          d="M6.69702 8.12891V14.0234C6.69702 14.3347 6.44465 14.5871 6.13333 14.5871C6.00838 14.5871 5.88697 14.5456 5.78818 14.4691L1.57702 11.2076C2.26911 10.6883 3.10396 10.2181 4.08156 9.79723C5.05916 9.37632 5.93098 8.82021 6.69702 8.12891Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_10101_3220)">
        <path
          d="M6.69653 8.12891V14.0234C6.69653 14.3347 6.44416 14.5871 6.13284 14.5871C6.00789 14.5871 5.88648 14.5456 5.78769 14.4691L1.57653 11.2076C2.26863 10.6883 3.10347 10.2181 4.08107 9.79723C5.05867 9.37632 5.93049 8.82021 6.69653 8.12891Z"
          fill="#3034F7"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.72156 6.56641L8.47376 10.5462C6.86909 12.3085 4.99697 13.1016 2.8574 12.9254C0.717839 12.7491 0.339223 10.6295 1.72156 6.56641Z"
          fill="#090DC2"
        />
      </g>
      <path
        d="M9.22744 0.238281H3.59618C1.85223 0.238281 0.438477 1.65203 0.438477 3.39598V8.91454C0.438477 10.6585 1.85223 12.0722 3.59618 12.0722H9.22744C10.9714 12.0722 12.3851 10.6585 12.3851 8.91454V3.39598C12.3851 1.65203 10.9714 0.238281 9.22744 0.238281Z"
        fill="#3034F7"
      />
      <path
        d="M4.98965 8.69279C3.82963 8.32368 2.99854 7.32769 2.99854 6.15694C2.99854 4.9862 3.82962 3.99022 4.98962 3.62109"
        stroke="white"
        stroke-width="1.30565"
        stroke-linecap="round"
      />
      <path
        d="M7.83384 8.69279C8.99385 8.32368 9.82495 7.32769 9.82495 6.15694C9.82495 4.9862 8.99387 3.99022 7.83387 3.62109"
        stroke="#275D73"
        stroke-width="1.30565"
        stroke-linecap="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.61358 6.44592L6.31072 7.49451C6.14944 7.62431 5.91348 7.5988 5.78368 7.43752C5.73008 7.37092 5.70085 7.28799 5.70085 7.2025V5.10531C5.70085 4.89829 5.86867 4.73047 6.07569 4.73047C6.16119 4.73047 6.24411 4.75969 6.31072 4.8133L7.61358 5.86189C7.77486 5.99169 7.80037 6.22765 7.67058 6.38893C7.65368 6.40992 7.63457 6.42902 7.61358 6.44592Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_10101_3220">
        <rect width="11.9467" height="14.9333" fill="white" transform="translate(0.440186 0.238281)" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'PoweredByIcon'
}
</script>
