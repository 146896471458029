var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gpt-settings" }, [
    _c(
      "div",
      { staticClass: "flex items-center" },
      [
        _c("span", { staticClass: "gpt-settings--heading mr-3" }, [
          _vm._v(_vm._s(_vm.$t("gpt.widgetCustomPromptTitle"))),
        ]),
        _c(
          "vx-tooltip",
          {
            staticClass: "flex items-center",
            attrs: {
              position: "top",
              title: "",
              text: _vm.$t("gpt.widgetCustomPromptInfo"),
            },
          },
          [
            _c("img", {
              staticStyle: { display: "inline-block", width: "15px" },
              attrs: { src: _vm.infoImg },
            }),
          ]
        ),
      ],
      1
    ),
    _c("div", { staticClass: "gpt-settings-container" }, [
      _c(
        "div",
        {
          staticClass: "gpt-settings-container--close-icon",
          on: {
            click: function ($event) {
              return _vm.$emit("close-gpt-prompt")
            },
          },
        },
        [
          _c("close-icon", {
            attrs: { width: 32, height: 32, color: "#53535B" },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "gpt-settings-container__item" }, [
        _c(
          "div",
          { on: { click: _vm.toggleEnableGptPrompt } },
          [
            _c("toggle-switch-icon", {
              attrs: { width: 42, height: 42, enabled: _vm.isGptPromptEnabled },
            }),
          ],
          1
        ),
        _c("div", [_vm._v(_vm._s(_vm.$t("vue.enabled")))]),
      ]),
      _c("div", { staticClass: "gpt-settings-container__item" }, [
        _c("div", [_vm._v(_vm._s(_vm.$t("gpt.prompt")))]),
      ]),
      _c(
        "div",
        { staticClass: "gpt-settings-container__item w-full" },
        [
          _c("vs-textarea", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: {
                  max: 2000,
                },
                expression: "{\n          max: 2000\n        }",
              },
            ],
            staticClass: "w-full",
            attrs: {
              name: "prompt",
              counter: "2000",
              height: "300px",
              placeholder: _vm.$tp("gpt.widgetCustomPromptInfo"),
            },
            model: {
              value: _vm.gptPromptText,
              callback: function ($$v) {
                _vm.gptPromptText = $$v
              },
              expression: "gptPromptText",
            },
          }),
        ],
        1
      ),
      _vm.errors.first("prompt")
        ? _c("p", { staticClass: "gpt-settings-error" }, [
            _vm._v("* " + _vm._s(_vm.errors.first("prompt"))),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "gpt-settings__action mt60",
          on: { click: _vm.onSaveGptPrompt },
        },
        [_vm._v("\n      " + _vm._s(_vm.$t("vue.save")) + "\n    ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }