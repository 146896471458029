var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.activeUserInfo && _vm.company
        ? _c(
            "div",
            {
              staticClass: "pa-gpt",
              class: {
                "pa-gpt--bannermargintop":
                  _vm.canShowTrialBanner && !_vm.isMobileApp,
              },
            },
            [
              !_vm.isMobile
                ? _c("div", { staticClass: "pa-gpt-action" }, [
                    _vm.showActivated
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "pa-gpt-action--connected pa-gpt-action--btn",
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  width: "19",
                                  height: "19",
                                  viewBox: "0 0 19 19",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("circle", {
                                  attrs: {
                                    cx: "9.5",
                                    cy: "9.5",
                                    r: "9.5",
                                    fill: "#2BFF6F",
                                  },
                                }),
                                _c("path", {
                                  attrs: {
                                    d: "M13.6 7L8.375 12.7L6 10.1091",
                                    stroke: "white",
                                    "stroke-width": "1.9",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round",
                                  },
                                }),
                              ]
                            ),
                            _c("div", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("gpt.activated")) +
                                  "\n        "
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    !_vm.showActivated &&
                    !_vm.isGetStarted &&
                    !_vm.hasAddedGPTConnectWidgets
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "pa-gpt-action--add pa-gpt-action--btn",
                            on: { click: _vm.onGetStarted },
                          },
                          [
                            _c("div", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("gpt.getStarted")) +
                                  "\n        "
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm.showActivateGPT &&
                    !_vm.isTesting &&
                    (_vm.isGetStarted || _vm.hasAddedGPTConnectWidgets)
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "pa-gpt-action--test pa-gpt-action--btn",
                          },
                          [
                            _c("EditIcon"),
                            _c("div", [_vm._v(_vm._s(_vm.$t("gpt.testMode")))]),
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.isTesting &&
                    !_vm.processToAddGptConnectToWidgetStarted &&
                    (_vm.showActivated ||
                      _vm.isGetStarted ||
                      _vm.hasAddedGPTConnectWidgets)
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "pa-gpt-action--add pa-gpt-action--btn",
                            class: {
                              "pa-gpt-action--btn--disabled": !_vm.IS_ADMIN,
                            },
                            on: {
                              click: _vm.onStartProcessToAddGptConnectToWidget,
                            },
                          },
                          [
                            _c("div", [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    width: "12",
                                    height: "12",
                                    viewBox: "0 0 12 12",
                                    fill: "none",
                                    xmlns: "http://www.w3.org/2000/svg",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M6 2.5V9.5",
                                      stroke: "white",
                                      "stroke-width": "2",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                    },
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d: "M2.5 6H9.5",
                                      stroke: "white",
                                      "stroke-width": "2",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                    },
                                  }),
                                ]
                              ),
                            ]),
                            _c("div", [
                              _vm._v(_vm._s(_vm.$t("gpt.newChatbot"))),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm.showActivateGPT &&
                    !_vm.isTesting &&
                    (_vm.isGetStarted || _vm.hasAddedGPTConnectWidgets) &&
                    !_vm.processToAddGptConnectToWidgetStarted
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "pa-gpt-action--test pa-gpt-action--btn pa-gpt-action--test--grow",
                          },
                          [
                            _c("InfoIcon"),
                            _c(
                              "div",
                              {
                                staticClass: "pa-gpt-action--test--smallertext",
                              },
                              [_vm._v(_vm._s(_vm.$t("gpt.subscribeMessage")))]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "pa-gpt-action--test--subscribe",
                                on: { click: _vm.onActivateGpt },
                              },
                              [_vm._v(_vm._s(_vm.$t("pricing.subscribeNow")))]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
              !_vm.isTesting
                ? _c(
                    "div",
                    { staticClass: "pa-gpt-content" },
                    [
                      _vm.gptIsProcessing
                        ? _c(
                            "div",
                            { staticClass: "pa-gpt-content__loading" },
                            [_vm._m(0)]
                          )
                        : _vm._e(),
                      _vm.gptCreatedSuccessfully
                        ? _c(
                            "div",
                            { staticClass: "pa-gpt-content__loading" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "pa-gpt-content__loading__loader",
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/images/register-funnel/check.png"),
                                      alt: "Success",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "pa-gpt-content__loading__loader__text",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("gpt.sources.chatBotCreated")
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      !_vm.showActivated &&
                      !_vm.hasAddedGPTConnectWidgets &&
                      !_vm.isGetStarted
                        ? _c("GptGetStarted", {
                            on: { "get-started": _vm.onGetStarted },
                          })
                        : _vm._e(),
                      (_vm.isGetStarted || _vm.showActivated) &&
                      !_vm.hasAddedGPTConnectWidgets &&
                      !_vm.processToAddGptConnectToWidgetStarted
                        ? _c("GptCreateChatbot")
                        : _vm._e(),
                      _vm.processToAddGptConnectToWidgetStarted
                        ? _c("AddGptToWidget", {
                            attrs: { step: _vm.goToStep },
                            on: {
                              "on-complete":
                                _vm.onCompleteAddingGptConnectToWidget,
                            },
                          })
                        : _vm._e(),
                      _vm.hasAddedGPTConnectWidgets &&
                      !_vm.processToAddGptConnectToWidgetStarted &&
                      !_vm.showGptWidgetSettings &&
                      !_vm.showGptPrompt
                        ? _c("GptWidgets", {
                            on: {
                              "edit-gpt-widget": _vm.onEditWidget,
                              "delete-widget": _vm.onDeleteWidget,
                              "edit-gpt-widget-settings":
                                _vm.onEditGptWidgetSettings,
                              "edit-gpt-prompt": _vm.onEditGptPrompt,
                            },
                          })
                        : _vm._e(),
                      _vm.showGptWidgetSettings
                        ? _c("GptWidgetSettings", {
                            on: {
                              "close-gpt-widget-settings": function ($event) {
                                _vm.showGptWidgetSettings = false
                              },
                            },
                          })
                        : _vm._e(),
                      _vm.showGptPrompt
                        ? _c("GptWidgetPrompt", {
                            on: {
                              "close-gpt-prompt": function ($event) {
                                _vm.showGptPrompt = false
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isTesting
                ? _c(
                    "div",
                    { staticClass: "pa-gpt--connect-test" },
                    [_c("TestGptWidget")],
                    1
                  )
                : _vm._e(),
              !_vm.HAS_ACCESS && !_vm.isMobileApp
                ? _c("gpt-connect-message")
                : _vm._e(),
            ],
            1
          )
        : _c("vs-progress", { attrs: { indeterminate: "", color: "primary" } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pa-gpt-content__loading__loader" }, [
      _c("span", { staticClass: "pa-gpt-content__loading__loader__spinner" }),
      _c("div", { staticClass: "pa-gpt-content__loading__loader__text" }, [
        _vm._v("Processing"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }