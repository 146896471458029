var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pag-activate",
      class: { "pag-activate--border": _vm.isMobile },
    },
    [
      !_vm.isMobile
        ? [
            _c("div", { staticClass: "pag-activate--header" }, [
              _vm._v(_vm._s(_vm.$t("gpt.automateYourProcess"))),
            ]),
            _c("div", {
              staticClass: "pag-activate--description",
              domProps: { innerHTML: _vm._s(_vm.$t("gpt.useChatbots")) },
            }),
            _c("div", { staticClass: "pag-activate--benefits" }, [
              _c("div", { staticClass: "pag-activate--benefits__box" }, [
                _c(
                  "div",
                  { staticClass: "pag-activate--benefits__box__header" },
                  [_vm._v("24 / 7")]
                ),
                _c(
                  "div",
                  { staticClass: "pag-activate--benefits__box__description" },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("register.gpt.benefits1")) +
                        "\n        "
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "pag-activate--benefits__box" }, [
                _c(
                  "div",
                  { staticClass: "pag-activate--benefits__box__header" },
                  [_vm._v("+300%")]
                ),
                _c(
                  "div",
                  { staticClass: "pag-activate--benefits__box__description" },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("register.gpt.benefits2")) +
                        "\n        "
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "pag-activate--benefits__box" }, [
                _c(
                  "div",
                  { staticClass: "pag-activate--benefits__box__header" },
                  [
                    _c("RatingSuccessIcon", {
                      attrs: { width: 30.13, height: 30.13, stroke: "#3034F7" },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "pag-activate--benefits__box__description pag-activate--benefits__box__description--icon",
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("register.gpt.benefits3")) +
                        "\n        "
                    ),
                  ]
                ),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass: "pag-activate--get-started",
                class: { "pag-activate--get-started--disabled": !_vm.IS_ADMIN },
                on: { click: _vm.onGetStarted },
              },
              [_vm._v("\n      " + _vm._s(_vm.$t("gpt.getStarted")) + "\n    ")]
            ),
            _c("div", { staticClass: "pag-activate--more-info" }, [
              _vm._v(_vm._s(_vm.$t("gpt.needMoreInfo"))),
            ]),
            _c(
              "div",
              {
                staticClass: "pag-activate--see-more",
                on: {
                  click: function ($event) {
                    return _vm.onGoToSeeMore()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("gpt.seeMore")))]
            ),
            _vm._m(0),
          ]
        : [
            _c("div", { staticClass: "pag-activate-mobile" }, [
              _c(
                "div",
                { staticClass: "pag-activate-mobile--mobile-message" },
                [
                  _c("div", [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "35",
                          height: "35",
                          viewBox: "0 0 35 35",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M17.4998 32.0846C25.554 32.0846 32.0832 25.5555 32.0832 17.5013C32.0832 9.44715 25.554 2.91797 17.4998 2.91797C9.44568 2.91797 2.9165 9.44715 2.9165 17.5013C2.9165 25.5555 9.44568 32.0846 17.4998 32.0846Z",
                            stroke: "#42B5FF",
                            "stroke-width": "2",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                          },
                        }),
                        _c("path", {
                          attrs: {
                            d: "M17.5 23.3333V17.5",
                            stroke: "#42B5FF",
                            "stroke-width": "2",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                          },
                        }),
                        _c("path", {
                          attrs: {
                            d: "M17.5 11.668H17.515",
                            stroke: "#42B5FF",
                            "stroke-width": "2",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                          },
                        }),
                      ]
                    ),
                  ]),
                  _c("div", [_vm._v(_vm._s(_vm.$t("gpt.mobileMessage")))]),
                ]
              ),
              _c("div", { staticClass: "pag-activate-mobile--header" }, [
                _vm._v(_vm._s(_vm.$t("gpt.automateYourProcess"))),
              ]),
              _c("div", {
                staticClass: "pag-activate-mobile--description",
                domProps: { innerHTML: _vm._s(_vm.$t("gpt.useChatbots")) },
              }),
              _c("div", { staticClass: "pag-activate-mobile--benefits" }, [
                _c(
                  "div",
                  { staticClass: "pag-activate-mobile--benefits__box" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "pag-activate-mobile--benefits__box__header",
                      },
                      [_vm._v("24 / 7")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "pag-activate-mobile--benefits__box__description",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("register.gpt.benefits1")) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "pag-activate-mobile--benefits__box" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "pag-activate-mobile--benefits__box__header",
                      },
                      [_vm._v("+300%")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "pag-activate-mobile--benefits__box__description",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("register.gpt.benefits2")) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "pag-activate-mobile--benefits__box" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "pag-activate-mobile--benefits__box__header",
                      },
                      [
                        _c("RatingSuccessIcon", {
                          attrs: {
                            width: 30.13,
                            height: 30.13,
                            stroke: "#3034F7",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "pag-activate-mobile--benefits__box__description pag-activate-mobile--benefits__box__description--icon",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("register.gpt.benefits3")) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "pag-activate-mobile--more-info" }, [
                _vm._v(_vm._s(_vm.$t("gpt.needMoreInfo"))),
              ]),
              _c(
                "div",
                {
                  staticClass: "pag-activate-mobile--see-more",
                  on: {
                    click: function ($event) {
                      return _vm.onGoToSeeMore()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("gpt.seeMore")))]
              ),
              _vm._m(1),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pag-activate--analyze" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/register-funnel/gpt-analyze-2.png"),
          alt: "GPT Connect bot",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pag-activate-mobile--analyze" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/register-funnel/gpt-analyze-2.png"),
          alt: "GPT analyze",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }