var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pag-analysis" }, [
    _vm._m(0),
    _c("div", { staticClass: "pag-analysis--header" }, [
      _vm._v(_vm._s(_vm.$t("gpt.chatbot.header"))),
    ]),
    _c("div", { staticClass: "pag-analysis--description" }, [
      _vm._v(_vm._s(_vm.$t("gpt.chatbot.description"))),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pag-analysis--icon" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/register-funnel/gpt-createchatbot.png"),
          width: "500",
          height: "372",
          alt: "Create AI Chatbot",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }