<template>
  <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="33" height="33" rx="6" :fill="color" />
    <path
      d="M16 25C20.4183 25 24 21.4183 24 17C24 12.5817 20.4183 9 16 9C11.5817 9 8 12.5817 8 17C8 21.4183 11.5817 25 16 25Z"
      :stroke="stroke"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M8 17H24" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M16 9C18.0011 11.1907 19.1383 14.0336 19.2 17C19.1383 19.9664 18.0011 22.8093 16 25C13.999 22.8093 12.8618 19.9664 12.8 17C12.8618 14.0336 13.999 11.1907 16 9Z"
      :stroke="stroke"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'SourceWebIcon',
  computed: {
    ...mapGetters({
      currentDataSource: 'gpt/currentDataSource'
    }),
    color() {
      if (this.currentDataSource === 'web') return '#53A5F2'
      return '#EEF8FF'
    },
    stroke() {
      if (this.currentDataSource === 'web') return 'white'
      return '#979797'
    }
  }
}
</script>
