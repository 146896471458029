var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pag-bubble-container" }, [
    _c(
      "svg",
      {
        attrs: {
          width: "209",
          viewBox: "0 0 209 70",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
          "xmlns:xlink": "http://www.w3.org/1999/xlink",
        },
      },
      [
        _c("path", {
          attrs: {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M0 10.5C0 4.70101 4.70101 0 10.5 0H198C203.799 0 208.5 4.70101 208.5 10.5V47.25C208.5 53.049 203.799 57.75 198 57.75H179.25V64.7396C179.25 66.681 176.956 67.7111 175.505 66.4213L165.75 57.75H10.5C4.70101 57.75 0 53.049 0 47.25V10.5Z",
            fill: "white",
          },
        }),
        _c(
          "foreignObject",
          { attrs: { x: "15", y: "10", width: "180", height: "50" } },
          [
            _c(
              "div",
              {
                staticClass: "svg-text",
                attrs: { xmlns: "http://www.w3.org/1999/xhtml" },
              },
              [_vm._v(_vm._s(_vm.gptSpeechBubbleTextTranslation))]
            ),
          ]
        ),
        _c("path", {
          attrs: {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M199.385 13.1127C199.57 13.2932 199.87 13.2932 200.055 13.1127C200.24 12.9322 200.24 12.6396 200.055 12.4592L198.039 10.4939L200.056 8.52797C200.241 8.3475 200.241 8.05492 200.056 7.87445C199.871 7.69399 199.571 7.69399 199.385 7.87446L197.369 9.84039L195.355 7.87719C195.17 7.69673 194.87 7.69673 194.685 7.87719C194.5 8.05766 194.5 8.35024 194.685 8.53071L196.699 10.4939L194.686 12.4564C194.5 12.6369 194.5 12.9295 194.686 13.11C194.871 13.2904 195.171 13.2904 195.356 13.11L197.369 11.1474L199.385 13.1127Z",
            fill: "#53535B",
          },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }