var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gpt-settings" }, [
    _c(
      "div",
      { staticClass: "flex items-center" },
      [
        _c("span", { staticClass: "gpt-settings--heading mr-3" }, [
          _vm._v(_vm._s(_vm.$t("gpt.widgetSettingsTitle"))),
        ]),
        _c(
          "vx-tooltip",
          {
            staticClass: "flex items-center",
            attrs: {
              position: "top",
              title: "",
              text: _vm.$t("info.dialogSettingsTitleInfo"),
            },
          },
          [
            _c("img", {
              staticStyle: { display: "inline-block", width: "15px" },
              attrs: { src: _vm.infoImg },
            }),
          ]
        ),
      ],
      1
    ),
    _c("div", { staticClass: "gpt-settings-container" }, [
      _c(
        "div",
        {
          staticClass: "gpt-settings-container--close-icon",
          on: {
            click: function ($event) {
              return _vm.$emit("close-gpt-widget-settings")
            },
          },
        },
        [
          _c("close-icon", {
            attrs: { width: 32, height: 32, color: "#53535B" },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "gpt-settings-container__item" }, [
        _c(
          "div",
          { on: { click: _vm.toggleAlwaysOn } },
          [
            _c("toggle-switch-icon", {
              attrs: { width: 42, height: 42, enabled: _vm.isAlwaysOn },
            }),
          ],
          1
        ),
        _c("div", [_vm._v(_vm._s(_vm.$t("info.alwaysOn")))]),
      ]),
      _c("div", { staticClass: "gpt-settings-container__item" }, [
        _c(
          "div",
          { on: { click: _vm.toggleIsHiddenWhenNoAgentOnline } },
          [
            _c("toggle-switch-icon", {
              attrs: {
                width: 42,
                height: 42,
                enabled: _vm.isHiddenWhenNoAgentOnline,
              },
            }),
          ],
          1
        ),
        _c("div", [
          _vm._v(_vm._s(_vm.$t("gpt.hideDialogWhileNoAgentIsCurrentlyOnline"))),
        ]),
      ]),
      _c("div", { staticClass: "gpt-settings-container__item" }, [
        _c(
          "div",
          { on: { click: _vm.toggleSetCustomTime } },
          [
            _c("toggle-switch-icon", {
              attrs: {
                width: 42,
                height: 42,
                enabled: _vm.isEnabledCustomTimeSettings,
              },
            }),
          ],
          1
        ),
        _c("div", [_vm._v(_vm._s(_vm.$t("info.setCustomTime")))]),
      ]),
      _c(
        "div",
        { staticClass: "gpt-settings-container__item" },
        [
          _vm.isEnabledCustomTimeSettings
            ? _c("DialogSettingsAvailabileTimeslots", {
                attrs: {
                  dialog: _vm.selectedGptWidget,
                  settingsKey: "gptTimeSettings",
                  isCustomDays: true,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }