var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "loading-container" }, [
    _c("span", {
      staticClass: "dot",
      style: { backgroundColor: _vm.color, opacity: 1 },
    }),
    _c("span", {
      staticClass: "dot",
      style: { backgroundColor: _vm.color, opacity: 0.8 },
    }),
    _c("span", {
      staticClass: "dot",
      style: { backgroundColor: _vm.color, opacity: 0.6 },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }