<template>
  <div class="pag-customization">
    <div class="pag-customization--top">
      <div class="pag-customization--top-left">
        <div class="pag-customization--translation-container">
          <div class="mr-4"><info-icon fill="#01AAF4" color="#fff" :width="20" :height="20" /></div>
          <div v-html="$t('info.translation')"></div>
        </div>
        <div class="language-selection">
          <div class="language-selection-text">
            {{ $t('vue.selectLanguageText') }}
          </div>
          <div>
            <v-select
              name="language-select"
              class="language-selection-select mb-4 md:mb-0 w-full"
              style="display: inline-block; max-width: 520px"
              :options="gptconnectSupportedLanguages"
              :placeholder="$t('vue.selectLanguage')"
              label="lnFullText"
              :clearable="false"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="selectedLanguage"
              v-validate="'required'"
              validate-on="blur"
            >
              <template v-slot:option="option">
                <div>
                  {{ option.lnFullText }}
                </div>
              </template>
            </v-select>
          </div>
        </div>
        <div class="pag-customization-face">
          <div class="pag-customization--label">
            {{ $t('gpt.customization.chatbotFaceLabel') }}
          </div>
          <div class="pag-customization--img-container">
            <div class="pag-customization--img-container--img">
              <img src="/img/lcbot.png" alt="" />
            </div>
            <div class="pag-customization--img-container--uploading">
              <div v-if="!croppaHasFile && selectedGptWidget && !selectedGptWidget.gptBotface" class="pag-customization--uploadcontainer" @click="onChooseFile">
                <div>{{ $t('gpt.customization.uploadYourImage') }}</div>
                <div><UploadIcon /></div>
              </div>
              <croppa
                v-show="croppaHasFile || (selectedGptWidget && selectedGptWidget.gptBotface)"
                v-model="gptBotface"
                :width="croppaWidth"
                :height="croppaHeight"
                :show-remove-button="true"
                canvas-color="transparent"
                :prevent-white-space="true"
                :image-border-radius="10"
                accept=".jpeg,.jpg,.png"
                :disable-drag-to-move="false"
                :file-size-limit="2048000"
                :placeholder-font-size="14"
                :quality="2"
                :disabled="false"
                @image-remove="onImageRemoved"
                @file-choose="onFileChosen"
                class="resizable-croppa"
                initial-size="cover"
                :initial-image="selectedGptWidget && selectedGptWidget.gptBotface"
              ></croppa>
            </div>
            <div v-if="croppaHasFile" class="pag-customization--upload" @click="onCroppaSave"><UploadIcon /></div>
          </div>
        </div>
        <div class="pag-customization-bubble">
          <div v-if="canUpdateTextFields" class="pag-customization--label">
            {{ $t('gpt.customization.speechBubbleLabel') }}
          </div>
          <div class="pag-customization--text-area">
            <div v-if="!canUpdateTextFields" class="w-full bold">{{ $t('gpt.customization.speechBubbleLabel') }} (English)</div>
            <textarea
              type="text"
              v-validate="'required'"
              name="chatbot-speechbubble"
              v-model="gptSpeechBubbleText"
              class="pag-customization-inputs--text-area"
              :placeholder="$t('gpt.choose.chatbotNameInput')"
            />
            <div v-if="!canUpdateTextFields" class="w-full bold">
              {{ $t('gpt.customization.speechBubbleLabel') }} ({{ this.selectedLanguageForGptConnect.lnFullText }})
            </div>
            <textarea
              v-if="!canUpdateTextFields"
              type="text"
              v-validate="'required'"
              name="chatbot-speechbubble"
              v-model="tGptConnectGptSpeechBubbleText"
              class="pag-customization-inputs--text-area"
              :placeholder="$t('gpt.choose.chatbotNameInput')"
            />
          </div>
        </div>

        <div class="pag-customization-botcolor">
          <div class="pag-customization-botcolor-colors">
            <div class="pag-customization--label">{{ $t('gpt.customization.botWidgetMainColor') }}</div>
            <div>
              <input class="pag-customization-botcolor-picker" type="color" v-model="botWidgetMainColor" v-validate="'required'" name="chatbot-maincolor" />
            </div>
          </div>
          <div class="pag-customization-botcolor-colors">
            <div class="pag-customization--label">{{ $t('gpt.customization.botWidgetSecondColor') }}</div>
            <div>
              <input class="pag-customization-botcolor-picker" type="color" v-model="botWidgetSecondColor" v-validate="'required'" name="chatbot-secondcolor" />
            </div>
          </div>
        </div>

        <div class="pag-customization-welcome">
          <div v-if="canUpdateTextFields" class="pag-customization--label">
            {{ $t('gpt.customization.welcomeMessageLabel') }}
          </div>
          <div class="pag-customization-inputs--editor">
            <div v-if="!canUpdateTextFields" class="w-full bold">{{ $t('gpt.customization.welcomeMessageLabel') }} (English)</div>
            <quill-editor v-model="gptBotWelcomeMessage" ref="quillEditorA" :options="editorOption" v-validate="'required'" name="chatbot-welcome">
            </quill-editor>
            <div v-if="!canUpdateTextFields" class="w-full bold">
              {{ $t('gpt.customization.welcomeMessageLabel') }} ({{ this.selectedLanguageForGptConnect.lnFullText }})
            </div>
            <quill-editor
              v-if="!canUpdateTextFields"
              v-model="tGptConnectGptBotWelcomeMessage"
              ref="quillEditorATranslation"
              :options="editorOption"
              v-validate="'required'"
              name="chatbot-welcome-translation"
            >
            </quill-editor>
          </div>
        </div>

        <div class="pag-customization-botcolor">
          <div class="pag-customization-botcolor-colors">
            <div class="pag-customization--label">{{ $t('gpt.customization.requestOptionsButtonColor') }}</div>
            <div>
              <input
                class="pag-customization-botcolor-picker"
                type="color"
                v-model="botWidgetRequestOptionsButtonColor"
                v-validate="'required'"
                name="chatbot-requestoptionsbuttoncolor"
              />
            </div>
          </div>
          <div class="pag-customization-botcolor-colors">
            <div class="pag-customization--label">{{ $t('gpt.customization.requestOptionsIconColor') }}</div>
            <div>
              <input
                class="pag-customization-botcolor-picker"
                type="color"
                v-model="botWidgetRequestOptionsIconColor"
                v-validate="'required'"
                name="chatbot-requestoptionsiconcolor"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="pag-customization--top-right">
        <BubbleChat />
        <LetsConnectChat :key="botFaceKey" />
      </div>
    </div>
  </div>
</template>
<script>
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

import vSelect from 'vue-select'

import { mapGetters, mapMutations, mapActions } from 'vuex'
import BubbleChat from '../components/BubbleChat.vue'
import LetsConnectChat from '../components/LetsConnectChat.vue'

import UploadIcon from '../icons/UploadIcon.vue'
import InfoIcon from '@/components/icons/InfoIcon.vue'

// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

export default {
  name: 'GPTCustomization',
  components: {
    vSelect,
    BubbleChat,
    LetsConnectChat,
    UploadIcon,
    quillEditor,
    InfoIcon
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      selectedGptWidgetId: 'gpt/selectedGptWidgetId',
      selectedGptWidget: 'gpt/selectedGptWidget',
      cGptSpeechBubbleText: 'gpt/gptSpeechBubbleText',
      cGptBotWelcomeMessage: 'gpt/gptBotWelcomeMessage',
      cBotWidgetMainColor: 'gpt/botWidgetMainColor',
      cBotWidgetSecondColor: 'gpt/botWidgetSecondColor',
      cBotWidgetRequestOptionsButtonColor: 'gpt/botWidgetRequestOptionsButtonColor',
      cBotWidgetRequestOptionsIconColor: 'gpt/botWidgetRequestOptionsIconColor',
      selectedLanguageForGptConnect: 'widgetTranslation/selectedLanguageForGptConnect',
      hasGptConnectWidgetTranslationUpdated: 'widgetTranslation/hasGptConnectWidgetTranslationUpdated',
      selectedGptConnectTranslation: 'widgetTranslation/selectedGptConnectTranslation',
      hasLoadedGptWidgetTranslation: 'widgetTranslation/hasLoadedGptWidgetTranslation'
    }),
    gptconnectSupportedLanguages() {
      const languages = [...this.languages]
      languages.push({ code: 'en', lnFullText: 'English', lnText: 'ENG' })
      return languages
    },
    canUpdateTextFields() {
      if (!this.selectedLanguageForGptConnect || this.selectedLanguageForGptConnect.code === 'en') return true

      return false
    }
  },
  created() {
    if (this.cGptSpeechBubbleText === '') {
      this.setGptSpeechBubbleText(this.gptSpeechBubbleText)
    } else {
      this.gptSpeechBubbleText = this.cGptSpeechBubbleText
    }

    if (this.cGptBotWelcomeMessage === '') {
      this.setGptBotWelcomeMessage(this.gptBotWelcomeMessage)
    } else {
      this.gptBotWelcomeMessage = this.cGptBotWelcomeMessage
    }

    if (!this.cBotWidgetMainColor) {
      this.setBotWidgetMainColor(this.botWidgetMainColor)
    } else {
      this.botWidgetMainColor = this.cBotWidgetMainColor
    }

    if (!this.cBotWidgetSecondColor) {
      this.setBotWidgetSecondColor(this.botWidgetSecondColor)
    } else {
      this.botWidgetSecondColor = this.cBotWidgetSecondColor
    }

    if (!this.cBotWidgetRequestOptionsButtonColor) {
      this.setBotWidgetRequestOptionsButtonColor(this.botWidgetRequestOptionsButtonColor)
    } else {
      this.botWidgetRequestOptionsButtonColor = this.cBotWidgetRequestOptionsButtonColor
    }

    if (!this.cBotWidgetRequestOptionsIconColor) {
      this.setBotWidgetRequestOptionsIconColor(this.botWidgetRequestOptionsIconColor)
    } else {
      this.botWidgetRequestOptionsIconColor = this.cBotWidgetRequestOptionsIconColor
    }
  },
  data() {
    return {
      gptSpeechBubbleText: `Hello 👋 Do you have questions about our products?
I am happy to assist you!`,
      gptBotWelcomeMessage: `Welcome, I'am {{Bot Name}} - a chatbot to help you out.
      How can I assist you today?
`,
      botWidgetMainColor: '#3034F7',
      botWidgetSecondColor: '#275D73',
      botWidgetRequestOptionsButtonColor: '#275D73',
      botWidgetRequestOptionsIconColor: '#ffffff',
      editorOption: {
        modules: {
          toolbar: {
            container: [['bold', 'italic', 'underline', { size: ['small', false, 'large', 'huge'] }]]
          }
        }
      },
      gptBotface: {},
      croppaWidth: 100,
      croppaHeight: 100,
      croppaHasFile: false,
      uploadTask: false,
      botFaceKey: Math.random().toString(36).substring(2, 15),
      selectedLanguage: { code: 'en', lnFullText: 'English', lnText: 'ENG' },
      tGptConnectGptBotWelcomeMessage: '',
      tGptConnectGptSpeechBubbleText: ''
    }
  },
  watch: {
    async 'selectedLanguage.code'(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getTranslation()
      }
    },
    hasLoadedGptWidgetTranslation(newVal) {
      if (newVal) {
        this.loadTranslation()
      }
      this.$nextTick(() => {
        this.errors.clear()
        this.$validator.reset()
      })
    },
    gptSpeechBubbleText() {
      this.setGptSpeechBubbleText(this.gptSpeechBubbleText)
    },
    gptBotWelcomeMessage() {
      this.setGptBotWelcomeMessage(this.gptBotWelcomeMessage)
    },
    botWidgetMainColor() {
      this.setBotWidgetMainColor(this.botWidgetMainColor)
    },
    botWidgetSecondColor() {
      this.setBotWidgetSecondColor(this.botWidgetSecondColor)
    },
    botWidgetRequestOptionsButtonColor() {
      this.setBotWidgetRequestOptionsButtonColor(this.botWidgetRequestOptionsButtonColor)
    },
    botWidgetRequestOptionsIconColor() {
      this.setBotWidgetRequestOptionsIconColor(this.botWidgetRequestOptionsIconColor)
    },
    tGptConnectGptSpeechBubbleText() {
      if (this.tGptConnectGptSpeechBubbleText !== this.selectedGptConnectTranslation.gptSpeechBubbleText) {
        this.setHasGptConnectWidgetTranslationUpdated(true)
        this.updateGptConnectWidgetTranslation({ gptSpeechBubbleText: this.tGptConnectGptSpeechBubbleText })
      }
    },
    tGptConnectGptBotWelcomeMessage() {
      if (this.tGptConnectGptBotWelcomeMessage !== this.selectedGptConnectTranslation.gptBotWelcomeMessage) {
        this.setHasGptConnectWidgetTranslationUpdated(true)
        this.updateGptConnectWidgetTranslation({ gptBotWelcomeMessage: this.tGptConnectGptBotWelcomeMessage })
      }
    }
  },
  methods: {
    ...mapMutations({
      setGptSpeechBubbleText: 'gpt/SET_GPT_SPEECH_BUBBLE_TEXT',
      setGptBotWelcomeMessage: 'gpt/SET_GPT_BOT_WELCOME_MESSAGE',
      setBotWidgetMainColor: 'gpt/SET_BOT_WIDGET_MAIN_COLOR',
      setBotWidgetSecondColor: 'gpt/SET_BOT_WIDGET_SECOND_COLOR',
      setSelectedGptWidget: 'gpt/SET_SELECTED_GPT_WIDGET',
      setBotWidgetRequestOptionsButtonColor: 'gpt/SET_BOT_WIDGET_REQUEST_OPTIONS_BUTTON_COLOR',
      setBotWidgetRequestOptionsIconColor: 'gpt/SET_BOT_WIDGET_REQUEST_OPTIONS_ICON_COLOR'
    }),
    ...mapActions({
      setHasGptConnectWidgetTranslationUpdated: 'widgetTranslation/setHasGptConnectWidgetTranslationUpdated',
      setGptConnectWidgetTranslation: 'widgetTranslation/setGptConnectWidgetTranslation',
      setLoadedGptConnectWidgetTranslation: 'widgetTranslation/setLoadedGptConnectWidgetTranslation',
      setDefaultGptConnectWidgetTranslation: 'widgetTranslation/setDefaultGptConnectWidgetTranslation',
      setSelectedLanguageForGptConnect: 'widgetTranslation/setSelectedLanguageForGptConnect',
      updateGptConnectWidgetTranslation: 'widgetTranslation/updateGptConnectWidgetTranslation'
    }),
    loadTranslation() {
      if (this.selectedLanguageForGptConnect && this.selectedLanguageForGptConnect.code !== 'en' && this.selectedGptConnectTranslation) {
        this.tGptConnectGptBotWelcomeMessage = this.selectedGptConnectTranslation.gptBotWelcomeMessage
        this.tGptConnectGptSpeechBubbleText = this.selectedGptConnectTranslation.gptSpeechBubbleText
      }
    },

    async getTranslation() {
      this.setSelectedLanguageForGptConnect(this.selectedLanguage)

      const languageRef = await this.$db
        .collection('translations')
        .doc(this.activeUserInfo.company)
        .collection('gpt-connect')
        .doc(this.selectedGptWidgetId)
        .collection('languages')
        .doc(this.selectedLanguage.code)
        .get()

      const translation = languageRef.data()

      if (translation && translation.gptConnect) {
        this.setGptConnectWidgetTranslation(translation.gptConnect)
        this.setLoadedGptConnectWidgetTranslation(Math.random().toString(36).substring(2, 15))
        this.setHasGptConnectWidgetTranslationUpdated(false)
      } else {
        this.setDefaultGptConnectWidgetTranslation(this.selectedLanguageForGptConnect.code)
        this.setLoadedGptConnectWidgetTranslation(Math.random().toString(36).substring(2, 15))
        this.setHasGptConnectWidgetTranslationUpdated(false)
      }
    },
    async onValidateCustomization() {
      await this.$validator.validateAll(['chatbot-speechbubble', 'chatbot-welcome', 'chatbot-maincolor', 'chatbot-secondcolor'])
    },
    onChooseFile() {
      this.gptBotface.chooseFile()
    },
    onFileChosen() {
      this.croppaHasFile = true
    },
    async onImageRemoved() {
      const vm = this
      await vm.$vs.loading()

      const storageService = this.$firebase.storage()
      try {
        await storageService.refFromURL(this.gptBotface.initialImage).delete()
        this.selectedGptWidget.gptBotface = null
        this.setSelectedGptWidget(this.selectedGptWidget)
        this.botFaceKey = Math.random().toString(36).substring(2, 15)

        const dialogRef = await vm.$db.collection('dialogs').doc(this.selectedGptWidgetId)
        await dialogRef.set({ gptBotface: this.$firebase.firestore.FieldValue.delete() }, { merge: true })
      } catch (err) {
        let message = err.message
        if (err.code === 'storage/object-not-found') {
          message = vm.$i18n.t('vue.objectDoesNotExists')
        }
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }
      await vm.$vs.loading.close()
      this.croppaHasFile = false
      this.gptBotface.remove()
    },
    onCroppaSave() {
      this.croppaHasFile = false
      this.onUpload()
    },
    onUpload() {
      const vm = this
      vm.$vs.loading()
      vm.croppaHasFile = false
      try {
        this.gptBotface.generateBlob(
          async (blob) => {
            const storageService = this.$firebase.storage()
            const storageRef = storageService.ref()
            const file = blob

            const metadata = {
              contentType: 'image/png',
              cacheControl: 'max-age=604800, public'
            }

            let storagePath = ''

            storagePath = `images/gpt-widgets/${this.selectedGptWidgetId}`

            let storageFileRef = null
            storageFileRef = storageRef.child(storagePath).put(file, metadata)
            this.uploadTask = true

            storageFileRef.on(
              this.$firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
              (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                this.progressUpload = progress
              },
              function (error) {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                  case 'storage/unauthorized':
                    // User doesn't have permission to access the object
                    vm.text = 'fields.storage/unauthorized'
                    break
                  case 'storage/canceled':
                    // User canceled the upload
                    vm.text = 'fields.storage/canceled'
                    break
                  case 'storage/unknown':
                    // Unknown error occurred, inspect error.serverResponse
                    vm.text = 'fields.storage/unknown'
                    break
                }

                vm.$vs.notify({
                  time: 8800,
                  title: vm.$i18n.t('vue.error'),
                  text: error.message,
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'danger'
                })

                vm.uploadTask = false
              },
              async () => {
                // Upload completed successfully, now we can get the download URL
                this.uploadTask = false
                const downloadURL = await storageFileRef.snapshot.ref.getDownloadURL()

                const dialogRef = await this.$db.collection('dialogs').doc(this.selectedGptWidgetId)
                dialogRef.set({ gptBotface: downloadURL }, { merge: true })

                this.selectedGptWidget.gptBotface = downloadURL
                this.setSelectedGptWidget(this.selectedGptWidget)
                this.botFaceKey = Math.random().toString(36).substring(2, 15)

                this.$vs.loading.close()
                this.$vs.notify({
                  time: 8800,
                  title: vm.$i18n.t('vue.success'),
                  text: vm.$i18n.t('vue.photoSavedSuccessfully'),
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'success'
                })
              }
            )
          },
          'image/jpg',
          0.8
        ) // 80% compressed jpeg file
      } catch (err) {
        this.$vs.notify({
          time: 8800,
          title: vm.$i18n.t('vue.error'),
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
        // eslint-disable-next-line
        console.log(err.message)
      }
    }
  }
}
</script>
<style lang="scss">
.language-selection-select {
  .vs__dropdown-toggle {
    background-color: rgba(var(--vs-primary), 1) !important;
    cursor: pointer;
  }
  .vs__selected,
  .vs__search,
  .vs__actions {
    color: #fff !important;
  }
  font-size: 16px;
  margin-bottom: 5px;
}
.ql-blank {
  /* Your styles here */
  border: 2px solid red; /* to indicate an error */
  background-color: #ffe5e5; /* a light red background */
}
</style>
<style lang="scss" scoped>
.bold {
  font-weight: bold;
}
.pag-customization {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-width: 500px;

  &--translation-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f4fbff;
    border-radius: 6px;
    padding: 5px 15px;
    margin: 10px 0px;
  }

  textarea[aria-invalid='true'] {
    /* Your styles here */
    border: 2px solid red; /* for example, to indicate an error */
    background-color: #ffe5e5; /* a light red background */
  }

  &--uploadcontainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 100px;
    border-radius: 6px;
    border: 1px dashed #c4c4c4;
    background: #f5f5f5;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    gap: 14.71px;
    cursor: pointer;
  }

  &--upload {
    width: 35px;
    height: 35px;
    background-color: #275D73;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    cursor: pointer;
  }

  &-botcolor {
    display: flex;
    margin-top: 10px;
    gap: 10px;

    &-picker {
      width: 300px;
      min-width: 300px;
      max-width: 100%;
      height: 40px;
      cursor: pointer;
    }

    &-colors {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }

  &-bubble {
    display: flex;
    gap: 10px;
    flex-direction: column;
  }
  &-welcome {
    display: flex;
    gap: 10px;
    flex-direction: column;
    margin-top: 15px;
  }

  &-inputs {
    &--text-area {
      border-radius: 8px;
      border: 1px solid #d9dbdd;
      background: #fff;
      width: 480px;
      height: 70px;
      flex-shrink: 0;
      padding: 10px;

      color: #262629;
      font-family: Larsseit;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: -0.111px;
    }

    &--editor {
      margin-top: 0px;

      .quill-editor {
        width: 480px;

        .ql-toolbar {
          max-height: 42px;
        }

        .ql-container {
          max-height: 108px;
          .ql-editor {
            font-family: 'Lato';
          }
        }
      }
    }
  }

  &--label {
    color: #575757;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  &--img-container {
    display: flex;
    margin-top: 10px;
    gap: 20px;

    &--img {
      width: 100px;
      height: 100px;
      flex-shrink: 0;
      border-radius: 6px;
      border: 1px solid #f5f5f5;
      background: #fff;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
  }

  &--top {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;

    @media (max-width: 1500px) {
      flex-direction: column;
      gap: 30px;
    }

    &-left {
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      min-width: 512px;
    }

    &-right {
      display: flex;
      flex-direction: row;
      min-width: 512px;
    }
  }
}
</style>
