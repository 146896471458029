<template>
  <div class="pag-analysis">
    <div class="pag-analysis--icon">
      <img src="@/assets/images/register-funnel/gpt-createchatbot.png" width="500" height="372" alt="Create AI Chatbot" />
    </div>
    <div class="pag-analysis--header">{{ $t('gpt.chatbot.header') }}</div>
    <div class="pag-analysis--description">{{ $t('gpt.chatbot.description') }}</div>
  </div>
</template>
<script>
export default {
  name: 'GptCreateChatbot'
}
</script>
<style lang="scss" scoped>
.pag-analysis {
  position: relative;
  margin-top: 30px;
  width: 100%;
  height: 100%;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  &--icon {
    margin-right: 50px;
  }

  &--header {
    text-align: center;
    margin-top: 40px;
    text-align: center;
    font-family: 'Larsseit-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    letter-spacing: -0.18px;
    color: #262629;
  }

  &--description {
    text-align: center;
    max-width: 483px;
    margin-top: 10px;
    text-align: center;
    font-family: 'Larsseit-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.0833333px;
    color: #53535b;
  }
}
</style>
