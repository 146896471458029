var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "19",
        height: "19",
        viewBox: "0 0 19 19",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("circle", {
        attrs: { cx: "9.5", cy: "9.5", r: "9.5", fill: "#F1A342" },
      }),
      _c("path", {
        attrs: {
          d: "M9 12.332H12.75",
          stroke: "white",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M10.875 5.4581C11.0408 5.29234 11.2656 5.19922 11.5 5.19922C11.6161 5.19922 11.731 5.22208 11.8382 5.2665C11.9455 5.31092 12.0429 5.37603 12.125 5.4581C12.2071 5.54018 12.2722 5.63762 12.3166 5.74485C12.361 5.85209 12.3839 5.96703 12.3839 6.0831C12.3839 6.19918 12.361 6.31411 12.3166 6.42135C12.2722 6.52859 12.2071 6.62603 12.125 6.7081L6.91667 11.9164L5.25 12.3331L5.66667 10.6664L10.875 5.4581Z",
          stroke: "white",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }