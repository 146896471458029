<template>
  <div class="pag-web">
    <div class="pag-web--header">{{ $t('gpt.sources.crawl') }}</div>
    <div class="pag-web-crawl">
      <div class="pag-web-crawl-actions">
        <input class="pag-web-crawl-actions--input" type="text" v-model="crawlLink" name="chatbot-secondcolor" placeholder="https://www.example.com" />
        <div class="pag-web--btn" :class="{ 'pag-web--invalid-url': !isCrawlLinkValid || isFetchingUrls }" @click="onFetchLinks('url')">
          {{ $t('gpt.sources.fetchLinks') }}
        </div>
      </div>
      <div class="pag-web-crawl-info">
        <info-icon color="#000" :width="11" :height="11" />
        <div class="pag-web--infotext">{{ $t('gpt.sources.crawlInfo') }}</div>
      </div>
      <div class="pag-web-progress-bar-container" v-if="progress && progress < 100">
        <div class="pag-web-progress-bar" :style="{ width: `${progress}%` }"></div>
        <div class="pag-web-progress-label">
          <span>{{ progress }}% </span>
          <span class="pag-web-progress-label--small">[Total Links: {{ totalLinksCount }}, Remaining Links: {{ remainingLinksCount }} ]</span>
        </div>
      </div>
    </div>

    <div class="pag-web-or">
      <div class="pag-web-or--line"></div>
      <div class="pag-web-or--text">OR</div>
      <div class="pag-web-or--line"></div>
    </div>

    <div class="pag-web--header">{{ $t('gpt.sources.submitSitemap') }}</div>

    <div class="pag-web-crawl">
      <div class="pag-web-crawl-actions">
        <input
          class="pag-web-crawl-actions--input"
          type="text"
          v-model="sitemapLink"
          name="chatbot-secondcolor"
          placeholder="https://www.example.com/sitemap.xml,https://www.example.com/sitemap2.xml"
        />
        <div class="pag-web--btn" :class="{ 'pag-web--invalid-url': !isSitemapLinkValid || isFetchingUrls }" @click="onFetchLinks('sitemap')">
          {{ $t('gpt.sources.loadSitemap') }}
        </div>
      </div>
    </div>
    <div class="pag-web-includedlinks" v-if="gptLinks.length > 0">
      <div class="pag-web--header">{{ $t('gpt.sources.includedLinks') }}</div>
      <LoadingIndicator class="pag-files-loader" v-if="isFetchingUrls" />
      <div class="pag-web--delete" @click="onDeleteAllLinks()">
        <DeleteIcon :width="25" :height="25" />
      </div>
    </div>
    <div ref="chatScrollArea" class="pag-web-scroll-area" style="max-height: 300px; --toolbar-background: #275D73; position: relative; padding-left: 20px">
      <div class="pag-web-linkscontainer" :key="linksKey">
        <div v-for="(link, index) in gptLinks" :key="index" class="pag-web-linkslist">
          <input class="pag-web-crawl-actions--input" type="text" :value="link" placeholder="https://www.example.com" @input="onUpdateLink($event, index)" />
          <div class="pag-web-crawl-actions--delete" @click="onDeleteLink(index)">
            <DeleteIcon />
          </div>
        </div>
      </div>
    </div>
    <div class="pag-web--addlink" @click="onAddLink">Add</div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { gen2function } from '@/mixins/gen2function'
import InfoIcon from '@/components/icons/InfoIcon.vue'
import DeleteIcon from '../icons/DeleteIcon.vue'
import LoadingIndicator from '../components/LoadingIndicator.vue'
export default {
  name: 'DataSourcesWeb',
  mixins: [gen2function],
  components: {
    InfoIcon,
    DeleteIcon,
    LoadingIndicator
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      selectedGptWidgetId: 'gpt/selectedGptWidgetId',
      gptLinks: 'gpt/gptLinks'
    }),
    isCrawlLinkValid() {
      try {
        new URL(this.crawlLink)
        return true
      } catch (_) {
        return false
      }
    },
    isSitemapLinkValid() {
      // Check if the sitemapLink is a valid URL first
      try {
        new URL(this.sitemapLink)

        // Additional heuristic check for sitemap-specific criteria
        return this.sitemapLink.includes('sitemap') && (this.sitemapLink.endsWith('.xml') || this.sitemapLink.endsWith('.txt'))
      } catch (_) {
        return false
      }
    },
    canProcessUrls() {
      return this.gptLinks.length > 0
    }
  },
  data() {
    return {
      crawlLink: '',
      sitemapLink: '',
      isFetchingUrls: false,
      totalLinksCount: 0,
      remainingLinksCount: 0,
      linksKey: Math.random().toString(36).substring(2, 15),
      progress: 0
    }
  },
  created() {
    this.getUrlsFromStorage()
    this.fetchPercentageFetchedLinks()
  },
  watch: {
    canProcessUrls() {
      this.$emit('can-process-urls', this.gptLinks)
    },
    isFetchingUrls() {
      this.$emit('is-fetching-urls', this.isFetchingUrls)
    }
  },
  methods: {
    ...mapMutations({
      setGptLinks: 'gpt/SET_GPT_LINKS'
    }),
    scrollToElement($el, value) {
      if (!$el) {
        return
      }
      $el.scroll({ top: value || $el.scrollHeight, behavior: 'smooth' })
    },
    scrollToBottom() {
      const vm = this
      setTimeout(() => {
        const scrollElement = this.$refs.chatScrollArea
        vm.scrollToElement(scrollElement)
      }, 100)
    },
    onAddLink() {
      this.gptLinks.push('')
      this.linksKey = Math.random().toString(36).substring(2, 15)
      this.scrollToBottom()
    },
    fetchPercentageFetchedLinks() {
      try {
        const vm = this
        vm.$database.ref(`/fetch-link-status/${vm.selectedGptWidgetId}`).on('value', async (snapshot) => {
          if (snapshot && snapshot.exists()) {
            const data = snapshot.val()
            this.progress = Number(data.percentage_completed) || 0
            this.totalLinksCount = data.total_links || 0
            this.remainingLinksCount = data.remaining_links || 0
            if (data && data.percentage_completed > 0 && data.percentage_completed < 100) {
              this.isFetchingUrls = true
            }
            if (data && data.links) {
              this.setGptLinks([...new Set([...this.gptLinks, ...data.links])])
            }
            if (data && data.percentage_completed === 100) {
              this.isFetchingUrls = false
            }
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error.message)
      }
    },
    getUrlsFromStorage() {
      try {
        const vm = this
        vm.isFetchingUrls = true
        const storage = this.$firebase.storage()
        const storageRef = storage.ref(`openai/${this.activeUserInfo.company}/${this.selectedGptWidgetId}/urls/urls.json`)

        storageRef
          .getDownloadURL()
          .then((url) => {
            // Fetch the content of the file using the URL
            return fetch(url)
          })
          .then((response) => {
            // Parse the response into a JSON object
            return response.json()
          })
          .then((data) => {
            // 'data' is now the content of your urls.json file parsed into an array
            if (data && data.length > 0) {
              this.setGptLinks([...new Set([...this.gptLinks, ...data])])
            }

            this.scrollToBottom()
          })
          .catch((error) => {
            if (error.code !== 'storage/object-not-found') {
              // eslint-disable-next-line
              console.log('Error downloading or parsing file:', error)
            }
          })
          .finally(() => {
            vm.isFetchingUrls = false
          })
      } catch (error) {
        this.isFetchingUrls = false // Make sure to set this to false in case of error
        // eslint-disable-next-line
        console.error('Unexpected error:', error.message)
      }
    },
    onDeleteLink(index) {
      this.gptLinks.splice(index, 1)
      this.setGptLinks(this.gptLinks)
    },
    async onDeleteAllLinks() {
      const storage = this.$firebase.storage()
      const storageUrlsRef = storage.ref(`openai/${this.activeUserInfo.company}/${this.selectedGptWidgetId}/urls/urls.json`)

      storageUrlsRef
        .delete()
        .then(() => {
          /* eslint-disable no-console */
          console.log('urls successfully removed!')
        })
        .catch((error) => {
          // Handle any errors that occur during the delete
          if (error.code === 'storage/object-not-found') {
            /* eslint-disable no-console */
            console.log('File doesnt exist, nothing to delete.')
          } else {
            /* eslint-disable no-console */
            console.error('Error deleting the file:', error.message)
          }
        })

      const storageVectorRef = storage.ref(`openai/${this.activeUserInfo.company}/${this.selectedGptWidgetId}/vector/faiss_store_url_openai.pkl`)
      storageVectorRef
        .delete()
        .then(() => {
          console.log('vector url db successfully removed!')
        })
        .catch((error) => {
          // Handle any errors that occur during the delete
          if (error.code === 'storage/object-not-found') {
            /* eslint-disable no-console */
            console.log('File doesnt exist, nothing to delete.')
          } else {
            /* eslint-disable no-console */
            console.error('Error deleting the file:', error.message)
          }
        })

      this.setGptLinks([])
      await this.$database
        .ref(`/fetch-link-status/${this.selectedGptWidgetId}`)
        .update({ links: [], percentage_completed: 0, remaining_links: 0, total_links: 0 })

      this.isFetchingUrls = false
    },
    onUpdateLink(event, index) {
      // Using Vue's $set method to ensure reactivity
      this.$set(this.gptLinks, index, event.target.value)
    },
    async onFetchLinks(urlType) {
      if (this.isFetchingUrls) return

      try {
        if (urlType === 'url') {
          if (!this.isCrawlLinkValid) return
          await this.$database
            .ref(`/fetch-link-status/${this.selectedGptWidgetId}`)
            .update({ links: [], percentage_completed: 0, remaining_links: 0, total_links: 0 })
          this.isFetchingUrls = true
          const idTokenResult = await this.$firebase.auth().currentUser.getIdTokenResult()
          let gcpFunctionToken = ''
          if (idTokenResult && idTokenResult.token) {
            gcpFunctionToken = idTokenResult.token
          }
          const config = {
            companyId: this.activeUserInfo.company,
            url: this.crawlLink,
            type: urlType,
            widgetId: this.selectedGptWidgetId
          }
          const result = await this.callGen2Function('gpt-fetch-links', config, gcpFunctionToken, this.$gen2EndPoint)
          if (result && result.links) {
            this.setGptLinks([...new Set([...this.gptLinks, ...result.links])])
            this.scrollToBottom()
          }
        }

        if (urlType === 'sitemap') {
          if (!this.isSitemapLinkValid) return
          this.isFetchingUrls = true
          await this.$database
            .ref(`/fetch-link-status/${this.selectedGptWidgetId}`)
            .update({ links: [], percentage_completed: 0, remaining_links: 0, total_links: 0 })
          const idTokenResult = await this.$firebase.auth().currentUser.getIdTokenResult()
          let gcpFunctionToken = ''
          if (idTokenResult && idTokenResult.token) {
            gcpFunctionToken = idTokenResult.token
          }
          const config = {
            companyId: this.activeUserInfo.company,
            url: this.sitemapLink,
            type: urlType
          }
          const result = await this.callGen2Function('gpt-fetch-links', config, gcpFunctionToken, this.$gen2EndPoint)
          if (result && result.links) {
            this.setGptLinks([...new Set([...this.gptLinks, ...result.links])])
            this.scrollToBottom()
          }
        }
      } catch (error) {
        // eslint-disable-next-line
        console.log(error.message)
        this.isFetchingUrls = false
      }
      this.isFetchingUrls = false
    }
  }
}
</script>
<style lang="scss" scoped>
.pag-web {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  &--delete {
    margin-left: auto;
    cursor: pointer;
  }

  &-progress-bar-container {
    position: relative;
    width: 100%;
    background-color: #e0e0e0;
    border-radius: 25px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    height: 14px;
    overflow: hidden;
  }

  &-progress-bar {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #53a5f2;
    border-radius: 25px;
    transition: width 0.25s ease;
  }

  &-progress-label {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(-50%);
    pointer-events: none;

    color: #000;
    font-weight: bold;
    font-size: 10px;

    &--small {
      margin-left: 5px;
      color: #fff;
      font-weight: normal;
      font-size: 10px;
    }
  }

  &-scroll-area {
    overflow-y: auto;
    scrollbar-width: thin;
    padding-bottom: 15px;
    max-height: 300px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(230, 236, 241, 0.5);
    }

    &::-webkit-scrollbar-thumb:vertical {
      background-color: var(--toolbar-background);
      border-radius: 6px;
    }
  }

  &--addlink {
    border-radius: 8px;
    background: #275D73;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    min-width: 91px;
    color: #fff;
    font-family: Larsseit;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 17.668px; /* 110.427% */
    letter-spacing: -0.111px;
    cursor: pointer;
  }

  &-linkscontainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 10px;
  }

  &-linkslist {
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;

    &--input {
      border-radius: 8px;
      border: 1px solid #d9dbdd;
      background: #fff;
      height: 40px;
      padding: 12px 20px;
      width: 100%;
      /* Styles for the text */
      color: #575757;
      font-family: 'Larsseit', sans-serif; /* fallback to sans-serif if Larsseit isn't loaded */
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: -0.111px;
      text-decoration-line: none;
    }
  }

  &-includedlinks {
    display: flex;
    gap: 10px;
    width: 100%;
  }

  &--invalid-url {
    background: #bcbcbc !important;
  }

  &-or {
    display: flex;
    align-items: center;
    width: 100%;

    &--line {
      flex-grow: 1;
      height: 1px;
      background-color: #d9dbdd;
    }

    &--text {
      color: #262629;
      font-family: Larsseit;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 114.286% */
      letter-spacing: -0.111px;
      padding: 0 9px;
    }
  }

  &--infotext {
    color: #575757;
    font-family: 'Larsseit-Regular', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 17.668px; /* 147.237% */
    letter-spacing: -0.111px;
  }

  &--header {
    color: #262629;
    font-family: Larsseit;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 17.668px; /* 110.427% */
    letter-spacing: -0.111px;
  }

  &--btn {
    border-radius: 8px;
    background: #275D73;
    padding: 10px;
    color: #fff;
    font-family: Larsseit;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 17.668px; /* 110.427% */
    letter-spacing: -0.111px;
    height: 40px;
    min-width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  &-crawl {
    display: flex;
    flex-direction: column;
    gap: 14px;
    width: 100%;

    &-actions {
      display: flex;
      width: 100%;
      gap: 20px;

      &--delete {
        margin-right: 20px;
        cursor: pointer;
      }

      &--input {
        border-radius: 8px;
        border: 1px solid #d9dbdd;
        background: #fff;
        height: 40px;
        padding: 12px 20px;
        width: 100%;
        /* Styles for the text */
        color: #575757;
        font-family: 'Larsseit-Regular', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: -0.111px;
        text-decoration-line: none;
      }

      &--input::-webkit-input-placeholder {
        color: #d9dbdd;
        text-decoration-line: underline;
      }
      &--input::-moz-placeholder {
        color: #d9dbdd;
        text-decoration-line: underline;
      }
      &--input::-ms-input-placeholder {
        color: #d9dbdd;
        text-decoration-line: underline;
      }
    }

    &-info {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
  }
}
</style>
