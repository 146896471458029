<template>
  <div class="gpt-test">
    <div v-if="isLoading" class="gpt-test__loading">
      <div class="gpt-test__loading__loader">
        <span class="gpt-test__loading__loader__spinner" />
        <div class="gpt-test__loading__loader__text">{{ $t('buttonAndHints.loading') }}</div>
      </div>
    </div>
    <div class="gpt-test--header">{{ $t('gpt.test.header') }}</div>
    <div class="gpt-test--description">{{ $t('gpt.test.description') }}</div>
    <div class="gpt-test--lc-chat">
      <LetsConnectChat mode="test" />
      <div class="gpt-test--lc-chat--testcount" :class="{ 'gpt-test--lc-chat--testcount--limit': hasTestLimitReached }">{{ totalTestCount }}/10</div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'

import LetsConnectChat from './components/LetsConnectChatBig.vue'
export default {
  name: 'TestGptConnect',
  components: {
    LetsConnectChat
  },
  data() {
    return {
      isLoading: true,
      unsubscribeGptWidget: null
    }
  },
  computed: {
    ...mapGetters({
      gptSelectedGptWidgetId: 'gpt/selectedGptWidgetId',
      gptSelectedGptWidget: 'gpt/selectedGptWidget',
      totalTestCount: 'gpt/totalTestCount'
    }),
    hasTestLimitReached() {
      return this.totalTestCount >= 10
    }
  },

  async created() {
    this.subscribeGptWidget()
  },
  beforeDestroy() {
    if (this.unsubscribeGptWidget) {
      this.unsubscribeGptWidget()
    }
  },

  methods: {
    ...mapMutations({
      setSelectedGptWidget: 'gpt/SET_SELECTED_GPT_WIDGET',
      setSelectedGptChatbotName: 'gpt/SET_SELECTED_GPT_CHATBOT_NAME',
      setConsultation: 'gpt/SET_CONSULTATION',
      setQualifiedPercent: 'gpt/SET_QUALIFIED_PERCENT',
      setGptSpeechBubbleText: 'gpt/SET_GPT_SPEECH_BUBBLE_TEXT',
      setGptBotWelcomeMessage: 'gpt/SET_GPT_BOT_WELCOME_MESSAGE',
      setBotWidgetMainColor: 'gpt/SET_BOT_WIDGET_MAIN_COLOR',
      setBotWidgetSecondColor: 'gpt/SET_BOT_WIDGET_SECOND_COLOR',
      setBotWidgetRequestOptionsButtonColor: 'gpt/SET_BOT_WIDGET_REQUEST_OPTIONS_BUTTON_COLOR',
      setBotWidgetRequestOptionsIconColor: 'gpt/SET_BOT_WIDGET_REQUEST_OPTIONS_ICON_COLOR',
      setTotalTestCount: 'gpt/SET_TOTAL_TEST_COUNT'
    }),
    subscribeGptWidget() {
      const widgetRef = this.$db.collection('dialogs').doc(this.gptSelectedGptWidgetId)

      this.unsubscribeGptWidget = widgetRef.onSnapshot((doc) => {
        const widget = doc.data()
        this.setSelectedGptWidget(widget)
        this.setSelectedGptChatbotName(widget.gpt.botName)
        this.setConsultation(widget.gpt.consultation)
        this.setQualifiedPercent(widget.gpt.qualifiedPercent)
        this.setGptSpeechBubbleText(widget.gpt.speechBubbleText)
        this.setGptBotWelcomeMessage(widget.gpt.botWelcomeMessage)
        this.setBotWidgetMainColor(widget.gpt.botWidgetMainColor)
        this.setBotWidgetSecondColor(widget.gpt.botWidgetSecondColor)
        this.setBotWidgetRequestOptionsButtonColor(widget.gpt.botWidgetRequestOptionsButtonColor)
        this.setBotWidgetRequestOptionsIconColor(widget.gpt.botWidgetRequestOptionsIconColor)
        this.setTotalTestCount(widget.gptMessageCount)
        this.isLoading = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.gpt-test {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;

  margin-top: 24px;
  background: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 21px 21px;

  justify-content: center;
  align-items: center;

  gap: 30px;

  &--lc-chat {
    display: flex;
    position: relative;

    &--testcount {
      position: absolute;
      right: -200px;
      height: 40px;
      min-width: 159px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-radius: 30px;
      background: rgba(44, 216, 92, 0.1);

      color: #2cd85c;
      text-align: center;
      font-family: 'Larsseit-Regular';
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &--limit {
        background: rgba(255, 0, 78, 0.1) !important;
        color: #ff004e;
      }
    }

    @media (max-width: 800px) {
      flex-direction: column;
      &--testcount {
        position: relative;
        right: auto;
        margin-top: 20px;
      }
    }
  }

  &--header {
    color: #262629;
    text-align: center;
    font-family: 'Larsseit-Bold';
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 125% */
    letter-spacing: -0.178px;
  }
  &--description {
    color: #53535b;
    text-align: center;
    font-family: 'Larsseit-Regular';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; /* 140% */
    letter-spacing: -0.083px;
    max-width: 750px;
  }

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.95);
    z-index: 99;

    &__loader {
      text-align: center;

      &__spinner {
        display: inline-block;
        width: 70px;
        height: 70px;
        border: 10px solid rgb(222, 241, 254);
        border-bottom-color: rgb(66, 181, 255);
        border-radius: 50%;
        animation: rotation 1s linear infinite;
      }

      &__title {
        margin-top: 28px;
        color: rgb(38, 38, 41);
        font-family: 'Larsseit-Bold';
        font-size: 28px;
        line-height: 26px;
        text-align: center;
        letter-spacing: -0.155556px;
      }

      &__text {
        margin-top: 28px;
        color: #262629;
        text-align: center;
        font-family: 'Larsseit-Bold';
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px; /* 92.857% */
        letter-spacing: -0.156px;
      }
    }
  }
}
</style>
