<template>
  <div class="pag-files">
    <div class="pag-files--header1">
      {{ $t('gpt.sources.uploadFiles') }}
    </div>
    <div>
      <vue-dropzone id="dataSourceFiles" ref="vueDropzone" :options="dropzoneOptions" :useCustomSlot="true" @vdropzone-files-added="afterFilesAdded">
        <div class="pag-files-filescontainer">
          <upload-icon stroke="#000000" />
          <div class="pag-files--header2 pag-files-mt-15">{{ $t('gpt.sources.dragDropMessage') }}</div>
          <div class="pag-files--text">{{ $t('gpt.sources.supportFileTypes') }}</div>
        </div>
      </vue-dropzone>
    </div>
    <div class="pag-files-attached">
      <div class="pag-files--header1">
        {{ $t('gpt.sources.attachedFiles') }}
      </div>
      <LoadingIndicator class="pag-files-loader" v-if="isProcessing" />
    </div>
    <div class="pag-files-attached-files">
      <scroll-view style="max-height: 250px" :key="fileKey">
        <div v-for="(file, index) in uploads" :key="index" class="pag-files-list">
          <!-- Container for filename, file size, and upload percentage -->
          <div class="pag-files-list--file-details">
            <!-- Display the filename -->
            <span class="pag-files-list--filename">{{ file.name }}</span>

            <!-- Display the file size -->
            <span class="pag-files-list--filesize">{{ formatSize(file.size) }}</span>

            <!-- Display the upload percentage -->
            <span class="pag-files-list--upload-percent">{{ file.uploadPercent || 0 }}%</span>
          </div>

          <!-- Delete button -->
          <div @click="deleteFile(index)" class="pag-files--delete-btn">
            <DeleteIcon />
          </div>
        </div>
      </scroll-view>
    </div>
  </div>
</template>
<script>
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

import ScrollView from '@blackbp/vue-smooth-scrollbar'
import DeleteIcon from '@/components/icons/DeleteIcon.vue'

import { mapGetters, mapMutations } from 'vuex'

import vue2Dropzone from 'vue2-dropzone'
import UploadIcon from '../icons/UploadIcon.vue'
import LoadingIndicator from '../components/LoadingIndicator.vue'

const ACCEPTED_FILES = ['pdf', 'doc', 'docx', 'txt', 'csv', 'xlsx']

export default {
  name: 'DataSourcesFiles',
  components: {
    vueDropzone: vue2Dropzone,
    UploadIcon,
    LoadingIndicator,
    ScrollView,
    DeleteIcon
  },
  data() {
    return {
      isProcessing: false,
      croppaFiles: null,
      files: null,
      uploads: [],
      dropzoneOptions: {
        url: '#',
        autoProcessQueue: false,
        thumbnailHeight: 32,
        addRemoveLinks: false,
        previewsContainer: false,
        acceptedFiles: ACCEPTED_FILES.map((ext) => `.${ext}`).join(',')
      },
      fileKey: Math.random().toString(36).substring(2, 15)
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      selectedGptWidgetId: 'gpt/selectedGptWidgetId',
      gptIsFilesUploading: 'gpt/gptIsFilesUploading'
    }),
    canUploadFiles() {
      return this.uploads.filter((x) => !x.isUploaded).length > 0
    },
    canProcessUploadedFiles() {
      let uploads = this.uploads
      if (typeof this.uploads === 'object') {
        uploads = Array.from(uploads)
      }
      const uploadedFilesCount = uploads.filter((x) => x.isUploaded).length
      return uploadedFilesCount > 0
    }
  },
  created() {
    this.getFilesFromStorage()
  },
  methods: {
    ...mapMutations({
      setGptFilesUploading: 'gpt/SET_GPT_FILES_UPLOADING',
      setHasFilesUpdated: 'gpt/SET_HAS_FILES_UPDATED'
    }),
    getFilesFromStorage() {
      const vm = this
      vm.isProcessing = true
      const storage = this.$firebase.storage()
      const storageRef = storage.ref(`openai/${this.activeUserInfo.company}/${this.selectedGptWidgetId}/files`)

      storageRef
        .listAll()
        .then((result) => {
          // result.items is an array of references (one for each file)
          // You can get each file's metadata or URL if needed

          const filePromises = result.items.map((ref) => {
            return ref.getMetadata().then((metadata) => {
              // Here we're assuming the metadata contains the data you want for each file.
              // You can adjust this to your needs.
              metadata.isUploaded = true
              metadata.uploadPercent = 100

              return metadata
            })
          })

          return Promise.all(filePromises)
        })
        .then((files) => {
          // Now 'files' contains all the metadata for the files in the directory.
          // Add them to the uploads array (or process as needed)

          this.uploads = this.uploads.concat(files)
          vm.isProcessing = false
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error('Error fetching files:', error)
          vm.isProcessing = false
        })
    },
    formatSize(size) {
      if (size < 1024) {
        return `${size} Bytes`
      } else if (size >= 1024 && size < 1048576) {
        return `${(size / 1024).toFixed(2)} KB`
      } else if (size >= 1048576 && size < 1073741824) {
        return `${(size / 1048576).toFixed(2)} MB`
      } else {
        return `${(size / 1073741824).toFixed(2)} GB`
      }
    },
    deleteFile(index) {
      this.setHasFilesUpdated(true)
      const vm = this
      const file = this.uploads[index]
      if (file.uploadPercent === 100) {
        const storage = this.$firebase.storage()
        const storageRef = storage.ref(`openai/${this.activeUserInfo.company}/${this.selectedGptWidgetId}/files/${file.name}`)

        storageRef
          .delete()
          .then(() => {
            // eslint-disable-next-line
            console.log(`File ${file.name} successfully deleted`)
            vm.uploads = [...vm.uploads.slice(0, index), ...vm.uploads.slice(index + 1)]
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(`Error deleting file ${file.name}:`, error)
          })
      } else {
        vm.uploads = [...vm.uploads.slice(0, index), ...vm.uploads.slice(index + 1)]
      }
    },

    onUploadFilesToCloud(canProcessURLs) {
      const vm = this
      if (vm.gptIsFilesUploading) return

      try {
        vm.setGptFilesUploading(true)

        const storage = this.$firebase.storage()
        const uploadPromises = [] // Array to store all upload promises

        this.uploads.forEach((file, index) => {
          // If the file is already uploaded, skip the upload logic for this iteration.
          if (file.isUploaded) {
            return
          }

          const storageRef = storage.ref(`openai/${this.activeUserInfo.company}/${this.selectedGptWidgetId}/files/${file.name}`)

          const uploadPromise = new Promise((resolve, reject) => {
            const uploadTask = storageRef.put(file)

            uploadTask.on(
              'state_changed',
              (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100

                const file = this.uploads[index]
                file.uploadPercent = progress.toFixed(0) // Update the upload percent for this file.
                file.isUploaded = progress === 100
                this.$set(this.uploads, index, file)

                this.fileKey = Math.random().toString(36).substring(2, 15)
              },
              (error) => {
                // eslint-disable-next-line
                console.error(`Upload failed for ${file.name}`, error)
                reject(error) // Reject the promise on upload error
              },
              () => {
                // eslint-disable-next-line
                console.log('Uploaded', uploadTask.snapshot.totalBytes, 'bytes for', file.name)
                resolve() // Resolve the promise when upload is successful
              }
            )
          })

          uploadPromises.push(uploadPromise) // Add the promise to the array
        })

        // Use Promise.all to wait for all uploads to complete
        Promise.all(uploadPromises)
          .then(() => {
            // eslint-disable-next-line
            console.log('All files have been uploaded!')
            vm.setGptFilesUploading(false)
            vm.$emit('start-processing-uploaded-files', canProcessURLs)
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error('Some files failed to upload', error)
            vm.setGptFilesUploading(false)
          })
      } catch (error) {
        // eslint-disable-next-line
        vm.setGptFilesUploading(false)
      }
    },
    afterFilesAdded(fileList) {
      this.setHasFilesUpdated(true)
      // Convert the FileList object to an array
      const uploads = Array.from(fileList)

      // Filter out the accepted files
      const acceptedFiles = uploads.filter((file) => file.accepted || ACCEPTED_FILES.some((extension) => file.type.includes(extension)))

      // Remove unaccepted files from the vue-dropzone instance
      uploads.forEach((file) => {
        if (!file.accepted) {
          this.$refs.vueDropzone.removeFile(file)
        }
      })

      // Filter out the existing uploads with the same name as any of the new accepted files
      const uniqueOldFiles = this.uploads.filter((oldFile) => !acceptedFiles.some((newFile) => newFile.name === oldFile.name))

      // Combine the unique old files with the new accepted files
      this.uploads = [...uniqueOldFiles, ...acceptedFiles]
    }
  },
  watch: {
    canProcessUploadedFiles() {
      this.$emit('can-process-files', this.canProcessUploadedFiles)
    },
    uploads() {
      this.$emit('set-uploads', this.uploads)
    }
  }
}
</script>
<style lang="scss" scoped>
.scroll-area {
  width: 100%;
  height: calc(100%);
}

.pag-files {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  max-width: 1033px;

  &--delete-btn {
    margin-right: 20px;
    cursor: pointer;
  }

  &-list {
    display: flex;
    justify-content: space-between; /* Pushes .file-details and .delete-btn apart */
    align-items: center; /* Vertically centers items */
    gap: 10px;

    &--file-details {
      display: flex;
      gap: 10px; /* Space between filename, filesize, and upload percent */
    }
  }

  &-attached-files {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &-loader {
    margin-left: 11px;
  }

  &-mt-15 {
    margin-top: 15px;
  }

  &-attached {
    display: flex;
  }

  &-filescontainer {
    max-width: 836px;
    width: 100%;
    height: 287px;
    flex-shrink: 0;
    border-radius: 6px;
    border: 1px dashed #d9dbdd;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex-direction: column;
    gap: 5px;
  }

  &--header1 {
    color: #263238;
    font-family: 'Larsseit-Bold';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 17.668px; /* 110.427% */
    letter-spacing: -0.111px;
  }

  &--header2 {
    color: #575757;
    text-align: center;
    font-family: 'Larsseit-Bold';
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 17.668px; /* 147.237% */
    letter-spacing: -0.111px;
  }

  &--text {
    color: #575757;
    text-align: center;
    font-family: 'Larsseit-Regular';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 17.668px; /* 147.237% */
    letter-spacing: -0.111px;
  }
}
</style>
