<template>
  <div class="pag-bubble-chat">
    <div class="pag-bubble-chat--block">
      <TextBubbleIcon />
      <div class="pag-bubble-chat--robot">
        <RoboChatIcon />
      </div>
    </div>
  </div>
</template>
<script>
import RoboChatIcon from '../icons/RoboChatIcon.vue'
import TextBubbleIcon from '../icons/TextBubbleIcon.vue'
export default {
  name: 'BubbleChat',
  components: {
    RoboChatIcon,
    TextBubbleIcon
  }
}
</script>
<style lang="scss" scoped>
.pag-bubble-chat {
  display: flex;
  height: 78px;
  margin-right: 42.5px;
  &--block {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
  }
  &--close {
    position: absolute;
  }
  &--robot {
    position: absolute;
    right: -12px;
    bottom: -53px;
  }
  &--text {
    width: 180px;
    color: #53535b;
    font-family: Larsseit;
    font-size: 10.5px;
    font-style: normal;
    font-weight: 400;
    line-height: 13.5px; /* 128.571% */
    letter-spacing: 0.033px;
    position: absolute;
    margin-bottom: 12.24px;
  }
}
</style>
