<template>
  <div class="pag-lc-chat" :style="letsConnectChat">
    <div class="pag-lc-chat-infocontainer" :style="`background: ${selectedGptWidget.step2ButtonColor || '#3034f7'};`">
      <div class="pag-lc-chat-infocontainer-heading">
        <img class="pag-lc-chat-infocontainer--icon" :src="botFace" alt="Bot Face" />
        <div class="pag-lc-chat-infocontainer-details">
          <div class="pag-lc-chat-infocontainer-details--company">{{ companyName }}</div>
          <div class="pag-lc-chat-infocontainer-details--botname">{{ selectedGptChatbotName }}</div>
        </div>
      </div>
      <div class="pag-lc-chat-caction">
        <div class="pag-lc-chat-caction-img-container">
          <img class="pag-lc-chat-caction-img" :src="defaultImageURL" />
          <div class="pag-lc-chat-caction-img-online"></div>
        </div>

        <div class="pag-lc-chat-caction--child pag-lc-chat-caction-live-support" :class="{ 'pag-lc-chat-caction--disabled': isTestMode }">
          {{ $t('gpt.customization.contactLiveSupport') }}
        </div>

        <div class="pag-lc-chat-caction-buttons-wrapper">
          <div
            v-if="showChat"
            class="pag-lc-chat-caction--child pag-lc-chat-caction-button pag-lc-chat-caction-chat"
            :class="{ 'pag-lc-chat-caction--disabled': isTestMode }"
          >
            <ChatIcon :width="12" :height="12.17" :color="isTestMode ? '#B2B2B2' : null" />
            {{ $t('vue.chat') }}
          </div>
          <div
            v-if="showAudio"
            class="pag-lc-chat-caction--child pag-lc-chat-caction-button pag-lc-chat-caction-audio"
            :class="{ 'pag-lc-chat-caction--disabled': isTestMode }"
          >
            <PhoneCallIcon :width="11.76" :height="11.6" :color="isTestMode ? '#B2B2B2' : null" />
            {{ $t('vue.audio') }}
          </div>
          <div
            v-if="showVideo"
            class="pag-lc-chat-caction--child pag-lc-chat-caction-button pag-lc-chat-caction-video"
            :class="{ 'pag-lc-chat-caction--disabled': isTestMode }"
          >
            <VideoCallIcon :width="13.72" :height="9.28" :color="isTestMode ? '#B2B2B2' : null" />
            {{ $t('vue.video') }}
          </div>
        </div>
      </div>
      <div class="pag-lc-close">
        <CloseIcon :width="20" :height="20" />
      </div>
    </div>
    <div class="pag-lc-chat-chatcontainer" style="position: relative">
      <div ref="chatScrollArea" class="pag-lc-chat-scroll-area" style="--toolbar-background: #3034f7; position: relative; padding-left: 20px">
        <!-- Loop through each message in defaultChatMessages -->
        <div style="padding: 10px 0px">
          <div v-for="(chat, index) in gptTestChats" :key="index">
            <div
              :class="{
                'pag-lc-chat-chatcontainer--visitor pag-lc-chat--message-visitor': chat.sender === 'visitor',
                'pag-lc-chat-chatcontainer--bot pag-lc-chat--message': chat.sender === 'bot'
              }"
            >
              <img v-if="chat.sender === 'bot'" class="pag-lc-chat--botface" :src="botFace" alt="Bot Face" />
              <div v-html="sanitizeHtml(chat.message)"></div>
            </div>
          </div>
        </div>
      </div>
      <loading-indicator v-if="isFetchingAnswer" class="pag-lc-chat--loader" color="#3034F7" />
    </div>
    <div class="pag-lc-chat-tool-container" :style="`background: ${selectedGptWidget.step2ButtonColor || '#3034f7'};`"></div>
    <div class="pag-lc-chat-message-container">
      <input
        type="text"
        placeholder="Write a message ..."
        class="pag-lc-chat-message-container--message-input"
        v-model="question"
        :disabled="mode === 'preview' || totalTestCount >= 10"
      />
      <div @click="onSendQuestion">
        <SendIcon
          class="pag-lc-chat-message-container--send-button"
          :width="20"
          :height="20"
          :color="isFetchingAnswer || totalTestCount >= 10 ? '#b2b2b2' : '#3034F7'"
        />
      </div>
    </div>
    <div class="pag-lc-chat-poweredby-container">
      <div class="pag-lc-chat-poweredby-container-content">
        <powered-by />
        <powered-by-text />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ChatIcon from '@/components/icons/ChatCallIcon.vue'
import PhoneCallIcon from '@/components/icons/PhoneCallIcon.vue'
import VideoCallIcon from '@/components/icons/VideoCallIcon.vue'
import CloseIcon from '@/components/icons/CloseIcon'
import SendIcon from '@/components/icons/SendIcon.vue'
import PoweredBy from '../icons/PoweredByIconBig.vue'
import PoweredByText from '../icons/PoweredByTextBig.vue'
import LoadingIndicator from './LoadingIndicator.vue'
import { gen2function } from '@/mixins/gen2function'
import Autolinker from 'autolinker'
import Marked from 'marked'

import sanitizeHtml from 'sanitize-html'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
dayjs.extend(utc)

const renderer = new Marked.Renderer()

renderer.link = function () {
  const link = Marked.Renderer.prototype.link.apply(this, arguments)
  return link.replace('<a', '<a target="_blank"')
}

Marked.setOptions({ renderer })

export default {
  name: 'GptLCChatBig',
  components: {
    ChatIcon,
    PhoneCallIcon,
    VideoCallIcon,
    CloseIcon,
    SendIcon,
    PoweredBy,
    PoweredByText,
    LoadingIndicator
  },
  mixins: [gen2function],
  props: {
    mode: {
      type: String,
      default: 'preview'
    },
    mainWidth: {
      type: Number,
      default: 280
    },
    mainHeight: {
      type: Number,
      default: 520
    },
    infoHeight: {
      type: Number,
      default: 96
    },
    actionHeight: {
      type: Number,
      default: 40
    },
    liveSupportWidth: {
      type: Number,
      default: 114
    }
  },

  data() {
    return {
      question: '',
      isFetchingAnswer: false,
      unsubscribeGptChats: null,
      chats: [],
      defaultImageURL: require('@/assets/images/dialog/contact-form-background.jpeg')
    }
  },

  created() {
    if (this.mode === 'test') {
      this.subscribeGptChats()
    }
  },

  async mounted() {
    this.scrollToBottom()
  },

  beforeDestroy() {
    if (this.unsubscribeGptChats) {
      this.unsubscribeGptChats()
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      company: 'company',
      selectedGptWidget: 'gpt/selectedGptWidget',
      selectedGptWidgetId: 'gpt/selectedGptWidgetId',
      gptSpeechBubbleText: 'gpt/gptSpeechBubbleText',
      gptBotWelcomeMessage: 'gpt/gptBotWelcomeMessage',
      botWidgetMainColor: 'gpt/botWidgetMainColor',
      botWidgetSecondColor: 'gpt/botWidgetSecondColor',
      selectedGptChatbotName: 'gpt/selectedGptChatbotName',
      totalTestCount: 'gpt/totalTestCount',
      selectedGptConnectTranslation: 'widgetTranslation/selectedGptConnectTranslation',
      selectedLanguageForGptConnect: 'widgetTranslation/selectedLanguageForGptConnect'
    }),

    /* Sytles */
    letsConnectChat() {
      return {
        '--pag-lc-chat-width': `${this.mainWidth}px`,
        '--pag-lc-chat-height': `${this.mainHeight}px`,
        '--pag-lc-chat-infocontainer-height': `${this.infoHeight}px`,
        '--pag-lc-chat-action-height': `${this.actionHeight}px`,
        '--pag-lc-chat-livesupport-width': `${this.liveSupportWidth}px`
      }
    },
    isTestMode() {
      return this.mode === 'test'
    },
    displayName() {
      if (this.activeUserInfo && this.activeUserInfo.displayName) {
        return this.activeUserInfo.displayName
      }
      if (this.activeUserInfo && this.activeUserInfo.firstname) {
        let displayName = this.activeUserInfo.firstname
        if (this.activeUserInfo.lastname) {
          displayName = `${displayName} ${this.activeUserInfo.lastname}`
        }

        return displayName
      }

      return null
    },
    companyName() {
      const widgetCompanyName = this.selectedGptWidget && this.selectedGptWidget.companyDisplayName ? this.selectedGptWidget.companyDisplayName : null

      const companyName = this.company && this.company.name ? this.company.name : ''

      return widgetCompanyName || companyName
    },
    botFace() {
      return this.selectedGptWidget && this.selectedGptWidget.gptBotface ? this.selectedGptWidget.gptBotface : '/img/lcbot.png'
    },
    showChat() {
      return this.selectedGptWidget && this.selectedGptWidget.communicationOptions && this.selectedGptWidget.communicationOptions.chat
    },
    showAudio() {
      return this.selectedGptWidget && this.selectedGptWidget.communicationOptions && this.selectedGptWidget.communicationOptions.audio
    },
    showVideo() {
      return this.selectedGptWidget && this.selectedGptWidget.communicationOptions && this.selectedGptWidget.communicationOptions.video
    },
    botWelcomeMessage() {
      if (this.selectedGptConnectTranslation && this.selectedLanguageForGptConnect && this.selectedLanguageForGptConnect.code !== 'en') {
        const message = this.selectedGptConnectTranslation.gptBotWelcomeMessage || ''
        return message.replace('{{Bot Name}}', this.selectedGptChatbotName)
      }
      return this.gptBotWelcomeMessage.replace('{{Bot Name}}', this.selectedGptChatbotName)
    },
    defaultChatMessages() {
      return [{ message: this.botWelcomeMessage, time: new Date(), chatTime: new Date().getTime(), sender: 'bot' }]
    },
    gptTestChats() {
      // Merge the two arrays
      const combinedChats = [...this.defaultChatMessages, ...this.chats]

      // Sort based on the time property
      const sortedCombinedChats = combinedChats.sort((a, b) => {
        const getTimeInMillis = (time) => {
          if (typeof time === 'string') {
            // Convert ISO string to date and get time in milliseconds
            return new Date(time).getTime()
          } else if (typeof time === 'object' && 'seconds' in time && 'nanoseconds' in time) {
            // Convert Firestore timestamp to milliseconds, using parentheses for clarity.
            const first = time.seconds * 1000
            const second = time.nanoseconds / 1e6
            return first + second
          }
          return 0
        }

        return getTimeInMillis(a.time) - getTimeInMillis(b.time)
      })

      const modifiedCombinedChats = sortedCombinedChats.map((item) => {
        if (item.sender === 'bot') {
          item.message = this.replaceFirstWordThatContainsSpecialCharacter(item.message)
        }

        return {
          ...item,
          message: item.sender === 'bot' ? Marked(item.message) : Autolinker.link(item.message)
        }
      })

      return modifiedCombinedChats
    }
  },
  methods: {
    sanitizeHtml,
    subscribeGptChats() {
      const vm = this
      const gptChatsRef = this.$db.collection('gpt-test-chats').doc(vm.selectedGptWidgetId)
      vm.unsubscribeGptChats = gptChatsRef.onSnapshot((doc) => {
        const widget = doc.data()
        if (widget && widget.chats) {
          vm.chats = widget.chats
          vm.scrollToBottom()
        }
      })
    },
    scrollToElement($el, value) {
      if (!$el) {
        return
      }
      $el.scrollTo({ top: value || $el.scrollHeight, behavior: 'smooth' })
    },
    scrollToBottom() {
      const vm = this
      setTimeout(() => {
        const scrollElement = this.$refs.chatScrollArea
        vm.scrollToElement(scrollElement)
      }, 100)
    },

    async onSendQuestion() {
      if (this.mode === 'preview') {
        return
      }
      if (!this.question) {
        return
      }
      if (this.isFetchingAnswer || !this.question) {
        return
      }
      try {
        this.isFetchingAnswer = true
        const query = this.question
        this.question = ''

        const message = {
          dialogId: this.selectedGptWidgetId,
          sender: 'visitor',
          message: query,
          time: new Date(),
          chatTime: dayjs().utc().diff('2021-01-01')
        }

        const gptChatsRef = await this.$db.collection('gpt-test-chats').doc(this.selectedGptWidgetId)
        await gptChatsRef.set(
          {
            chats: this.$firebase.firestore.FieldValue.arrayUnion(message),
            companyId: this.activeUserInfo.company,
            dialogId: this.selectedGptWidgetId
          },
          { merge: true }
        )

        const idTokenResult = await this.$firebase.auth().currentUser.getIdTokenResult()

        let gcpFunctionToken = ''
        if (idTokenResult && idTokenResult.token) {
          gcpFunctionToken = idTokenResult.token
        }
        const config = {
          question: query,
          companyId: this.activeUserInfo.company,
          dialogId: this.selectedGptWidgetId,
          visitorId: 'TEST_GPT_CONNECT'
        }
        this.scrollToBottom()
        await this.callGen2Function('gpt-fetch-answer', config, gcpFunctionToken, this.$gen2EndPoint)
        this.scrollToBottom()
        this.isFetchingAnswer = false
      } catch (error) {
        // eslint-disable-next-line
        console.log(error.message)
        this.isFetchingAnswer = false
      }
    },

    replaceFirstWordThatContainsSpecialCharacter(str) {
      if (!str) {
        return ''
      }

      if (this.selectedGptChatbotName && str.includes(`${this.selectedGptChatbotName}: `)) {
        return str.replace(`${this.selectedGptChatbotName}: `, '')
      }

      const words = str.split(' ')

      if (words.length > 0 && words[0].includes(':') && ['http', 'https', 'file'].every((item) => !words[0].includes(`${item}:`))) {
        words.shift()
      }

      return words.join(' ')
    }
  }
}
</script>

<style lang="scss">
.pag-lc-chat-chatcontainer--bot {
  p {
    display: inline-block;
  }

  ul,
  ol {
    li {
      margin-left: 14px;
    }
  }
}
</style>

<style lang="scss" scoped>
.pag-lc-close {
  position: absolute;
  right: 14px;
  top: 16px;
  cursor: pointer;
}

.pag-lc-chat {
  display: flex;
  flex-direction: column;
  width: var(--pag-lc-chat-width);
  height: var(--pag-lc-chat-height);
  flex-shrink: 0;
  border-radius: 9.75px;
  background: transparent;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.25);
  position: relative;

  &-caction {
    display: flex;
    align-items: center;
    gap: 3.97px;
    margin: 5px -11px 0;
    padding: 0 10px;
    width: calc(100% + 22px);
    height: 30px;
    background: #eeeeee;
    z-index: 1;

    &--disabled {
      background: #d9dbdd !important;
      color: #b2b2b2 !important;
    }

    &--child {
      height: var(--pag-lc-chat-action-height);
      border-radius: 6.095px;
      color: #b2b2b2;
      font-family: Larsseit;
      font-size: 7.324px;
      font-style: normal;
      font-weight: 700;
      line-height: 9px; /* 169.231% */
      letter-spacing: -0.041px;
    }

    &-button {
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }

    &-img {
      width: 30px;
      height: 30px;
      object-fit: cover;
      border-radius: 6px;

      &-container {
        position: relative;
        width: 30px;
        height: 30px;
      }

      &-online {
        position: absolute;
        top: -3px;
        right: -3px;
        width: 10px;
        height: 10px;
        background: #2bff6f;
        border-radius: 50%;
      }
    }

    &-live-support {
      width: 85.5px;
      padding: 7.5px 12px 7.5px 6px;
      height: auto;
      background: transparent !important;
      color: #101d2e;
      font-family: 'Larsseit-Bold';
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: 15px; /* 141.923% */
      letter-spacing: -0.06px;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-buttons-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      gap: 3.97px;
      flex: 1;
      margin-top: -2px;
      z-index: 1;
    }

    /* Other child divs will take the remaining space equally */
    &-chat,
    &-audio,
    &-video {
      flex: 1; /* this will make them share the remaining space equally */
      min-width: var(--pag-lc-chat-action-height);
      background: #275D73;
      gap: 0.07px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      cursor: pointer;
    }
  }

  &-live-support {
    width: var(--pag-lc-chat-livesupport-width);
    background: #edf6fc;

    display: flex;
    justify-content: center;
    align-items: center;

    &--icon {
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  &-scroll-area {
    overflow-y: auto;
    scrollbar-width: thin;
    padding-bottom: 15px;
    max-height: 314px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(230, 236, 241, 0.5);
    }

    &::-webkit-scrollbar-thumb:vertical {
      background-color: var(--toolbar-background);
      border-radius: 6px;
    }
  }

  &--loader {
    align-self: flex-start;
    justify-self: flex-start;
    margin-left: 5px;
    margin-bottom: 5px;
  }

  &--botface {
    width: 30px;
    height: 30px;
    position: absolute;
    top: -22px;
    left: -8px;
    z-index: 1000;
    pointer-events: none;
    border-radius: 6px;
    object-fit: cover;
  }

  &--message {
    color: #53535b;
    font-family: 'Larsseit-Regular';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    letter-spacing: 0.044px;
    position: relative;
    word-break: break-word;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  &--message-visitor {
    color: #262629;
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    letter-spacing: 0.044px;
    position: relative;
  }

  &-infocontainer {
    position: relative;
    padding: 11.25px 11px 0 11px;
    width: 100%;
    height: var(--pag-lc-chat-infocontainer-height);
    flex-shrink: 0;
    background: #3034f7;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    z-index: 1;

    &-heading {
      display: flex;
      gap: 14.75px;
    }

    &--icon {
      width: 50px;
      height: 50px;
      object-fit: cover;
      background: #fff;
      border-radius: 6px;
      box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.25);
    }

    &-details {
      display: flex;
      flex-direction: column;
      gap: 5px;
      &--company {
        color: #fff;
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }
      &--botname {
        color: #fff;
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        width: calc(100% - 1px);
        height: 39px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &-chatcontainer {
    width: var(--pag-lc-chat-width);
    height: 327.69px;
    flex-shrink: 0;
    background: #fff;
    position: relative;
    z-index: 0;
    padding-top: 10px;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    overflow: visible;

    &--bot {
      width: 235px;
      background: #eff1f5;
      padding: 12px 10px;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      margin-top: 24px;
      margin-right: 15px;
    }

    &--visitor {
      width: 214px;
      background: #edf6fc;
      padding: 7.5px;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      border-bottom-left-radius: 6px;
      margin-top: 10px;
      margin-left: 20px;
    }
  }

  &-tool-container {
    width: var(--pag-lc-chat-width);
    height: 27px;
    flex-shrink: 0;
    background: #3034f7;
  }

  &-message-container {
    width: var(--pag-lc-chat-width);
    height: 50px;
    flex-shrink: 0;
    background: #fff;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;

    &--message-input {
      padding-right: 25px;
      width: 100%; /* This will fill the entire width of the container */
      height: 100%; /* This will fill the entire height of the container */
      border: none; /* Removing the default border */
      outline: none; /* Removing the default focus outline */
      background: transparent; /* Making the background transparent */
      padding: 0; /* Removing any default padding */
      box-sizing: border-box; /* To ensure padding and border are included in width/height */
      vertical-align: middle; /* Aligning text vertically in the middle */
      position: relative; /* To ensure it's below the button */

      color: #b7b7c1;
      font-family: Larsseit;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &::placeholder {
        color: #b7b7c1;
      }
    }

    &--send-button {
      position: absolute; /* Positioning it above the input */
      right: 15px; /* 15px space from the right */
      top: 50%; /* Positioning it 50% from the top to center vertically */
      transform: translateY(-50%); /* This shifts it up by half its height to perfectly center it */
      background-color: some-color; /* Use your desired color here */
      border: none; /* Removing the default border */
      cursor: pointer; /* Making it look clickable */
    }
  }

  &-poweredby-container {
    width: var(--pag-lc-chat-width);
    height: 19.41px;
    flex-shrink: 0;
    background: rgba(230, 236, 241, 0.5);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    &-content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding-left: 10px;
      gap: 5.57px;
    }
  }
}
</style>
