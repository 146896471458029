var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "33",
        height: "33",
        viewBox: "0 0 33 33",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("rect", {
        attrs: { width: "33", height: "33", rx: "6", fill: _vm.color },
      }),
      _c("path", {
        attrs: {
          d: "M16 25C20.4183 25 24 21.4183 24 17C24 12.5817 20.4183 9 16 9C11.5817 9 8 12.5817 8 17C8 21.4183 11.5817 25 16 25Z",
          stroke: _vm.stroke,
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M8 17H24",
          stroke: _vm.stroke,
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M16 9C18.0011 11.1907 19.1383 14.0336 19.2 17C19.1383 19.9664 18.0011 22.8093 16 25C13.999 22.8093 12.8618 19.9664 12.8 17C12.8618 14.0336 13.999 11.1907 16 9Z",
          stroke: _vm.stroke,
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }