<template>
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="6.5" cy="6.5" r="6.5" fill="#1C8439" />
    <path d="M9.30522 4.78906L5.73022 8.68906L4.10522 6.91634" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>
<script>
export default {
  name: 'CheckIcon'
}
</script>
