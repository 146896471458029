<template>
  <div>
    <div v-if="activeUserInfo && company" class="pa-gpt" :class="{ 'pa-gpt--bannermargintop': canShowTrialBanner && !isMobileApp }">
      <div v-if="!isMobile" class="pa-gpt-action">
        <div v-if="showActivated" class="pa-gpt-action--connected pa-gpt-action--btn">
          <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="9.5" cy="9.5" r="9.5" fill="#2BFF6F" />
            <path d="M13.6 7L8.375 12.7L6 10.1091" stroke="white" stroke-width="1.9" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <div>
            {{ $t('gpt.activated') }}
          </div>
        </div>

        <div v-if="!showActivated && !isGetStarted && !hasAddedGPTConnectWidgets" class="pa-gpt-action--add pa-gpt-action--btn" @click="onGetStarted">
          <div>
            {{ $t('gpt.getStarted') }}
          </div>
        </div>

        <div v-if="showActivateGPT && !isTesting && (isGetStarted || hasAddedGPTConnectWidgets)" class="pa-gpt-action--test pa-gpt-action--btn">
          <EditIcon />
          <div>{{ $t('gpt.testMode') }}</div>
        </div>

        <div
          v-if="!isTesting && !processToAddGptConnectToWidgetStarted && (showActivated || isGetStarted || hasAddedGPTConnectWidgets)"
          class="pa-gpt-action--add pa-gpt-action--btn"
          :class="{ 'pa-gpt-action--btn--disabled': !IS_ADMIN }"
          @click="onStartProcessToAddGptConnectToWidget"
        >
          <div>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 2.5V9.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M2.5 6H9.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
          <div>{{ $t('gpt.newChatbot') }}</div>
        </div>

        <div
          v-if="showActivateGPT && !isTesting && (isGetStarted || hasAddedGPTConnectWidgets) && !processToAddGptConnectToWidgetStarted"
          class="pa-gpt-action--test pa-gpt-action--btn pa-gpt-action--test--grow"
        >
          <InfoIcon />
          <div class="pa-gpt-action--test--smallertext">{{ $t('gpt.subscribeMessage') }}</div>
          <div class="pa-gpt-action--test--subscribe" @click="onActivateGpt">{{ $t('pricing.subscribeNow') }}</div>
        </div>
      </div>
      <div v-if="!isTesting" class="pa-gpt-content">
        <div v-if="gptIsProcessing" class="pa-gpt-content__loading">
          <div class="pa-gpt-content__loading__loader">
            <span class="pa-gpt-content__loading__loader__spinner" />
            <div class="pa-gpt-content__loading__loader__text">Processing</div>
          </div>
        </div>

        <div v-if="gptCreatedSuccessfully" class="pa-gpt-content__loading">
          <div class="pa-gpt-content__loading__loader">
            <img src="@/assets/images/register-funnel/check.png" alt="Success" />
            <div class="pa-gpt-content__loading__loader__text">{{ $t('gpt.sources.chatBotCreated') }}</div>
          </div>
        </div>
        <GptGetStarted v-if="!showActivated && !hasAddedGPTConnectWidgets && !isGetStarted" @get-started="onGetStarted" />
        <GptCreateChatbot v-if="(isGetStarted || showActivated) && !hasAddedGPTConnectWidgets && !processToAddGptConnectToWidgetStarted" />
        <AddGptToWidget v-if="processToAddGptConnectToWidgetStarted" :step="goToStep" @on-complete="onCompleteAddingGptConnectToWidget" />
        <GptWidgets
          v-if="hasAddedGPTConnectWidgets && !processToAddGptConnectToWidgetStarted && !showGptWidgetSettings && !showGptPrompt"
          @edit-gpt-widget="onEditWidget"
          @delete-widget="onDeleteWidget"
          @edit-gpt-widget-settings="onEditGptWidgetSettings"
          @edit-gpt-prompt="onEditGptPrompt"
        />
        <GptWidgetSettings v-if="showGptWidgetSettings" @close-gpt-widget-settings="showGptWidgetSettings = false" />
        <GptWidgetPrompt v-if="showGptPrompt" @close-gpt-prompt="showGptPrompt = false" />
      </div>

      <div v-if="isTesting" class="pa-gpt--connect-test">
        <TestGptWidget />
      </div>

      <gpt-connect-message v-if="!HAS_ACCESS && !isMobileApp"></gpt-connect-message>
    </div>
    <vs-progress v-else indeterminate color="primary"></vs-progress>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { mobile_check } from '@/mixins/mobile_check'
import GptGetStarted from './GptGetStarted.vue'
import GptCreateChatbot from './GptCreateChatbot.vue'
import AddGptToWidget from './AddGptToWidget.vue'
import GptWidgets from './GptWidgets.vue'
import TestGptWidget from './TestGptWidget.vue'
import GptWidgetSettings from './GptWidgetSettings.vue'
import GptWidgetPrompt from './GptWidgetPrompt.vue'

import { Device } from '@capacitor/device'
import GptConnectMessage from '@/components/upgrade-messages/GptConnectMessage.vue'

import EditIcon from './icons/EditIcon.vue'
import InfoIcon from './icons/InfoIcon.vue'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const duration = require('dayjs/plugin/duration')
dayjs.extend(utc)
dayjs.extend(duration)

export default {
  name: 'GptConnect',
  components: {
    GptGetStarted,
    GptCreateChatbot,
    AddGptToWidget,
    GptWidgets,
    TestGptWidget,
    EditIcon,
    InfoIcon,
    GptWidgetSettings,
    GptConnectMessage,
    GptWidgetPrompt
  },
  mixins: [mobile_check],
  data() {
    return {
      isGetStarted: false,
      processToAddGptConnectToWidgetStarted: false,
      isMobileApp: false,
      goToStep: 0,
      showGptWidgetSettings: false,
      showGptPrompt: false
    }
  },

  computed: {
    ...mapGetters({
      company: 'company',
      activeUserInfo: 'activeUser',
      dialogs: 'dialogs',
      gptIsProcessing: 'gpt/gptIsProcessing',
      gptCreatedSuccessfully: 'gpt/gptCreatedSuccessfully'
    }),

    HAS_ACCESS() {
      if (!this.company) {
        return false
      }
      let claims = this.company.claims || []
      const custom_claims = this.company.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return claims.includes('gpt-connect') && this.company.expiryDate && dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds))
    },

    IS_ADMIN() {
      if (!this.activeUserInfo) return false

      return this.activeUserInfo.role === 'admin'
    },

    canShowTrialBanner() {
      if (this.company && this.company.paymentStatus) {
        switch (this.company.paymentStatus) {
          case 'trial':
          case 'expired':
            return true

          case 'on-trial':
            return this.company && !this.company.currentInvoiceId && !this.company.mollieSubscriptionId
          default:
            return false
        }
      }
      return false
    },

    isMobile() {
      return this.mobileCheck()
    },

    showActivated() {
      return this.gptIsActivated
    },

    hasGPTPaymentFailed() {
      if (this.company && this.company.gptPaymentFailed) return true
      return false
    },

    showActivateGPT() {
      if (this.hasGPTPaymentFailed) return false
      if (this.gptIsActivated) return false
      return true
    },

    gptIsActivated() {
      if (!this.company || !this.company.gptIsActivated) return false

      if (this.company.gptIsActivated) {
        return true
      }

      return false
    },

    hasAddedGPTConnectWidgets() {
      if (!this.dialogs || this.dialogs.length === 0) return false

      return this.dialogs.filter((x) => x.isGptWidget).length > 0
    },
    isTesting() {
      return this.$route.params.id
    }
  },

  beforeMount() {
    this.setSelectedGptWidgetId(this.$route.params.id)
  },

  methods: {
    ...mapMutations({
      setSelectedGptWidgetId: 'gpt/SET_SELECTED_GPT_WIDGET_ID',
      setConfigOptionsToDefault: 'gpt/SET_CONFIG_OPTIONS_TO_DEFAULT'
    }),
    onActivateGpt() {
      if (!this.IS_ADMIN) return
      this.$router.push({ name: 'payment', params: { action: 'activate-gpt-get-started' } })
    },
    onEditWidget() {
      if (!this.IS_ADMIN) return
      this.processToAddGptConnectToWidgetStarted = true
      this.goToStep = 2
    },
    onEditGptWidgetSettings() {
      if (!this.IS_ADMIN) return
      this.showGptWidgetSettings = true
    },
    onEditGptPrompt() {
      if (!this.IS_ADMIN) return
      this.showGptPrompt = true
    },
    onDeleteWidget() {
      if (!this.IS_ADMIN) return
      this.processToAddGptConnectToWidgetStarted = false
      this.goToStep = 0
    },
    async checkIsMobileApp() {
      try {
        const { platform } = await Device.getInfo()

        if (['ios', 'android'].includes(platform)) {
          this.isMobileApp = true
        }
      } catch (error) {
        /* eslint-disable-next-line */
        console.log(error)
      }
    },
    onStartProcessToAddGptConnectToWidget() {
      if (!this.IS_ADMIN) return
      this.setConfigOptionsToDefault()
      this.isGetStarted = true
      this.processToAddGptConnectToWidgetStarted = true
      this.goToStep = 0
    },
    onCompleteAddingGptConnectToWidget() {
      if (!this.IS_ADMIN) return
      this.processToAddGptConnectToWidgetStarted = false
      this.goToStep = 0
    },
    onGetStarted() {
      if (!this.IS_ADMIN) return
      this.isGetStarted = true
    }
  }
}
</script>
<style lang="scss">
@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 #275D73;
  }
  70% {
    -webkit-box-shadow: 0 0 0 15px rgba(40, 167, 249, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(40, 167, 249, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 #275D73;
    box-shadow: 0 0 0 0 rgba(40, 167, 249, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 15px rgba(40, 167, 249, 0);
    box-shadow: 0 0 0 15px rgba(40, 167, 249, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(40, 167, 249, 0);
    box-shadow: 0 0 0 0 rgba(40, 167, 249, 0);
  }
}
</style>
<style lang="scss" scoped>
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.pa-gpt {
  height: 100%;
  position: relative;

  &--connect-test {
    width: 100%;
    height: 100%;
  }

  @media (min-width: 1200px) {
    width: calc(100vw - 325px) !important;
  }

  &--bannermargintop {
    margin-top: 120px;

    @media (max-width: 767px) {
      margin-top: 0px;
    }
  }

  &-content {
    height: calc(100% - 65px);
    padding: 0;
    margin: 0;
    box-sizing: border-box;

    &__loading {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.95);
      z-index: 99;

      &__loader {
        text-align: center;

        &__spinner {
          display: inline-block;
          width: 70px;
          height: 70px;
          border: 10px solid rgb(222, 241, 254);
          border-bottom-color: rgb(66, 181, 255);
          border-radius: 50%;
          animation: rotation 1s linear infinite;
        }

        &__title {
          margin-top: 28px;
          color: rgb(38, 38, 41);
          font-family: 'Larsseit-Bold';
          font-size: 28px;
          line-height: 26px;
          text-align: center;
          letter-spacing: -0.155556px;
        }

        &__text {
          margin-top: 28px;
          color: #262629;
          text-align: center;
          font-family: 'Larsseit-Bold';
          font-size: 28px;
          font-style: normal;
          font-weight: 700;
          line-height: 26px; /* 92.857% */
          letter-spacing: -0.156px;
        }
      }
    }
  }

  &-action {
    display: flex;

    &--btn {
      margin-right: 20px;

      &--disabled {
        background: #979797 !important;
      }

      &-pulse.active {
        box-shadow: 0 0 0 #275D73;
        background: #275D73;
        animation: pulse 2s infinite;
      }
      &-pulse.inactive {
        box-shadow: 0 0 0 rgba(181, 194, 184, 1);
        background: #95a098;
      }
    }
    &--btn:last-child {
      margin-right: 0px;
    }

    &--connected {
      padding: 0px 10px;
      height: 39px;
      background: #e6ecf1;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;

      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: -0.111111px;
      color: #2bff6f;

      > div:last-child {
        margin-left: 10px;
      }

      &--timeleft {
        margin-left: 10px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        padding: 5px;
        background: #000;
        color: #fff;
        border-radius: 10px;
      }
    }

    &--test {
      padding: 13px 25px;
      height: 39px;
      border-radius: 8px;
      background: #e6ecf1;
      color: #f1a342;
      font-family: 'Larsseit-Medium';
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 17.668px; /* 110.427% */
      letter-spacing: -0.111px;

      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;

      &--grow {
        flex-grow: 1;
      }

      &--smallertext {
        color: #262629;
        font-family: 'Larsseit-Regular';
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 17.668px; /* 126.203% */
        letter-spacing: -0.111px;
      }
      &--subscribe {
        color: #275D73;
        font-family: 'Larsseit-Medium';
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 17.668px; /* 110.427% */
        letter-spacing: -0.111px;
        margin-left: auto;
        cursor: pointer;
      }
    }

    &--add {
      padding: 13px 25px;
      height: 39px;
      border-radius: 8px;
      background: #275D73;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Larsseit-Medium';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: -0.111111px;
      color: #ffffff;

      cursor: pointer;

      &:hover {
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.4);
      }

      > div:last-child {
        margin-left: 10px;
      }
    }
  }
}
</style>
