var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pag-sources-container" }, [
    _c("div", { staticClass: "pag-sources" }, [
      _c("div", { staticClass: "pag-sources-left" }, [
        _c("div", { staticClass: "pag-sources-left-file" }, [
          _c(
            "div",
            {
              staticClass: "pag-sources--icon",
              on: {
                click: function ($event) {
                  return _vm.onSelectSource("file")
                },
              },
            },
            [_c("FileIcon")],
            1
          ),
          _c("div", { staticClass: "pag-sources-left-file--texts" }, [
            _c("div", { staticClass: "pag-sources-left--header" }, [
              _vm._v(_vm._s(_vm.$t("gpt.sources.files"))),
            ]),
            _c("div", { staticClass: "pag-sources-left--text" }, [
              _vm._v(_vm._s(_vm.$t("gpt.sources.fileText"))),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "pag-sources-left-web" }, [
          _c(
            "div",
            {
              staticClass: "pag-sources--icon",
              on: {
                click: function ($event) {
                  return _vm.onSelectSource("web")
                },
              },
            },
            [_c("WebsiteIcon")],
            1
          ),
          _c("div", { staticClass: "pag-sources-left-web--texts" }, [
            _c("div", { staticClass: "pag-sources-left--header" }, [
              _vm._v(_vm._s(_vm.$t("gpt.sources.website"))),
            ]),
            _c("div", { staticClass: "pag-sources-left--text" }, [
              _vm._v(_vm._s(_vm.$t("gpt.sources.urls"))),
            ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "pag-sources-right" },
        [
          _c("DataSourceFiles", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.currentDataSource === "file",
                expression: "currentDataSource === 'file'",
              },
            ],
            ref: "dataSourcesFilesRef",
            on: {
              "can-process-files": _vm.onUpdateCanProcessUploadedFiles,
              "start-processing-uploaded-files": _vm.onProcessUploadedFiles,
              "set-uploads": _vm.onSetUploads,
            },
          }),
          _c("DataSourceWeb", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.currentDataSource === "web",
                expression: "currentDataSource === 'web'",
              },
            ],
            on: {
              "can-process-urls": _vm.onUpdateCanProcessURLs,
              "is-fetching-urls": _vm.onUpdateIsFetchingUrls,
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "pag-sources-action" }, [
      _c("div", { staticClass: "pag-sources-action-sources" }),
      _c("div", { staticClass: "pag-sources-action-finish" }, [
        _c(
          "div",
          {
            staticClass: "pag-sources-action-finish--btn",
            class: {
              "pag-sources--disabled":
                _vm.gptIsFilesUploading || _vm.isFetchingUrls,
            },
            on: { click: _vm.onSubmit },
          },
          [
            _vm._v(
              "\n        " + _vm._s(_vm.$t("gpt.sources.finish")) + "\n      "
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }