<template>
  <div class="loading-container">
    <span class="dot" :style="{ backgroundColor: color, opacity: 1 }"></span>
    <span class="dot" :style="{ backgroundColor: color, opacity: 0.8 }"></span>
    <span class="dot" :style="{ backgroundColor: color, opacity: 0.6 }"></span>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#275D73' // Default color is black
    }
  }
}
</script>
<style scoped>
.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dot {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
  animation: expandContract 1.2s infinite alternate;
}

@keyframes expandContract {
  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5); /* Adjust this for more or less expansion */
  }
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.4s; /* Adjust this for the desired delay between ellipses */
}

.dot:nth-child(3) {
  animation-delay: 0.8s; /* Adjust this for the desired delay between ellipses */
}
</style>
