<template>
  <div class="pag-sources-container">
    <div class="pag-sources">
      <div class="pag-sources-left">
        <div class="pag-sources-left-file">
          <div class="pag-sources--icon" @click="onSelectSource('file')">
            <FileIcon />
          </div>
          <div class="pag-sources-left-file--texts">
            <div class="pag-sources-left--header">{{ $t('gpt.sources.files') }}</div>
            <div class="pag-sources-left--text">{{ $t('gpt.sources.fileText') }}</div>
          </div>
        </div>
        <div class="pag-sources-left-web">
          <div class="pag-sources--icon" @click="onSelectSource('web')">
            <WebsiteIcon />
          </div>
          <div class="pag-sources-left-web--texts">
            <div class="pag-sources-left--header">{{ $t('gpt.sources.website') }}</div>
            <div class="pag-sources-left--text">{{ $t('gpt.sources.urls') }}</div>
          </div>
        </div>
      </div>
      <div class="pag-sources-right">
        <DataSourceFiles
          @can-process-files="onUpdateCanProcessUploadedFiles"
          @start-processing-uploaded-files="onProcessUploadedFiles"
          @set-uploads="onSetUploads"
          ref="dataSourcesFilesRef"
          v-show="currentDataSource === 'file'"
        />

        <DataSourceWeb @can-process-urls="onUpdateCanProcessURLs" @is-fetching-urls="onUpdateIsFetchingUrls" v-show="currentDataSource === 'web'" />
      </div>
    </div>
    <div class="pag-sources-action">
      <div class="pag-sources-action-sources"></div>
      <div class="pag-sources-action-finish">
        <div class="pag-sources-action-finish--btn" :class="{ 'pag-sources--disabled': gptIsFilesUploading || isFetchingUrls }" @click="onSubmit">
          {{ $t('gpt.sources.finish') }}
        </div>
        <!-- <div v-if="canProcessUploadedFiles" class="pag-sources-action-finish--btn" @click="onProcessUploadedFiles">
          {{ $t('gpt.sources.processUploadedFiles') }}
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import FileIcon from '../icons/FileIcon.vue'
import WebsiteIcon from '../icons/WebsiteIcon.vue'
import DataSourceFiles from './DataSourceFiles.vue'
import DataSourceWeb from './DataSourceWeb.vue'
import { gen2function } from '@/mixins/gen2function'

export default {
  name: 'GptDataSources',
  components: {
    FileIcon,
    WebsiteIcon,
    DataSourceFiles,
    DataSourceWeb
  },
  mixins: [gen2function],
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      company: 'company',
      gptSelectedGptWidgetId: 'gpt/selectedGptWidgetId',
      currentDataSource: 'gpt/currentDataSource',
      consultation: 'gpt/consultation',
      qualifiedPercent: 'gpt/qualifiedPercent',
      gptSpeechBubbleText: 'gpt/gptSpeechBubbleText',
      gptBotWelcomeMessage: 'gpt/gptBotWelcomeMessage',
      botWidgetMainColor: 'gpt/botWidgetMainColor',
      botWidgetSecondColor: 'gpt/botWidgetSecondColor',
      botWidgetRequestOptionsButtonColor: 'gpt/botWidgetRequestOptionsButtonColor',
      botWidgetRequestOptionsIconColor: 'gpt/botWidgetRequestOptionsIconColor',
      selectedGptChatbotName: 'gpt/selectedGptChatbotName',
      gptIsProcessing: 'gpt/gptIsProcessing',
      gptCreatedSuccessfully: 'gpt/gptCreatedSuccessfully',
      gptIsFilesUploading: 'gpt/gptIsFilesUploading',
      gptLinks: 'gpt/gptLinks',
      hasFilesUpdated: 'gpt/hasFilesUpdated'
    }),
    IS_GPT_ACTIVATED() {
      if (this.company && this.company.gptIsActivated) return true
      return false
    },
    canProcessURLs() {
      return this.gptLinks.length > 0
    },
    filteredLinks() {
      // First, remove empty items.
      const nonEmptyLinks = this.gptLinks.filter((link) => link && link.trim() !== '')

      // Remove duplicates.
      const uniqueLinks = []
      nonEmptyLinks.forEach((link) => {
        if (!uniqueLinks.includes(link)) {
          uniqueLinks.push(link)
        }
      })

      return uniqueLinks
    }
  },
  data() {
    return {
      canProcessUploadedFiles: false,
      isFetchingUrls: false,
      uploads: []
    }
  },
  methods: {
    ...mapMutations({
      setCurrentDataSource: 'gpt/SET_CURRENT_DATA_SOURCE',
      setGptProcessing: 'gpt/SET_GPT_PROCESSING',
      setGptCreated: 'gpt/SET_GPT_CREATED',
      setGptLinks: 'gpt/SET_GPT_LINKS'
    }),
    onSubmit() {
      if (this.gptIsFilesUploading || this.isFetchingUrls) {
        return
      }

      if (this.uploads && this.uploads.length > 0 && (this.hasFilesUpdated || this.currentDataSource === 'file')) {
        this.onUploadFilesToCloud(this.canProcessURLs)
      } else if (this.canProcessURLs) {
        this.onProcessURLs()
      }
    },

    onSelectSource(source) {
      this.setCurrentDataSource(source)
    },
    onUploadFilesToCloud(canProcessURLs) {
      this.$refs.dataSourcesFilesRef.onUploadFilesToCloud(canProcessURLs)
    },
    async onProcessUploadedFiles(canProcessURLs) {
      if (this.gptIsProcessing) return
      try {
        this.setGptProcessing(true)
        const idTokenResult = await this.$firebase.auth().currentUser.getIdTokenResult()
        let gcpFunctionToken = ''
        if (idTokenResult && idTokenResult.token) {
          gcpFunctionToken = idTokenResult.token
        }
        const config = {
          companyId: this.activeUserInfo.company,
          dialogId: this.gptSelectedGptWidgetId
        }
        const result = await this.callGen2Function('gpt-process-files', config, gcpFunctionToken, this.$gen2EndPoint)

        let configWeb = null
        let resultWeb = null

        if (canProcessURLs) {
          configWeb = {
            companyId: this.activeUserInfo.company,
            dialogId: this.gptSelectedGptWidgetId,
            urls: this.filteredLinks
          }

          resultWeb = await this.callGen2Function('gpt-process-urls', configWeb, gcpFunctionToken, this.$gen2EndPoint)
        }

        const isResultWeb = canProcessURLs ? resultWeb.status === 200 : true

        if ((result && result.status === 200) || isResultWeb) {
          this.setGptProcessing(false)
          this.setGptCreated(true)

          const _gpt = {
            gpt: {
              consultation: this.consultation,
              qualifiedPercent: this.qualifiedPercent,
              speechBubbleText: this.gptSpeechBubbleText,
              botWelcomeMessage: this.gptBotWelcomeMessage,
              botWidgetMainColor: this.botWidgetMainColor,
              botWidgetSecondColor: this.botWidgetSecondColor,
              botWidgetRequestOptionsButtonColor: this.botWidgetRequestOptionsButtonColor,
              botWidgetRequestOptionsIconColor: this.botWidgetRequestOptionsIconColor,
              botName: this.selectedGptChatbotName
            },
            isGptWidget: true,
            isGptWidgetEnabled: this.IS_GPT_ACTIVATED
          }

          await this.$db.collection('dialogs').doc(this.gptSelectedGptWidgetId).set(_gpt, { merge: true })

          setTimeout(() => {
            this.setGptCreated(false)
            this.$emit('on-complete-process')
          }, 5000)
        } else {
          this.setGptProcessing(false)
          // eslint-disable-next-line
          console.log(result.status)
        }
      } catch (error) {
        // eslint-disable-next-line
        console.log(error.message)
        this.setGptProcessing(false)
      }
    },
    onSetUploads(value) {
      this.uploads = value
    },
    onUpdateCanProcessUploadedFiles(value) {
      this.canProcessUploadedFiles = value
    },
    onUpdateCanProcessURLs(value) {
      this.setGptLinks(value)
    },
    onUpdateIsFetchingUrls(value) {
      this.isFetchingUrls = value
    },
    async onProcessURLs() {
      if (this.gptIsProcessing) return
      try {
        this.setGptProcessing(true)
        const idTokenResult = await this.$firebase.auth().currentUser.getIdTokenResult()
        let gcpFunctionToken = ''
        if (idTokenResult && idTokenResult.token) {
          gcpFunctionToken = idTokenResult.token
        }
        const config = {
          companyId: this.activeUserInfo.company,
          dialogId: this.gptSelectedGptWidgetId,
          urls: this.filteredLinks
        }
        const result = await this.callGen2Function('gpt-process-urls', config, gcpFunctionToken, this.$gen2EndPoint)
        if (result.status === 200) {
          this.setGptProcessing(false)
          this.setGptCreated(true)
          const _gpt = {
            gpt: {
              consultation: this.consultation,
              qualifiedPercent: this.qualifiedPercent,
              speechBubbleText: this.gptSpeechBubbleText,
              botWelcomeMessage: this.gptBotWelcomeMessage,
              botWidgetMainColor: this.botWidgetMainColor,
              botWidgetSecondColor: this.botWidgetSecondColor,
              botWidgetRequestOptionsButtonColor: this.botWidgetRequestOptionsButtonColor,
              botWidgetRequestOptionsIconColor: this.botWidgetRequestOptionsIconColor,
              botName: this.selectedGptChatbotName
            },
            isGptWidget: true,
            isGptWidgetEnabled: this.IS_GPT_ACTIVATED
          }
          await this.$db.collection('dialogs').doc(this.gptSelectedGptWidgetId).set(_gpt, { merge: true })

          setTimeout(() => {
            this.setGptCreated(false)
            this.$emit('on-complete-process')
          }, 5000)
        }
        this.setGptProcessing(false)
      } catch (error) {
        // eslint-disable-next-line
        console.log(error.message)
        this.setGptProcessing(false)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@media (min-width: 1400px) {
  .pag-sources-container {
    min-width: 1033px;
  }
}

/* For viewports between 800px and 1033px */
@media (min-width: 1200px) and (max-width: 1399px) {
  .pag-sources-container {
    min-width: 850px;
  }
}

/* For viewports between 800px and 1033px */
@media (min-width: 1033px) and (max-width: 1200px) {
  .pag-sources-container {
    min-width: 950px;
  }
}

/* For viewports between 800px and 1033px */
@media (min-width: 800px) and (max-width: 1033px) {
  .pag-sources-container {
    min-width: 800px;
  }
}

/* For viewports smaller than 800px */
@media (max-width: 799px) {
  .pag-sources-container {
    min-width: 100%;
  }
}

.pag-sources-container {
  display: flex;
  flex-direction: column;
  gap: 23px;
  max-width: 1033px;
  width: 100%;
}

.pag-sources {
  max-width: 1033px;
  min-width: 100%;
  width: 100%;
  margin: 0 auto;
  min-height: 433px;
  height: 100%;
  align-items: stretch;
  border-radius: 6px;
  border: 1px solid #d9dbdd;
  display: flex;
  flex-direction: row;

  &--disabled {
    background: #979797 !important;
  }

  &-action {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 30px;

    &-finish {
      display: flex;
      flex-direction: row;
      gap: 10px;

      &--btn {
        border-radius: 8px;
        background: #275D73;
        height: 37px;
        color: #fff;
        font-family: 'Larsseit-Regular';
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 17.668px; /* 110.427% */
        letter-spacing: -0.111px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 40px;
        cursor: pointer;
      }
    }
  }

  &--icon {
    cursor: pointer;
  }

  &-left {
    padding: 31px 20px;
    width: 157px;
    min-height: 433px;
    border-right: 1px solid #d9dbdd;

    display: flex;
    gap: 15px;
    flex-direction: column;

    &--header {
      color: #575757;
      font-family: 'Larsseit-Bold';
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 17.668px; /* 147.237% */
      letter-spacing: -0.111px;
    }

    &--text {
      color: #575757;
      font-family: 'Larsseit-Regular';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 17.668px; /* 147.237% */
      letter-spacing: -0.111px;
    }

    &-file {
      display: flex;
      flex-direction: row;
      gap: 15px;

      &--texts {
        display: flex;
        flex-direction: column;
      }
    }

    &-web {
      display: flex;
      flex-direction: row;
      gap: 15px;

      &--texts {
        display: flex;
        flex-direction: column;
      }
    }
  }

  &-right {
    padding: 31px 20px;
    display: flex;
    min-height: 433px;
    height: 100%;
    width: 100%;
    max-width: 1033px;
  }
}
</style>
