var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pag-widget" },
    [
      _c(
        "form-wizard",
        {
          ref: "gptFormWizard",
          staticClass: "pag-widget--formwizard",
          attrs: {
            color: "rgba(var(--vs-primary), 1)",
            title: "",
            subtitle: "",
            startIndex: _vm.currentStep,
            nextButtonText: _vm.$t("vue.next"),
            backButtonText: _vm.$t("vue.back"),
            finishButtonText: _vm.$t("vue.finish"),
            shape: "circle",
          },
          on: { "on-change": _vm.onStepChanged },
        },
        [
          _c("template", { slot: "footer" }, [
            _c("div", { staticClass: "pag-widget-footer" }, [
              _c(
                "div",
                {
                  staticClass: "pag-widget-footer--btn",
                  on: { click: _vm.prevTab },
                },
                [_vm._v(_vm._s(_vm.$t("vue.back")))]
              ),
              _vm.currentStep !== 3
                ? _c(
                    "div",
                    {
                      staticClass: "pag-widget-footer--btn",
                      on: { click: _vm.nextTab },
                    },
                    [_vm._v(_vm._s(_vm.$t("vue.next")))]
                  )
                : _vm._e(),
            ]),
          ]),
          _c(
            "tab-content",
            {
              staticClass: "mb-5",
              attrs: {
                title: _vm.$t("gpt.tabs.howTo"),
                icon: "material-icons icon-question-mark",
                lazy: true,
              },
            },
            [_vm.currentStep == 0 ? _c("HowTo") : _vm._e()],
            1
          ),
          _c(
            "tab-content",
            {
              staticClass: "tab2 mb-5",
              attrs: {
                title: _vm.$t("gpt.tabs.settings"),
                icon: "material-icons icon-settings",
                "before-change": _vm.validateSettings,
                lazy: true,
              },
            },
            [
              _vm.currentStep == 1
                ? _c("Settings", { ref: "gptSettings" })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "tab-content",
            {
              staticClass: "mb-5",
              attrs: {
                title: _vm.$t("gpt.tabs.customization"),
                icon: "material-icons icon-edit-3",
                "before-change": _vm.validateCustomization,
              },
            },
            [
              _vm.currentStep == 2
                ? _c("Customization", { ref: "gptCustomization" })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "tab-content",
            {
              staticClass: "mb-5",
              attrs: {
                title: _vm.$t("gpt.tabs.dataSources"),
                icon: "material-icons icon-file-text-2",
              },
            },
            [
              _vm.currentStep == 3
                ? _c("DataSources", {
                    on: { "on-complete-process": _vm.onCompleteProcess },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }