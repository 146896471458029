<template>
  <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 10L5 5.5L1 1" stroke="#2BFF6F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>
<script>
export default {
  name: 'RightArrowIcon'
}
</script>
